import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { Alert, Button, ButtonGroup, Card, Col, Row, Table } from "react-bootstrap";
import { FieldArray, Form, Formik } from "formik";
import * as yup from "yup";
import { useTranslation } from "react-i18next";

import { SubmitButton, UploadButton, VerificationDocumentsButton } from "components/ui/buttons";
import { AllError, FormGroup, TableGroup } from "components/formik";
import cx from "classnames";
import { formatMoney } from "utils/money";
import { formatDate } from "utils/date";
import * as salaryAPI from "api2/salaries";
import { specificationDocumentUpload } from "api2/salaries";
import { toast } from "react-toastify";
import BillectaPaymentDate from "components/ui/boxes/BillectaPaymentDate";
import { FinancialYearDispatchContext } from "state/providers/FinancialYearProvider";
import { filterActiveCC, filterActiveProjects } from "utils/others";
import "./SalaryForm.scss";
import { useCompanyState } from "hooks/useCompany";

const MENU_PORTAL_WIDTH = 300;

function _sum(data, key) {
  return data.filter((i) => i.selected).reduce((sum, i) => sum + i[key] || 0, 0);
}

function _optionsForEmployee(employee, t) {
  if (employee && employee.account) {
    return [
      { value: "P", label: t("common:accountP") },
      { value: "F", label: t("common:accountF") },
    ];
  }
  return [{ value: "P", label: t("common:accountP") }];
}

function SalaryForm({ companyId, salaries, onSave }) {
  const paidToRef = useRef(null);
  const [menuPortalShift, setMenuPortalShift] = useState(0);
  const { t } = useTranslation("salary");
  const { checkYears } = useContext(FinancialYearDispatchContext);
  const [isExternalColumns, setIsExternalColumns] = useState(false);
  const {
    costCenters: { asOptions: costOptions },
    projects: { asOptions: projectOptions },
  } = useCompanyState();

  const activeProjects = useMemo(() => projectOptions.filter(filterActiveProjects), [projectOptions]);
  const activeCenters = useMemo(() => costOptions.filter(filterActiveCC), [costOptions]);

  const showProjects = activeProjects.length !== 0;
  const showCostCenter = activeCenters.length !== 0;

  useEffect(() => {
    const getShift = () => {
      const shift = paidToRef.current.offsetWidth;
      setMenuPortalShift(shift);
    };

    getShift();
    window.addEventListener("resize", getShift);

    return () => window.removeEventListener("resize", getShift);
  }, []);

  const collSpanWithExternalCol = showProjects && showCostCenter ? 10 : 9;

  const formikProps = {
    initialValues: {
      ...salaries,
    },
    validationSchema: yup.object().shape({
      booking_date: yup.date().nullable().required(),
      payment_date: yup.date().nullable().required(),
      salaries: yup
        .array()
        .ensure()
        .of(
          yup.object().shape({
            amount: yup.number(),
          })
        ),
    }),
    onSubmit: async (values, { setErrors }) => {
      const selectedSalaries = values.salaries.filter((i) => i.selected);
      if (!selectedSalaries.length) {
        setErrors({ __all__: t("errors.oneEmployeeRequired") });
        return;
      }
      for (let i = 0; i < selectedSalaries.length; i++) {
        if (!selectedSalaries[i].file && !selectedSalaries[i].specification) {
          setErrors({ __all__: t("errors.missingSpec") });
          return;
        }
      }
      const executeAnyway = await checkYears(values.payment_date || new Date());
      if (!executeAnyway) {
        return;
      }
      const promises = [];
      selectedSalaries.forEach((salary, i) => {
        const promise = new Promise((resolve, reject) => {
          setTimeout(() => {
            return salaryAPI
              .save(companyId, {
                booking_date: formatDate(new Date()),
                payment_date: formatDate(values.payment_date),
                ...salary,
                paid_to: salary.paid_to.value,
                project: salary.project?.id,
                cost_center: salary.cost_center?.id,
              })
              .then(async (response) => {
                if (salary.file) {
                  await specificationDocumentUpload(companyId, response.data.id || salary.id, salary.file).catch(() => {
                    // pass silently
                  });
                }
                // if (!salary.specification && salary.specificationHolder) {
                //   await salaryAPI.removeSpec(companyId, salary.id);
                // }
                // if (salary.file) {
                //   salary.file.description = "salary_spec";
                //   await documentAPI.uploadVerification(companyId, response.data.id, [salary.file]).catch(() => {
                //     // pass silently
                //   });
                // }
                resolve();
              })
              .catch((err) => {
                reject(err);
              });
          }, i * 800);
        });
        promises.push(promise);
      });

      await Promise.all(promises)
        .then((results) => {
          if (onSave) {
            onSave();
          }
          toast.success(t("msg:saved"), { autoClose: 2000 });
        })
        .catch((error) => {
          toast.error(t("msg:fixErrors"));
          if (error.data.payment_date) {
            setErrors({ __all__: error.data.payment_date });
          }
          if (error.data.__all__) {
            setErrors({ __all__: error.data.__all__ });
          }
        });
    },
  };

  function getToBePaid(salary) {
    return Math.round((salary.salary_gross || 0) - (salary.personal_tax || 0) + (salary.tax_free || 0));
  }

  function getSocSec(salary) {
    return Math.round(((salary.salary_gross || 0) + (salary.fringe_benefits || 0)) * 0.3142);
  }

  function salaryGrossBlur(salary, index, arrayHelper) {
    arrayHelper.replace(index, {
      ...salary,
      soc_sec: getSocSec(salary),
      amount: getToBePaid(salary),
    });
  }

  function fringeBlur(salary, index, arrayHelper) {
    arrayHelper.replace(index, {
      ...salary,
      soc_sec: getSocSec(salary),
    });
  }

  function personalTaxBlur(salary, index, arrayHelper) {
    arrayHelper.replace(index, {
      ...salary,
      amount: getToBePaid(salary),
    });
  }

  function taxFreeBlur(salary, index, arrayHelper) {
    arrayHelper.replace(index, {
      ...salary,
      amount: getToBePaid(salary),
    });
  }

  function handleCostCenterOrProject(name, salariesWithSelectField, setFieldValue, value) {
    setFieldValue(
      "salaries",
      salariesWithSelectField.map((salary) => {
        if (!salary.selected) {
          return salary;
        }

        return { ...salary, [name]: value };
      })
    );
  }

  return (
    <Formik {...formikProps}>
      {({ values, isSubmitting, setFieldValue, errors }) => {
        return (
          <Form id="salaryForm" className="salary-form">
            <Card>
              <Card.Body>
                {(showProjects || showCostCenter) && (
                  <div className="external-wrapper">
                    <Button
                      variant="link"
                      onClick={() => setIsExternalColumns((prevValue) => !prevValue)}
                      className="external-button"
                    >
                      {isExternalColumns
                        ? `<< ${t("common:actions.hide")} ${t("ccOrProject")}`
                        : `${t("common:actions.show")} ${t("ccOrProject")} >>`}
                    </Button>
                  </div>
                )}
                <FieldArray
                  name="salaries"
                  render={(arrayHelper) => (
                    <Table bordered>
                      <thead>
                        <tr>
                          <th>{t("common:employee")}</th>
                          <th>{t("grossSalary")}</th>
                          <th>{t("fringeBenefits")}</th>
                          <th>{t("socSecFees")}</th>
                          <th>{t("persTax")}</th>
                          <th>{t("taxFree")}</th>
                          <th>{t("common:money.toPay")}</th>
                          <th ref={paidToRef}>{t("paidTo")}</th>
                          <th>{t("salarySpec")}</th>
                          {isExternalColumns && (
                            <>
                              {showProjects && <th>{t("common:project")}</th>}
                              {showCostCenter && <th>{t("common:costCenter")}</th>}
                            </>
                          )}
                        </tr>
                      </thead>
                      <tbody>
                        {values.salaries.map((salary, index) => {
                          return (
                            <React.Fragment key={index}>
                              <tr className={cx({ selected: salary.selected })}>
                                <td style={{ width: 250 }}>
                                  {salary.id ? (
                                    <strong>
                                      {salary.employee}
                                      {salary.account && ` (${salary.account})`}
                                    </strong>
                                  ) : (
                                    <div
                                      className="checkbox custom-control checkbox-primary"
                                      onClick={() =>
                                        arrayHelper.replace(index, {
                                          ...salary,
                                          selected: !salary.selected,
                                          project: values.project,
                                          cost_center: values.cost_center,
                                        })
                                      }
                                    >
                                      <input
                                        type="checkbox"
                                        className="d-none"
                                        name="selected"
                                        checked={salary.selected}
                                        readOnly
                                      />
                                      <label className="custom-control-label">
                                        {salary.employee}
                                        {salary.account && ` (${salary.account})`}
                                      </label>
                                    </div>
                                  )}
                                </td>
                                {salary.selected ? (
                                  <>
                                    <TableGroup.MoneyInput
                                      name={`salaries[${index}].salary_gross`}
                                      onBlur={() => salaryGrossBlur(salary, index, arrayHelper)}
                                    />

                                    <TableGroup.MoneyInput
                                      name={`salaries[${index}].fringe_benefits`}
                                      onBlur={() => fringeBlur(salary, index, arrayHelper)}
                                    />

                                    <TableGroup.MoneyInput name={`salaries[${index}].soc_sec`} />

                                    <TableGroup.MoneyInput
                                      name={`salaries[${index}].personal_tax`}
                                      onBlur={() => personalTaxBlur(salary, index, arrayHelper)}
                                    />

                                    <TableGroup.MoneyInput
                                      name={`salaries[${index}].tax_free`}
                                      onBlur={() => taxFreeBlur(salary, index, arrayHelper)}
                                    />
                                    <td className="text-right bg-light" style={{ paddingRight: 13 }}>
                                      {formatMoney(salary.amount)}
                                    </td>
                                    <TableGroup.SimpleSelect
                                      name={`salaries[${index}].paid_to`}
                                      options={_optionsForEmployee(salary, t)}
                                      styles={{
                                        menuPortal: (base) => ({
                                          ...base,
                                          zIndex: 9999,
                                          width: MENU_PORTAL_WIDTH,
                                          left: base.left + menuPortalShift - MENU_PORTAL_WIDTH,
                                        }),
                                      }}
                                    />
                                    <td className="text-center" style={{ width: 100 }}>
                                      {salary.specification ? (
                                        <ButtonGroup size="sm">
                                          <VerificationDocumentsButton
                                            verificationId={salary.id}
                                            companyId={companyId}
                                            documentsIds={[salary.specification]}
                                          />
                                          <Button
                                            type="button"
                                            variant="danger"
                                            onClick={() =>
                                              arrayHelper.replace(index, {
                                                ...salary,
                                                specification: null,
                                              })
                                            }
                                          >
                                            <i className="fas fa-times" />
                                          </Button>
                                        </ButtonGroup>
                                      ) : (
                                        <UploadButton
                                          fileTypes={["application/pdf"]}
                                          onFileChange={(file) => {
                                            arrayHelper.replace(index, {
                                              ...salary,
                                              file,
                                            });
                                          }}
                                        />
                                      )}
                                    </td>
                                    {isExternalColumns && (
                                      <>
                                        {showProjects && (
                                          <TableGroup.SimpleSelect
                                            name={`salaries[${index}].project`}
                                            id="project-select"
                                            placeholder=""
                                            options={activeProjects}
                                            onChange={(value) => {
                                              if (value.id === values.project?.id) return;
                                              setFieldValue("project", []);
                                            }}
                                            styles={{
                                              menuPortal: (base) => ({
                                                ...base,
                                                zIndex: 9999,
                                                width: MENU_PORTAL_WIDTH,
                                                left: base.left + menuPortalShift - MENU_PORTAL_WIDTH,
                                              }),
                                            }}
                                          />
                                        )}

                                        {showCostCenter && (
                                          <TableGroup.SimpleSelect
                                            name={`salaries[${index}].cost_center`}
                                            id="cost_center-select"
                                            placeholder=""
                                            onChange={(value) => {
                                              if (value.id === values.cost_center?.id) return;
                                              setFieldValue("cost_center", []);
                                            }}
                                            options={activeCenters}
                                            styles={{
                                              menuPortal: (base) => ({
                                                ...base,
                                                zIndex: 9999,
                                                width: MENU_PORTAL_WIDTH,
                                                left: base.left + menuPortalShift - MENU_PORTAL_WIDTH,
                                              }),
                                            }}
                                          />
                                        )}
                                      </>
                                    )}
                                  </>
                                ) : (
                                  <td colSpan={isExternalColumns ? collSpanWithExternalCol : 8} />
                                )}
                              </tr>
                              <TableGroup.RowErrors cols={9} errors={errors.salaries && errors.salaries[index]} />
                            </React.Fragment>
                          );
                        })}
                      </tbody>
                      <tfoot>
                        <tr>
                          <th />
                          <td>{formatMoney(_sum(values.salaries, "salary_gross"))}</td>
                          <td>{formatMoney(_sum(values.salaries, "fringe_benefits"))}</td>
                          <td>{formatMoney(_sum(values.salaries, "soc_sec"))}</td>
                          <td>{formatMoney(_sum(values.salaries, "personal_tax"))}</td>
                          <td>{formatMoney(_sum(values.salaries, "tax_free"))}</td>
                          <td>{formatMoney(_sum(values.salaries, "amount"))}</td>
                          <td />
                          <td />
                          {isExternalColumns && (
                            <>
                              {showProjects && <td />}
                              {showCostCenter && <td />}
                            </>
                          )}
                        </tr>
                      </tfoot>
                    </Table>
                  )}
                />
                <Row>
                  <Col xl={2} lg={4}>
                    <FormGroup.BookingDatePicker name="payment_date" label={t("common:dates.paymentDate")} required />
                  </Col>
                  <Col xl={3} lg={5}>
                    <Alert variant="info">
                      <BillectaPaymentDate selectedDate={values.payment_date} />
                    </Alert>
                  </Col>
                  {(showProjects || showCostCenter) && (
                    <Col xl={2} lg={4}>
                      <div className="float-right">{t("salary:selectForAll")}:</div>
                    </Col>
                  )}

                  {showProjects && (
                    <Col xl={2} lg={4}>
                      <FormGroup.SimpleSelect
                        name="project"
                        label={t("common:project")}
                        options={activeProjects}
                        onChange={(value) =>
                          handleCostCenterOrProject("project", values.salaries, setFieldValue, value)
                        }
                      />
                    </Col>
                  )}

                  {showCostCenter && (
                    <Col xl={2} lg={4}>
                      <FormGroup.SimpleSelect
                        name="cost_center"
                        label={t("common:costCenter")}
                        options={activeCenters}
                        onChange={(value) =>
                          handleCostCenterOrProject("cost_center", values.salaries, setFieldValue, value)
                        }
                      />
                    </Col>
                  )}
                </Row>
                <AllError errors={errors} />
              </Card.Body>
              <Card.Footer>
                <ButtonGroup>
                  <SubmitButton isSubmitting={isSubmitting} />
                </ButtonGroup>
              </Card.Footer>
            </Card>
          </Form>
        );
      }}
    </Formik>
  );
}

export default SalaryForm;
