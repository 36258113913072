import React from "react";
import NavBar from "components/ui/navbar";
import { Outlet, useParams } from "react-router-dom";
import OfficeSupportMenu from "components/layouts/OfficeSupport/OfficeSupportMenu";
import StatusListProvider from "state/providers/StatusListProvider";
import StatusListNavTabs from "./StatusListNavTabs";

function StatusListLayout() {
  const { section } = useParams();
  return (
    <div className="status-list">
      <NavBar company={null} forDashboard={false} />
      <OfficeSupportMenu />
      <div className="content-page">
        <div className="content">
          <div className="container-fluid">
            <div className="tab-card">
              <StatusListProvider section={section}>
                <StatusListNavTabs />
                <Outlet />
              </StatusListProvider>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default StatusListLayout;
