import React, { useContext, useMemo, useState } from "react";
import { Button, ButtonGroup, Card, Table } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import cx from "classnames";

import { Loader } from "components/ui/loaders";
import { MonthDateFilter, MultiConsultFilter, MultiSelectSimpleFilter, SearchFilter } from "components/filters";
import useModal from "hooks/useModal";
import { useCompanyDispatch } from "hooks/useCompany";
import { StatusListStateContext } from "state/providers/StatusListProvider";
import { truncateText } from "utils/text";
import { statusListMainStatuses } from "api/options";
import EventSelect from "./EventSelect";
import "./StatusListPage.scss";
import { prepareData } from "./helpers";
import EventDetailModal from "./EventDetailModal";
import { FilterButton } from "../../../components/ui/buttons";

function ViewSwitch({ filters, setFilters }) {
  const { t } = useTranslation("officeSupport");
  const { view } = filters;
  const update = (newValue) => {
    setFilters({ ...filters, view: newValue });
  };
  return (
    <ButtonGroup className="btn-switch">
      <Button variant="outline-secondary" active={view === "delivery"} onClick={() => update("delivery")}>
        <i className="fe-check" /> {t("view.delivery")}
      </Button>
      <Button variant="outline-secondary" active={view === "period"} onClick={() => update("period")}>
        <i className="fe-check" /> {t("view.period")}
      </Button>
    </ButtonGroup>
  );
}

function CondensedSwitch() {
  const { options, setOptions } = useContext(StatusListStateContext);
  return (
    <Button
      variant="outline-secondary"
      active={options.extended}
      onClick={() => {
        setOptions({ ...options, extended: !options.extended });
      }}
    >
      <i className="fe-menu" style={{ fontWeight: 500 }} />
    </Button>
  );
}

function Filters({ filters, setFilters }) {
  const { t } = useTranslation("common");
  const filtersCount = 0 + (filters.assignees ? !!filters.assignees.length : 0);
  const [more, setMore] = useState(false);
  const statusesOptions = statusListMainStatuses.asList();
  return (
    <div className="table-filters-group">
      <section className="table-filters-left">
        <SearchFilter
          onFilter={({ term }) => setFilters({ ...filters, term })}
          defaultValue={filters.term}
          label="&nbsp;"
        />
        <FilterButton active={more} count={filtersCount} onClick={() => setMore(!more)} />

        <MonthDateFilter
          name="date_month"
          isClearable={false}
          label={t("dates.month")}
          defaultValue={filters.date_month}
          onChange={(selected) => setFilters({ ...filters, date_month: selected })}
        />
        <div className="form-group">
          <label className="form-label">{t("common:view")}</label>
          <ViewSwitch filters={filters} setFilters={setFilters} />
        </div>
        <div className="form-group">
          <label className="form-label">&nbsp;</label>
          <CondensedSwitch />
        </div>
      </section>
      {more && (
        <section className="more-filters">
          <MultiConsultFilter
            label={t("officeSupport:config.assignee")}
            name="assignees"
            defaultValue={filters.assignees}
            onFilter={({ assignees }) => {
              setFilters({ ...filters, assignees });
            }}
          />
          <MultiSelectSimpleFilter
            label={t("common:statuses.status")}
            name="statuses"
            options={statusesOptions}
            defaultValue={filters.statuses}
            onFilter={({ statuses }) => {
              setFilters({ ...filters, statuses });
            }}
          />
        </section>
      )}
    </div>
  );
}

function InvolvedBadges({ involved }) {
  const visible = involved.slice(0, 4);
  const notVisible = involved
    .slice(4)
    .map(({ name }) => name)
    .join(",");
  return (
    <div className="initials">
      {visible.map(({ name, initials }) => (
        <div key={initials} className="initials__badge" title={name}>
          <div>{initials}</div>
        </div>
      ))}
      {involved.length > 4 && (
        <div className="initials__badge text-muted" title={notVisible}>
          <div>+{involved.length - 4}</div>
        </div>
      )}
    </div>
  );
}

function EventDetailBadges({ event }) {
  const detailModal = useModal();
  return (
    <>
      <Button
        variant="link"
        onClick={detailModal.open}
        className={cx("comment-btn", { hidden: !event.comments.length })}
      >
        <i className="fe-message-circle" />
      </Button>
      {event.activity.note && (
        <Button variant="link" onClick={detailModal.open}>
          <i className="fe-file-text" />
        </Button>
      )}
      {detailModal.show && <EventDetailModal event={event} handleClose={detailModal.close} />}
    </>
  );
}

function EventTd({ event, extended }) {
  if (event.disabled) {
    return <td className="event-col disabled" />;
  }

  return (
    <td className={cx("event-col", { "no-comments": !event.comments })}>
      <div className="top">
        <EventSelect
          eventId={event.id}
          deadline={event.deadline}
          periodIndicator={event.periodIndicator}
          current={event.statusIndicator.current}
          options={event.statusIndicator.statuses}
          extended={extended}
        />
      </div>
      <div className="bottom">
        <EventDetailBadges event={event} />
      </div>
    </td>
  );
}

function StatusListTable({ templates, companies, tableOptions }) {
  const { t, i18n } = useTranslation();
  const isEnglish = useMemo(() => i18n.language === "en", [i18n.language]);
  const { selectCompany: selectCompanyAction } = useCompanyDispatch();
  const selectCompany = async (companyId) => {
    await selectCompanyAction(companyId);
    window.open("/office-support-companies/status-list", "_blank");
  };

  return (
    <div>
      <Table bordered responsive>
        <thead>
          <tr>
            <th className="fix">{t("common:company")}</th>
            {templates.map((tpl) => (
              <td key={tpl.id}>{isEnglish ? tpl.name_en : tpl.name_sv}</td>
            ))}
          </tr>
        </thead>
        <tbody>
          {companies.map((company) => (
            <tr key={company.id}>
              <td className="fix">
                <div className="d-flex">
                  <Button
                    variant="link"
                    title={company.name}
                    className="company-btn mr-1"
                    onClick={() => selectCompany(company.id)}
                  >
                    {truncateText(company.name, 20)}
                  </Button>{" "}
                  <InvolvedBadges involved={company.involved} />
                  <div>
                    <i className="fe-grid" />
                  </div>
                </div>
              </td>
              {templates.map((tpl) => {
                const event = company.events[tpl.id];
                return <EventTd key={event.id} event={event} extended={tableOptions.extended} />;
              })}
            </tr>
          ))}
          {!companies.length && (
            <tr>
              <td colSpan={templates.length}>{t("common:noResultsFound")}</td>
            </tr>
          )}
        </tbody>
      </Table>
    </div>
  );
}

function StatusListPage() {
  const { loading, templates, events, filters, setFilters, options } = useContext(StatusListStateContext);
  const data = useMemo(() => {
    if (loading) {
      return [];
    }
    return prepareData(templates, events);
  }, [loading, templates, events]);

  return (
    <Card id="status-list-table">
      <Card.Body>
        <Filters filters={filters} setFilters={setFilters} />
        {loading ? (
          <Loader />
        ) : (
          <StatusListTable templates={data.templates} companies={data.companies} tableOptions={options} />
        )}
      </Card.Body>
    </Card>
  );
}

export default StatusListPage;
