import React, { useState } from "react";

import useTable from "components/tables/btable/useTable";
import { SearchFilter } from "components/filters";
import { Button, ButtonGroup } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { AccountSelect } from "components/filters/AccountRangeFilter";
import ReactDatePicker from "react-datepicker";
import qs from "qs";
import { DATE_FORMAT, formatDate, SHORT_DATE_FORMAT } from "utils/date";
import { FilterButton } from "components/ui/buttons";
import { useCompanyState } from "hooks/useCompany";
import { filterAssetAccountOptions } from "utils/assets";
import { PermCodeRequired } from "components/perms";
import { codesForInventoryRegister } from "components/perms/PermCodes";
import { scrollToSection } from "utils/others";
import useModal from "hooks/useModal";
import FileUrlModalOld from "components/modals/FileUrlModal/FileUrlModalOld";

function AssetFilters({ companyId }) {
  const { filters, setFilters } = useTable();
  const { t } = useTranslation("common");
  const filtersCount = !!filters.asset_account;
  const [more, setMore] = useState(!!filtersCount);
  const [reportDateTo, setReportDateTo] = useState(null);
  const reportModal = useModal();
  const {
    accounts: { asOptions: accountOptions },
  } = useCompanyState();
  const openReportModal = () => {
    const params = qs.stringify(
      {
        date: formatDate(reportDateTo),
      },
      { indices: false }
    );
    return reportModal.open(`/companies/${companyId}/reports/inventory-register/pdf/?${params}`);
  };
  return (
    <>
      <section className="table-filters-left">
        <SearchFilter onFilter={setFilters} defaultValue={filters.term} width={300} />
        <FilterButton active={more} count={filtersCount} onClick={() => setMore(!more)} />
        <ButtonGroup aria-label="Status filter" className="status-filter">
          <Button
            variant="outline-secondary"
            active={!filters.mode || filters.mode === "all"}
            onClick={() => setFilters({ mode: "" })}
          >
            {t("common:statuses.all")}
          </Button>
          <Button
            variant="outline-draft"
            active={filters.mode === "draft"}
            onClick={() => setFilters({ mode: "draft" })}
          >
            {t("common:statuses.draft")}
          </Button>
          <Button
            variant="outline-prelim"
            active={filters.mode === "prelim"}
            onClick={() => setFilters({ mode: "prelim" })}
          >
            {t("common:statuses.inApproval")}
          </Button>
          <Button
            variant="outline-success"
            active={filters.mode === "active"}
            onClick={() => setFilters({ mode: "active" })}
          >
            {t("common:statuses.active")}
          </Button>
          <Button
            variant="outline-unpaid"
            active={filters.mode === "inactive"}
            onClick={() => setFilters({ mode: "inactive" })}
          >
            {t("common:statuses.inactive")}
          </Button>
        </ButtonGroup>
      </section>
      <section className="table-filters-right">
        <ButtonGroup>
          <ReactDatePicker
            className="form-control"
            selected={reportDateTo}
            isClearable={false}
            dateFormat={[DATE_FORMAT, SHORT_DATE_FORMAT]}
            placeholderText={t("common:dates.dateTo")}
            onChange={(date) => setReportDateTo(date)}
          />
          <Button className="btn btn-first" onClick={() => openReportModal()}>
            PDF
          </Button>
        </ButtonGroup>
        <PermCodeRequired code={codesForInventoryRegister.manage}>
          <Button onClick={() => scrollToSection("create-asset-section")} className="btn btn-first">
            <i className="fas fa-plus" /> {t("common:actions.create")}
          </Button>
        </PermCodeRequired>
      </section>
      {more && (
        <section className="more-filters">
          <AccountSelect
            placeholder={t("assetAccount")}
            options={accountOptions.filter(filterAssetAccountOptions)}
            defaultValue={null}
            onChange={(selected) => setFilters({ asset_account: selected && selected.number })}
            filterOptionStartsWith
          />
        </section>
      )}
      {reportModal.show && <FileUrlModalOld fileUrl={reportModal.data} handleClose={reportModal.close} />}
    </>
  );
}

export default AssetFilters;
