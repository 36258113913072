import React from "react";
import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import { useTranslation } from "react-i18next";

function DownloadTemplateButton({ templatePaths, buttonTitle = "", tooltipTranslationKey, downloadFileName }) {
  const { t, i18n } = useTranslation("company");
  const lang = i18n.language || "sv";
  const isSwedish = lang === "sv";
  const templatePath = isSwedish ? templatePaths.sv : templatePaths.en;

  return (
    <OverlayTrigger
      placement="bottom"
      overlay={
        <Tooltip id={`${downloadFileName}-template-tooltip`} className="step-status-tooltip">
          {t(tooltipTranslationKey)}
        </Tooltip>
      }
    >
      <Button as="a" href={templatePath} variant="second" download={`${downloadFileName}.csv`}>
        <i className="fe-download" /> {buttonTitle}
      </Button>
    </OverlayTrigger>
  );
}

export default DownloadTemplateButton;
