import { formatMonthTrailingNumber, formatQuarterSolo, formatYear, parseDate } from "utils/date";

export function getInitials(name) {
  const items = name.split(" ");
  return items[0][0].toUpperCase() + items[1][0].toUpperCase();
}

function getStatusIndicator(status, availableStatuses) {
  const index = availableStatuses.indexOf(status);
  return {
    current: { value: status, label: status, index: index + 1 },
    index: index + 1,
    max: availableStatuses.length,
    statuses: availableStatuses.map((item, idx) => ({ label: item, value: item, index: idx + 1 })),
  };
}

function getPeriodIndicator(periodicity, periodStart, periodEnd) {
  if (periodicity === "monthly") {
    return formatMonthTrailingNumber(periodStart);
  }
  if (periodicity === "quarterly") {
    return formatQuarterSolo(periodStart);
  }
  const indicator = formatYear(periodStart);
  if (periodStart.slice(0, 4) !== periodEnd.slice(0, 4)) {
    return `${indicator}/${periodEnd.slice(2, 4)}`;
  }
  return indicator;
}

export function prepareData(templates, events) {
  const data = { templates: [], companies: [] };
  const companies = {};
  const emptyTemplateList = templates.reduce(
    (item, tpl) => ({
      ...item,
      [tpl.id]: { id: `t.${tpl.id}`, disabled: true, name_en: tpl.name_en, name_sv: tpl.name_sv },
    }),
    {}
  );

  const temp = {};
  const usedTemplates = new Set();
  events.forEach((event) => {
    if (!temp[event.company_id]) {
      temp[event.company_id] = {};
    }
    if (!companies[event.company_id]) {
      companies[event.company_id] = {
        id: event.company_id,
        name: event.company_name,
        involved: [],
        events: { ...emptyTemplateList },
      };
    }
    if (!temp[event.company_id][event.assignee_id]) {
      // need unique
      companies[event.company_id].involved.push({
        name: event.assignee_name,
        initials: getInitials(event.assignee_name),
      });
      temp[event.company_id][event.assignee_id] = 1;
    }
    const template = emptyTemplateList[event.activity.template];
    usedTemplates.add(event.activity.template);
    companies[event.company_id].events[event.activity.template] = {
      ...event,
      deadline: parseDate(event.deadline),
      activity: {
        ...event.activity,
        name_en: template.name_en,
        name_sv: template.name_sv,
      },
      disabled: false,
      template_name: emptyTemplateList[event.activity.template].name,
      periodIndicator: getPeriodIndicator(event.periodicity, event.period_start, event.period_end),
      statusIndicator: getStatusIndicator(event.status, event.activity.available_statuses),
    };
  });
  data.templates = templates.filter((template) => usedTemplates.has(template.id));

  data.companies = Object.values(companies);
  return data;
}
