import React, { useRef, useState } from "react";
import { DraggableModalDialog } from "components/modals/DraggableModalDialog";
import { Button, Modal } from "react-bootstrap";
import { SubmitButton } from "components/ui/buttons";
import { useTranslation } from "react-i18next";
import { Scrollbars } from "react-custom-scrollbars";
import * as supportAPI from "api2/office-support";
import { toast } from "react-toastify";
import { useFormikContext } from "formik";

function DescriptionModal({ companyId, activity, index, handleClose }) {
  const { t } = useTranslation();
  const ref = useRef();
  const [note, setNote] = useState(activity.note);
  const [edit, setEdit] = useState(!activity.note);
  const { values, resetForm } = useFormikContext();
  const onClose = () => {
    const activities = [...values.activities];
    activities[index].note = note.trim();
    resetForm({ values: { ...values, activities } });
    handleClose();
  };
  const save = () => {
    const text = ref.current.value.trim();
    supportAPI.statusList.updateNote(companyId, activity.id, text).then((response) => {
      toast.success(t("msg:saved"));
      setEdit(false);
      setNote(text);
    });
  };

  return (
    <Modal
      animation={false}
      scrollable
      show
      onHide={onClose}
      size="lg"
      dialogClassName="verification-comments-modal status-list-config-modal"
      dialogAs={DraggableModalDialog}
    >
      <Modal.Header closeButton>
        <div>
          <h1>{t("common:description")}</h1>
          <h2>{activity.name_en}</h2>
        </div>
      </Modal.Header>
      <Modal.Body style={{ minHeight: 300 }}>
        {edit ? (
          <textarea
            className="form-control"
            defaultValue={note}
            placeholder={t("yourMsg")}
            autoFocus
            autoCorrect="off"
            autoComplete="off"
            spellCheck="false"
            ref={ref}
            rows={13}
          />
        ) : (
          <Scrollbars className="body" style={{ height: 400 }}>
            <div className="pr-2">
              <p>{note || t("common:noDataAvailable")}</p>
            </div>
          </Scrollbars>
        )}
      </Modal.Body>
      {!edit && (
        <Modal.Footer className="d-flex justify-content-between">
          <Button variant="outline-primary" onClick={() => setEdit(true)}>
            <i className="fe-edit-2" /> {t("common:actions.change")}
          </Button>
          <Button variant="primary" onClick={onClose}>
            Ok
          </Button>
        </Modal.Footer>
      )}
      {edit && (
        <Modal.Footer>
          <Button variant="link" onClick={() => setEdit(false)}>
            {t("common:actions.cancel")}
          </Button>
          <SubmitButton onClick={save} />
        </Modal.Footer>
      )}
    </Modal>
  );
}

export default DescriptionModal;
