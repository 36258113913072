import qs from "qs";
import { parseISO } from "date-fns";
import _ from "lodash";
import client from "./client";

export function companyVerifications(companyId, filters, config) {
  return client.get(`/verifications/`, {
    params: filters,
    headers: {
      "x-company": companyId,
    },
    paramsSerializer: (params) => {
      return qs.stringify(params, { indices: true });
    },
    ...config,
  });
}

export function globalVerifications(filters, config) {
  return client.get(`/verifications/global/`, {
    params: filters,
    paramsSerializer: (params) => {
      return qs.stringify(params, { indices: true });
    },
    ...config,
  });
}

function update(companyId, data) {
  return client.put(`/verifications/${data.id}/update/`, data, {
    headers: {
      "x-company": companyId,
    },
  });
}

function create(companyId, data) {
  return client.post(`/verifications/create/`, data, {
    headers: {
      "x-company": companyId,
    },
  });
}

export function save(companyId, data) {
  if (data.id) {
    return update(companyId, data);
  }
  return create(companyId, data);
}

export function details(companyId, verificationId) {
  return client
    .get(`/verifications/${verificationId}/`, {
      headers: {
        "x-company": companyId,
      },
    })
    .then((response) => ({
      ...response.data,
      booking_date: response.data.booking_date ? parseISO(response.data.booking_date) : null,
      newDocuments: [{ key: _.uniqueId("nd.") }],
    }));
}

export function getPreliminaryNumber(companyId, series, financialYearId) {
  return client.get("/verifications/prelim-number/", {
    params: { series, financial_year: financialYearId },
    headers: {
      "x-company": companyId,
    },
  });
}

export function remove(companyId, verificationId) {
  return client.delete(`/verifications/${verificationId}/delete/`, {
    headers: {
      "x-company": companyId,
    },
  });
}

export function removeLastSeries(companyId, verificationId) {
  return client.delete(`/verifications/${verificationId}/force-delete/`, {
    headers: {
      "x-company": companyId,
    },
  });
}

export function documentsIds(companyId, verificationId) {
  return client
    .get(`/verifications/${verificationId}/documents/ids/`, {
      headers: {
        "x-company": companyId,
      },
    })
    .then((response) => {
      response.data = response.data.documents;
      return response;
    });
}

export function changePeriodisationStatus(companyId, configId, action) {
  return client.patch(`/periodisations/${configId}/${action}/`, null, {
    headers: {
      "x-company": companyId,
    },
  });
}

export const document = {
  file: (companyId, verificationId, documentId, config) => {
    return client.get(`/verifications/${verificationId}/documents/${documentId}/file/`, {
      responseType: "blob",
      headers: {
        "x-company": companyId,
      },
    });
  },
  uploadMany: (companyId, verificationId, files, ocrFileId = "") => {
    const formData = new FormData();
    files.forEach((file, i) => {
      if (file) {
        formData.append("files", file, file.name);
        if (file.description) {
          formData.append("description", file.description);
        }
        if (ocrFileId) {
          formData.append("ocr_file_id", ocrFileId);
        }
      }
    });
    return client.post(`/verifications/${verificationId}/documents/upload/`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
        "x-company": companyId,
      },
    });
  },
  ocrScan: (companyId, file, config) => {
    const formData = new FormData();
    formData.append("file", file, file?.name ?? "");
    return client.post(`/verifications/ocr-scan/`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
        "x-company": companyId,
      },
      ...config,
    });
  },
};

export const comment = {
  list: (companyId, verificationId) => {
    return client.get(`/verifications/${verificationId}/comments/`, {
      params: {
        page_size: 100,
      },
      headers: {
        "x-company": companyId,
      },
    });
  },
  remove: (companyId, verificationId, commentId) => {
    return client.delete(`/verifications/${verificationId}/comments/${commentId}/delete/`, {
      headers: {
        "x-company": companyId,
      },
    });
  },
  create: (companyId, verificationId, text) => {
    return client.post(
      `/verifications/${verificationId}/comments/create/`,
      { text },
      {
        headers: {
          "x-company": companyId,
        },
      }
    );
  },
};

export function history(companyId, verificationId, config) {
  return client.get(`/verifications/${verificationId}/history/`, {
    headers: {
      "x-company": companyId,
    },
    ...config,
  });
}

export function historyGlobal(companyId, verificationId, config) {
  return client.get(`/verifications/${verificationId}/history-global/`, {
    headers: {
      "x-company": companyId,
    },
    ...config,
  });
}
