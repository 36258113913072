import React, { useState } from "react";
import { DraggableModalDialog } from "components/modals/DraggableModalDialog";
import { Button, ButtonGroup, Modal } from "react-bootstrap";
import { useFormikContext } from "formik";
import { SubmitButton } from "components/ui/buttons";
import { Trans, useTranslation } from "react-i18next";
import { MonthDateFilter } from "components/filters";
import * as supportAPI from "api2/office-support";
import { useCompanyState } from "hooks/useCompany";
import { toast } from "react-toastify";

function DeactivateModal({ activity, handleClose, index }) {
  const {
    company: { id: companyId },
  } = useCompanyState();
  const { t } = useTranslation("officeSupport");
  const { values, resetForm } = useFormikContext();
  const [isSubmitting, setSubmitting] = useState(false);
  const [type, setType] = useState("now");
  const [customDate, setCustomDate] = useState(new Date());
  const save = () => {
    setSubmitting(true);
    supportAPI.statusList
      .deactivateActivity(companyId, activity.id, type, customDate)
      .then((response) => {
        const activities = [...values.activities];
        activities[index].is_active = false;
        resetForm({ values: { ...values, activities } });
        toast.success(t("msg:deactivated"));
        handleClose();
      })
      .catch(() => {
        toast.error(t("msg:canNotExecuteAction"));
        setSubmitting(false);
      });
  };
  const onClose = () => {
    handleClose();
  };
  return (
    <Modal
      animation={false}
      scrollable
      show
      onHide={onClose}
      size="md"
      dialogClassName="verification-comments-modal status-list-config-modal deactivate-form"
      dialogAs={DraggableModalDialog}
    >
      <Modal.Header closeButton>
        <div>
          <h1>{t("confirmTaskDeactivationTitle")}</h1>
        </div>
      </Modal.Header>
      <Modal.Body style={{ minHeight: 300 }}>
        <div className="text text-center">
          <Trans i18nKey="officeSupport:confirmTaskDeactivationPrompt" activityName={activity.name_en}>
            You are about to deactivate the task <strong>{{ activityName: activity.name_en }}</strong>. Please select
            when the deactivation should take effect.
            <br />
            <br />
            Note: All events after given deactivation point will be removed.
          </Trans>
        </div>
        <div className="form mt-3">
          <div className="form-group">
            <label className="form-label">{t("stepType")}</label>
            <ButtonGroup className="btn-switch">
              <Button variant="outline-secondary" active={type === "now"} onClick={() => setType("now")}>
                <i className="fe-check" /> {t("common:immediately")}
              </Button>
              <Button variant="outline-secondary" active={type === "custom"} onClick={() => setType("custom")}>
                <i className="fe-check" /> {t("common:custom")}
              </Button>
            </ButtonGroup>
          </div>
          {type === "custom" && (
            <MonthDateFilter
              label={t("common:dates.month")}
              isClearable={false}
              defaultValue={customDate}
              onChange={setCustomDate}
            />
          )}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="link" onClick={onClose}>
          {t("common:actions.cancel")}
        </Button>
        <SubmitButton isSubmitting={isSubmitting} onClick={save} />
      </Modal.Footer>
    </Modal>
  );
}

export default DeactivateModal;
