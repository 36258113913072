import { parseDate } from "utils/date";
import client from "./client";

export function registerBillecta(companyId) {
  return client.patch(`/companies/${companyId}/register_billecta/`);
}

export function initialRepeatedTasks(companyId) {
  return client.post(`companies/${companyId}/time-tracker/repeated-tasks/initialize/`);
}

export function setTTActiveDate(companyId, selected_date, ttActive) {
  return client.put(`companies/${companyId}/time-tracker/repeated-tasks/set_tt_active_date/`, {
    date: selected_date,
    tt_active: ttActive,
  });
}

export function saveRepeatedTask(companyId, taskData) {
  if (taskData.id) {
    return client.put(`companies/${companyId}/time-tracker/repeated-tasks/${taskData.id}/`, taskData);
  }
  return client.post(`companies/${companyId}/time-tracker/repeated-tasks/`, {
    company: companyId,
    ...taskData,
  });
}

export function getOccurrences(companyId, taskData) {
  return client.post(`companies/${companyId}/time-tracker/repeated-tasks/show_occurrences/`, {
    company: companyId,
    ...taskData,
  });
}

export function getCompanyRepeatedTask(companyId, taskId, config) {
  return client.get(`companies/${companyId}/time-tracker/repeated-tasks/${taskId}/`, { ...config });
}

export function removeCompanyTTRepeatedTask(companyId, taskId) {
  return client.delete(`companies/${companyId}/time-tracker/repeated-tasks/${taskId}`);
}

export function saveCompanyManagement(companyId, data) {
  return client.patch(`/companies/${companyId}/manage/`, data);
}

export const fortnox = {
  info: (companyId) => client.get(`/companies/${companyId}/fortnox/info/`),
  connect: (companyId) => client.get(`/companies/${companyId}/fortnox/activate/`),
  disconnect: (companyId) => client.delete(`/companies/${companyId}/fortnox/disconnect/`),
  importCompanyInfo: (companyId) => client.post(`/companies/${companyId}/fortnox/update_company/`, {}),
  importNextInvoiceNo: (companyId) => client.post(`/companies/${companyId}/fortnox/update_next_invoice_no/`, {}),
  importCustomers: (companyId) => client.post(`/companies/${companyId}/fortnox/import_customers/`, {}),
  importProducts: (companyId) => client.post(`/companies/${companyId}/fortnox/import_products/`, {}),
  importCC: (companyId) => client.post(`/companies/${companyId}/fortnox/import_cost_centers/`, {}),
  importProjects: (companyId) => client.post(`/companies/${companyId}/fortnox/import_projects/`, {}),
  importSIE: (companyId, data, config) => client.post(`/companies/${companyId}/fortnox/import_sie/`, data, config),
  getFortnoxYears: (companyId, config) => client.get(`/companies/${companyId}/fortnox/fortnox_years/`, config),
  importVerificationFiles: (companyId, fortnoxYearId) =>
    client.post(`/companies/${companyId}/fortnox/import_files/`, {
      fortnox_year_id: fortnoxYearId,
    }),
};

export function formatRepeatedTask(task) {
  return {
    ...task,
    assignee: task.assignee && task.assignee.value,
    bymonthday: task.bymonthday ? task.bymonthday.map((t) => t.value).join() : "",
    byweekday: task.byweekday ? task.byweekday.map((t) => t.value).join() : "",
    bymonth: task.bymonth ? task.bymonth.map((t) => t.value).join() : "",
    bysetpos: task.bysetpos ? task.bysetpos : null,
  };
}

export const onboarding = {
  create: (companyId) => {
    return client.post(`/companies/${companyId}/onboarding/`);
  },
  changeFinishingStep: (companyId, key, value) => {
    return client.patch(`/companies/${companyId}/onboarding/finishing_steps/`, { [key]: value });
  },
  details: (companyId) => {
    return client.get(`/companies/${companyId}/onboarding/info/`);
  },
  companyInfoStep: {
    get: (companyId, config) => client.get(`/companies/${companyId}/onboarding/company_info/`, config),
    save: (companyId, data) => client.put(`/companies/${companyId}/onboarding/company_info/`, data),
  },
  previousSystemStep: {
    get: (companyId, config) => client.get(`/companies/${companyId}/onboarding/previous_system/`, config),
    rollback: (companyId) => client.delete(`/companies/${companyId}/onboarding/previous_system/`),
    otherSystemPath: (companyId) => client.post(`/companies/${companyId}/onboarding/previous_system/other_system/`),
    fortnoxPath: (companyId) => client.get(`/companies/${companyId}/onboarding/previous_system/fortnox_connect/`),
  },
  generalInfoStep: {
    get: (companyId, config) => client.get(`/companies/${companyId}/onboarding/general_info/`, config),
    save: (companyId, data) => client.put(`/companies/${companyId}/onboarding/general_info/`, data),
  },
  saveAccountMappingStep: {
    get: (companyId, config) => client.get(`/companies/${companyId}/onboarding/save_accounting/`, config),
    save: (companyId, data) => client.put(`/companies/${companyId}/onboarding/save_accounting/`, data),
  },
  importAccountingStep: {
    get: (companyId, config) => client.get(`/companies/${companyId}/onboarding/import_accounting/`, config),
    save: (companyId) => client.put(`/companies/${companyId}/onboarding/import_accounting/`),
  },
  companySettingsStep: {
    get: (companyId, config) => client.get(`/companies/${companyId}/onboarding/company_settings/`, config),
    save: (companyId, data) => client.put(`/companies/${companyId}/onboarding/company_settings/`, data),
  },
  employees: {
    get: (companyId, config) => client.get(`/companies/${companyId}/onboarding/employees/`, config),
    finish: (companyId, config) => client.put(`/companies/${companyId}/onboarding/employees/`, config),
  },
  finishStep: {
    get: (companyId, config) => client.get(`/companies/${companyId}/onboarding/finished/`, config),
    save: (companyId, data) => client.put(`/companies/${companyId}/onboarding/finished/`, data),
  },
  customers: {
    get: (companyId, config) => client.get(`/companies/${companyId}/onboarding/import_customers/`, config),
    save: (companyId, data) => client.put(`/companies/${companyId}/onboarding/import_customers/`, data),
  },
  fortnoxImportStep: {
    get: (companyId, config) =>
      client.get(`/companies/${companyId}/onboarding/import_fortnox/`, config).then((response) => {
        response.data.fortnox_financial_years.forEach((fYear) => {
          fYear.from = parseDate(fYear.from);
          fYear.to = parseDate(fYear.to);
        });
        return response;
      }),
    start: (companyId, financialYearIds) =>
      client.put(`/companies/${companyId}/onboarding/import_fortnox/`, { ids: financialYearIds }),
  },
  fortnoxSaveLedgerStep: {
    get: (companyId, config) => client.get(`/companies/${companyId}/onboarding/save_ledgers/`, config),
    start: (companyId) => client.put(`/companies/${companyId}/onboarding/save_ledgers/`),
  },
  consults: {
    get: (companyId, params, config) =>
      client.get(`/companies/${companyId}/onboarding/consultant_settings/`, { params, ...config }),
    save: (companyId, data, params, config) =>
      client.put(`/companies/${companyId}/onboarding/consultant_settings/`, data, { params, ...config }),
  },
  ttConfig: {
    skip: (companyId, params, config) =>
      client.get(`/companies/${companyId}/onboarding/skip_tt_config/`, { params, ...config }),
  },
};

export const asset = {
  save: (companyId, data, partialUpdateFor) => {
    if (data.id) {
      if (!partialUpdateFor) {
        return client.put(`/companies/${companyId}/assets/${data.id}/`, data);
      }
      return client.put(`/companies/${companyId}/assets/${data.id}/update_${partialUpdateFor}/`, data);
    }
    return client.post(`/companies/${companyId}/assets/`, {
      ...data,
    });
  },
  remove: (companyId, assetId) => client.delete(`/companies/${companyId}/assets/${assetId}/`),
  get: (companyId, assetId) => client.get(`/companies/${companyId}/assets/${assetId}/`),
  decommission: (companyId, assetId, data) =>
    client.put(`/companies/${companyId}/assets/${assetId}/decommission/`, data),
  decommissionPreview: (companyId, assetId, data) =>
    client.put(`/companies/${companyId}/assets/${assetId}/decommission_preview/`, data),
  sell: (companyId, assetId, data) => client.put(`/companies/${companyId}/assets/${assetId}/sell/`, data),
  sellPreview: (companyId, assetId, data) =>
    client.put(`/companies/${companyId}/assets/${assetId}/sell_preview/`, data),
  logs: (companyId, assetId) => client.get(`/asset-history/${assetId}/`),
  previewAccountsSwitch: (companyId, assetId, data, forField) =>
    client.put(`/companies/${companyId}/assets/${assetId}/preview_update_${forField}/`, data),
  extraDepreciation: (companyId, assetId, data) =>
    client.post(`/companies/${companyId}/assets/${assetId}/add_extra_depreciation/`, data),
};
