import React from "react";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import { toast } from "react-toastify";
import { Form, Formik } from "formik";

import * as companyAPI from "api2/companies";
import { AllError, FormGroup } from "components/formik";
import { RemoveButton, SubmitButton } from "components/ui/buttons";
import * as selectAPI from "api2/selects";

function CostCenterForm({ companyId, costCenter, successCallback, deleteCallback }) {
  const { t } = useTranslation("company");

  const formikProps = {
    initialValues: {
      ...costCenter,
    },
    validationSchema: yup.object().shape({
      name: yup.string().required(),
      serial: yup.string().required(),
    }),
    onSubmit: async (values, { setErrors, resetForm }) => {
      await companyAPI.costCenters
        .save(companyId, {
          ...values,
          approver: values.approver ? values.approver.value : null,
          id: costCenter.id,
        })
        .then((response) => {
          if (!costCenter.id) {
            resetForm();
          }
          toast.success(t("msg:saved"), { autoClose: 2000 });
          if (successCallback) {
            successCallback(response.data);
          }
        })
        .catch((error) => {
          toast.error(t("msg:fixErrors"));
          setErrors(error.data);
        });
    },
  };

  const onDelete = async () => {
    companyAPI.costCenters
      .delete(companyId, costCenter.id)
      .then(() => {
        toast.success(t("msg:deleted"));
        if (deleteCallback) {
          deleteCallback();
        }
      })
      .catch((error) => {
        if (error.data.__all__) {
          toast.error(error.data.__all__, { autoClose: 4000 });
        } else {
          toast.error(t("msg:canNotExecuteAction"));
        }
      });
  };

  return (
    <Formik {...formikProps}>
      {({ isSubmitting, errors }) => {
        return (
          <Form>
            <Row>
              <Col sm={6} xl={3}>
                <FormGroup.Input label={t("common:name")} name="name" required />
              </Col>
              <Col sm={6} xl={3}>
                <FormGroup.Input label={t("serialOrNumber")} name="serial" required />
              </Col>
              <Col sm={6} xl={3}>
                <FormGroup.AsyncSelect
                  label={t("assignedToEmployee")}
                  name="approver"
                  loadOptions={() =>
                    selectAPI.companyEmployees(
                      companyId,
                      {
                        mode: "active",
                      },
                      "id"
                    )
                  }
                  minSearchLength={0}
                  isClearable
                />
              </Col>
            </Row>
            <Row>
              {costCenter.id && (
                <Col sm={6} xl={3}>
                  <FormGroup.Checkbox
                    title={t("common:options")}
                    label={t("common:statuses.active")}
                    name="is_active"
                  />
                </Col>
              )}
            </Row>
            <AllError className="mt-2" errors={errors} />
            <hr />
            <div className="space-1">
              <SubmitButton isSubmitting={isSubmitting} />
              {costCenter.id && (
                <RemoveButton
                  variant="danger"
                  confirmMessage={t("confirm.removeCostCenter", {
                    name: costCenter.name,
                  })}
                  disabled={isSubmitting}
                  onClick={onDelete}
                />
              )}
            </div>
          </Form>
        );
      }}
    </Formik>
  );
}

export default CostCenterForm;
