import React, { Suspense } from "react";
import { createRoot } from "react-dom/client";
import "./i18n";
import { ToastContainer } from "react-toastify";

import reportWebVitals from "./reportWebVitals";

import "react-datepicker/dist/react-datepicker.min.css";
import "react-toastify/dist/ReactToastify.css";
import "./assets/scss/bootstrap.scss";
import "./assets/scss/icons.scss";
import "./assets/scss/bflow.scss";

import Root from "./Root";

const root = createRoot(document.getElementById("wrapper"));

// react bootstrap no longer supported - this suppresses warning
const { error } = console;
console.error = (...args) => {
  if (/defaultProps/.test(args[0])) return;
  if (/findDOMNode/.test(args[0])) return;
  error(...args);
};

root.render(
  // <React.StrictMode>
  <>
    <Suspense fallback={<p>....</p>}>
      <Root />
    </Suspense>

    <ToastContainer
      position="top-right"
      autoClose={4000}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      pauseOnHover
    />
    {/* </React.StrictMode>, */}
  </>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
