import client from "api2/client";
import { parseDate } from "utils/date";
import { parseISO } from "date-fns";

export function companySalaries(companyId, filters, config) {
  return client.get(`/salaries/`, {
    params: {
      ...filters,
      payment_date__year: filters.payment_year ? parseDate(filters.payment_year).getFullYear() : null,
      payment_date__month: filters.payment_month ? parseDate(filters.payment_month).getMonth() + 1 : null,
    },
    headers: {
      "x-company": companyId,
    },
    ...config,
  });
}

export function details(companyId, salaryId, config) {
  return client
    .get(`/salaries/${salaryId}/`, {
      headers: {
        "x-company": companyId,
      },
      ...config,
    })
    .then((response) => ({
      ...response.data,
      booking_date: response.data.booking_date ? parseISO(response.data.booking_date) : null,
    }));
}

export function save(companyId, data, config) {
  if (data.id) {
    return client.put(`/salaries/${data.id}/update/`, data, {
      headers: {
        "x-company": companyId,
      },
      ...config,
    });
  }
  return client.post(`/salaries/create/`, data, {
    headers: {
      "x-company": companyId,
    },
    ...config,
  });
}

export function remove(companyId, salaryId, params, config) {
  return client.delete(`/salaries/${salaryId}/delete/`, {
    params,
    headers: {
      "x-company": companyId,
    },
    ...config,
  });
}

export function manualPay(companyId, salaryId, data, config) {
  return client.post(`/salaries/${salaryId}/payments/pay-manual/`, data, {
    headers: {
      "x-company": companyId,
    },
    ...config,
  });
}

export function cancelPayment(companyId, salaryId, config) {
  return client.delete(`/salaries/${salaryId}/payments/cancel/`, {
    headers: {
      "x-company": companyId,
    },
    ...config,
  });
}

export function pay(companyId, salaryId, bankIDToken, config) {
  return client.post(
    `/salaries/${salaryId}/payments/pay-bgc/`,
    {
      bank_id_token: bankIDToken,
    },
    {
      headers: {
        "x-company": companyId,
      },
      ...config,
    }
  );
}

export function movePrelim(companyId, salaryId, config) {
  return client.patch(
    `/salaries/${salaryId}/prelim/`,
    {},
    {
      headers: {
        "x-company": companyId,
      },
      ...config,
    }
  );
}

export function approve(companyId, salaryId, params, config) {
  return client.patch(`/salaries/${salaryId}/approve/`, null, {
    params,
    headers: {
      "x-company": companyId,
    },
    ...config,
  });
}

export function specificationDocumentUpload(companyId, salaryId, file, fileId) {
  const formData = new FormData();
  formData.append("file", file, file?.name);
  if (fileId) {
    formData.append("file_id", fileId);
  }

  return client.post(`/salaries/${salaryId}/documents/upload/`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
      "x-company": companyId,
    },
  });
}

export function holidayAllSocCreate(companyId, data, config) {
  return client.post(`/salaries/holiday-all-soc/create/`, data, {
    headers: {
      "x-company": companyId,
    },
    ...config,
  });
}

export function socialSecReductionCreate(companyId, data, config) {
  return client.post(`/salaries/social-sec-reduction/create/`, data, {
    headers: {
      "x-company": companyId,
      "Content-Type": "multipart/form-data",
    },
    ...config,
  });
}

export function history(companyId, salaryId, config) {
  return client.get(`/salaries/${salaryId}/history/`, {
    headers: {
      "x-company": companyId,
    },
    ...config,
  });
}
