import client from "api2/client";
import { formatDate } from "utils/date";
import qs from "qs";

export const statusIndicators = {
  details: (companyId, config) => {
    return client.get(`/office-support/status-indicators/detail/`, {
      headers: {
        "x-company": companyId,
      },
      ...config,
    });
  },
  update: (companyId, data, config) => {
    return client.put(`/office-support/status-indicators/update/`, data, {
      headers: {
        "x-company": companyId,
      },
      ...config,
    });
  },
};

export const accounting = {
  details: (companyId, config) => {
    return client.get(`/office-support/accounting/detail/`, {
      headers: {
        "x-company": companyId,
      },
      ...config,
    });
  },
  update: (companyId, data, config) => {
    return client.put(`/office-support/accounting/update/`, data, {
      headers: {
        "x-company": companyId,
      },
      ...config,
    });
  },
};

export const clientDetails = {
  details: (companyId, config) => {
    return client.get(`/office-support/client/detail/`, {
      headers: {
        "x-company": companyId,
      },
      ...config,
    });
  },
  update: (companyId, data, config) => {
    return client.put(`/office-support/client/update/`, data, {
      headers: {
        "x-company": companyId,
      },
      ...config,
    });
  },
};
export const internal = {
  details: (companyId, config) => {
    return client.get(`/office-support/internal/detail/`, {
      headers: {
        "x-company": companyId,
      },
      ...config,
    });
  },
  update: (companyId, data, config) => {
    return client.put(`/office-support/internal/update/`, data, {
      headers: {
        "x-company": companyId,
      },
      ...config,
    });
  },
};

export const contacts = {
  list: (companyId, config) => {
    return client.get(`/office-support/contacts/`, {
      headers: {
        "x-company": companyId,
      },
      ...config,
    });
  },
  updateBulk: (companyId, data, config) => {
    return client.post(`/office-support/contacts/bulk-update/`, data, {
      headers: {
        "x-company": companyId,
      },
      ...config,
    });
  },
};

export const businessProfile = {
  details: (companyId, config) => {
    return client.get(`/office-support/business-profile/detail/`, {
      headers: {
        "x-company": companyId,
      },
      ...config,
    });
  },
  update: (companyId, data, config) => {
    return client.put(`/office-support/business-profile/update/`, data, {
      headers: {
        "x-company": companyId,
      },
      ...config,
    });
  },
};

export const roles = {
  consultsAccess: (companyId, config) => {
    return client.get(`/office-support/consult-accesses/`, {
      headers: {
        "x-company": companyId,
      },
      ...config,
    });
  },
  essential: {
    details: (companyId, config) => {
      return client.get(`/office-support/roles/essential/detail/`, {
        headers: {
          "x-company": companyId,
        },
        ...config,
      });
    },
    update: (companyId, data, config) => {
      return client.put(`/office-support/roles/essential/update/`, data, {
        headers: {
          "x-company": companyId,
        },
        ...config,
      });
    },
  },
  additional: {
    details: (companyId, config) => {
      return client.get(`/office-support/roles/additional/detail/`, {
        headers: {
          "x-company": companyId,
        },
        ...config,
      });
    },
    update: (companyId, data, config) => {
      return client.put(`/office-support/roles/additional/update/`, data, {
        headers: {
          "x-company": companyId,
        },
        ...config,
      });
    },
  },
  bank: {
    list: (companyId, config) => {
      return client.get(`/office-support/roles/bank-attorney/`, {
        headers: {
          "x-company": companyId,
        },
        ...config,
      });
    },
    bulkUpdate: (companyId, data, config) => {
      return client.post(`/office-support/roles/bank-attorney/bulk-update/`, data, {
        headers: {
          "x-company": companyId,
        },
        ...config,
      });
    },
  },
  tax: {
    list: (companyId, config) => {
      return client.get(`/office-support/roles/tax-attorney/`, {
        headers: {
          "x-company": companyId,
        },
        ...config,
      });
    },
    bulkUpdate: (companyId, data, config) => {
      return client.post(`/office-support/roles/tax-attorney/bulk-update/`, data, {
        headers: {
          "x-company": companyId,
        },
        ...config,
      });
    },
  },
  other: {
    list: (companyId, config) => {
      return client.get(`/office-support/roles/other-attorney/`, {
        headers: {
          "x-company": companyId,
        },
        ...config,
      });
    },
    bulkUpdate: (companyId, data, config) => {
      return client.post(`/office-support/roles/other-attorney/bulk-update/`, data, {
        headers: {
          "x-company": companyId,
        },
        ...config,
      });
    },
  },
};

export const assessment = {
  detail: (companyId, config) => {
    return client.get(`/office-support/assessment/detail/`, {
      headers: {
        "x-company": companyId,
      },
      ...config,
    });
  },
  update: (companyId, data, config) => {
    return client.put(`/office-support/assessment/update/`, data, {
      headers: {
        "x-company": companyId,
      },
      ...config,
    });
  },
};

export const reassessment = {
  detail: (companyId, config) => {
    return client.get(`/office-support/reassessment/detail/`, {
      headers: {
        "x-company": companyId,
      },
      ...config,
    });
  },
  update: (companyId, data, config) => {
    return client.put(`/office-support/reassessment/update/`, data, {
      headers: {
        "x-company": companyId,
      },
      ...config,
    });
  },
};

export const notes = {
  list: (companyId, filters, config) => {
    return client.get(`/office-support/customer-notes/`, {
      params: {
        ...filters,
        period_start: filters.period_start ? formatDate(filters.period_start) : null,
        period_end: filters.period_end ? formatDate(filters.period_end) : null,
      },
      headers: {
        "x-company": companyId,
      },
      ...config,
    });
  },
  details: (companyId, noteId, config) => {
    return client.get(`/office-support/customer-notes/${noteId}/`, {
      headers: {
        "x-company": companyId,
      },
      ...config,
    });
  },
  save: (companyId, data, newFile, config) => {
    const formData = new FormData();
    Object.keys(data).forEach((key) => {
      if (data[key]) {
        formData.append(key, data[key]);
      }
    });
    if (newFile) {
      formData.append("file", newFile, newFile.name);
    }
    if (data.id) {
      return client.put(`/office-support/customer-notes/${data.id}/update/`, formData, {
        headers: {
          "x-company": companyId,
          "Content-Type": "multipart/form-data",
        },
        ...config,
      });
    }
    return client.post(`/office-support/customer-notes/create/`, formData, {
      headers: {
        "x-company": companyId,
        "Content-Type": "multipart/form-data",
      },
      ...config,
    });
  },
  delete: (companyId, noteId, config) => {
    return client.delete(`/office-support/customer-notes/${noteId}/delete/`, {
      headers: {
        "x-company": companyId,
      },
      ...config,
    });
  },
  deleteDocument: (companyId, noteId, documentId, config) => {
    return client.delete(`/office-support/customer-notes/${noteId}/documents/${documentId}/delete/`, {
      headers: {
        "x-company": companyId,
      },
      ...config,
    });
  },
  archive: (companyId, noteId, config) => {
    return client.patch(`/office-support/customer-notes/${noteId}/archive/`, null, {
      headers: {
        "x-company": companyId,
      },
      ...config,
    });
  },
  unarchive: (companyId, noteId, config) => {
    return client.patch(`/office-support/customer-notes/${noteId}/unarchive/`, null, {
      headers: {
        "x-company": companyId,
      },
      ...config,
    });
  },
  child: {
    save: (companyId, noteId, data, newFile, config) => {
      const formData = new FormData();
      Object.keys(data).forEach((key) => {
        if (data[key]) {
          formData.append(key, data[key]);
        }
      });
      if (newFile) {
        formData.append("file", newFile, newFile.name);
      }
      if (data.id) {
        return client.put(`/office-support/customer-notes/${noteId}/child/${data.id}/update/`, formData, {
          headers: {
            "x-company": companyId,
            "Content-Type": "multipart/form-data",
          },
          ...config,
        });
      }
      return client.post(`/office-support/customer-notes/${noteId}/child/create/`, formData, {
        headers: {
          "x-company": companyId,
          "Content-Type": "multipart/form-data",
        },
        ...config,
      });
    },
    delete: (companyId, noteId, childId, config) => {
      return client.delete(`/office-support/customer-notes/${noteId}/child/${childId}/delete/`, {
        headers: {
          "x-company": companyId,
        },
        ...config,
      });
    },
  },
};

const prepareQueryParams = (filters) => {
  const queryParams = { ...filters };
  if (queryParams.org_no && filters.org_no.length > 0) {
    queryParams.ids = queryParams.org_no;
    delete queryParams.org_no;
  }
  if (filters.name && filters.name.length > 0) {
    queryParams.ids = queryParams.name;
    delete queryParams.name;
  }
  return queryParams;
};

export const exporter = {
  list: (filters, config) => {
    const queryParams = prepareQueryParams(filters);
    return client.get(`/office-support/export/`, {
      params: queryParams,
      ...config,
      paramsSerializer: (params) => {
        return qs.stringify(params, { indices: false });
      },
    });
  },
  exportCSV: (filters, data, config) => {
    const queryParams = prepareQueryParams(filters);
    return client.post(`/office-support/export/csv/`, data, {
      params: queryParams,
      responseType: "blob",
      ...config,
      paramsSerializer: (params) => {
        return qs.stringify(params, { indices: false });
      },
    });
  },
  select: (filters, config) => {
    const queryParams = prepareQueryParams(filters);
    return client.get(`/office-support/export/select/`, {
      params: queryParams,
      ...config,
      paramsSerializer: (params) => {
        return qs.stringify(params, { indices: false });
      },
    });
  },
};

export const statusList = {
  templates: (filters, config) => {
    return client.get(`/office-support/status-list/templates/`, {
      params: filters,
      ...config,
    });
  },
  activities: (companyId, config) => {
    return client.get(`/office-support/status-list/activities/`, {
      headers: {
        "x-company": companyId,
      },
      ...config,
    });
  },
  generate: (companyId, startDate, config) => {
    return client.post(
      `/office-support/status-list/activities/generate/`,
      { start_date: startDate },
      {
        headers: {
          "x-company": companyId,
        },
        ...config,
      }
    );
  },
  reset: (companyId, config) => {
    return client.delete(`/office-support/status-list/activities/reset/`, {
      headers: {
        "x-company": companyId,
      },
      ...config,
    });
  },
  updateMany: (companyId, data, config) => {
    return client.post(`/office-support/status-list/activities/update-many/`, data, {
      headers: {
        "x-company": companyId,
      },
      ...config,
    });
  },
  deactivateActivity: (companyId, activityId, stepType, stepDate, config) => {
    return client.put(
      `/office-support/status-list/activities/${activityId}/deactivate/`,
      { step_type: stepType, step_date: stepDate && formatDate(stepDate) },
      {
        headers: {
          "x-company": companyId,
        },
        ...config,
      }
    );
  },
  activateActivity: (companyId, activityId, data, config) => {
    return client.put(`/office-support/status-list/activities/${activityId}/activate/`, data, {
      headers: {
        "x-company": companyId,
      },
      ...config,
    });
  },
  activateActivityCheck: (companyId, activityId, startDate, periodicity, config) => {
    return client.get(`/office-support/status-list/activities/${activityId}/activation-check/`, {
      params: { start_date: startDate && formatDate(startDate), periodicity },
      headers: {
        "x-company": companyId,
      },
      ...config,
    });
  },
  updateNote: (companyId, activityId, note, config) => {
    return client.put(
      `/office-support/status-list/activities/${activityId}/update-note/`,
      { note },
      {
        headers: {
          "x-company": companyId,
        },
        ...config,
      }
    );
  },
  events: {
    list: (filters, config) => {
      return client.get(`/office-support/status-list/events/`, {
        params: filters,
        paramsSerializer: (params) => {
          return qs.stringify(params, { indices: false });
        },
        ...config,
      });
    },
    updateStatus: (eventId, newStatus, config) => {
      return client.patch(
        `/office-support/status-list/events/${eventId}/update-status/`,
        { status: newStatus },
        config
      );
    },
    updateAssignee: (eventId, newAssignee, config) => {
      return client.patch(
        `/office-support/status-list/events/${eventId}/update-assignee/`,
        { assignee: newAssignee },
        config
      );
    },
    comments: {
      list: (eventId, config) => {
        return client.get(`/office-support/status-list/events/${eventId}/comments/`, {
          params: {
            page_size: 100,
          },
          ...config,
        });
      },
      remove: (eventId, commentId) => {
        return client.delete(`/office-support/status-list/events/${eventId}/comments/${commentId}/delete/`);
      },
      create: (eventId, text, config) => {
        return client.post(`/office-support/status-list/events/${eventId}/comments/create/`, { text }, config);
      },
    },
  },
};
