import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Col, Row } from "react-bootstrap";

import { BExpandedTable } from "components/tables/btable";
import { BankRuleEditWidget } from "components/forms/company-settings-forms/BankRuleForm";
import Actions from "./BankRulesActions";

function BankRulesExtendedTable({ companyId, companyAccounts }) {
  const { t } = useTranslation(["company", "options"]);
  const headers = useMemo(
    () => [
      {
        field: "rule_id",
        label: "#",
        canSort: false,
        width: 50,
      },
      {
        field: "condition",
        label: t("bankRule.condition"),
        render: ({ condition }) => t(`options:${condition}`),
      },
      {
        field: "search_text",
        label: t("bankRule.text"),
      },
    ],
    [t]
  );

  return (
    <Row>
      <Col>
        <BExpandedTable
          headers={headers}
          renderActions={(row, index) => <Actions companyId={companyId} row={row} index={index} />}
          renderExpandedRow={(row) => (
            <BankRuleEditWidget companyId={companyId} id={row.id} companyAccounts={companyAccounts} />
          )}
        />
      </Col>
    </Row>
  );
}

export default BankRulesExtendedTable;
