import { useMemo } from "react";
import { useAuthState } from "hooks/useAuth";
import { useCompanyState } from "hooks/useCompany";

export function codeInList(code, codeList) {
  if (codeList) {
    return codeList.includes(code);
  }
  return false;
}

export function CodeRequiredInList({ code, codeList, children }) {
  if (codeInList(code, codeList)) {
    return children;
  }
  return null;
}

export function HasPermCode(code) {
  const { userPermCodes: companyStatePermsCodes } = useCompanyState();
  const _userPermCodes = useMemo(() => {
    return companyStatePermsCodes;
  }, [companyStatePermsCodes]);
  return codeInList(code, _userPermCodes);
}

export function PermCodeRequired({ code, children }) {
  if (HasPermCode(code)) {
    return children;
  }
  return null;
}

export function StaffPermRequired({ agencyConsult, children }) {
  const { user } = useAuthState();

  if (user.is_superuser) {
    return children;
  }
  if (agencyConsult && user.is_consult) {
    return children;
  }
  return null;
}

export function HasAnyPermCode(exclude = []) {
  const { userPermCodes: companyStatePermsCodes } = useCompanyState();
  const _userPermCodes = useMemo(() => {
    return companyStatePermsCodes;
  }, [companyStatePermsCodes]);
  return _userPermCodes ? _userPermCodes.filter((x) => !exclude.includes(x)).length > 0 : false;
}
