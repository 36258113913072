import React, { useCallback, useEffect, useMemo, useState } from "react";
import * as companyAPI from "api/company";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

const OnboardingStateContext = React.createContext(undefined);
const OnboardingDispatchContext = React.createContext(undefined);

function OnboardingProvider({ companyId, initialStep, children }) {
  const [state, setState] = useState({
    companyName: "",
    loading: true,
    activeStep: undefined,
    prevSystem: undefined,
    steps: [],
  });
  const { t } = useTranslation("company");
  const fetchDetails = useCallback(
    (goNext = false, initialFromQueryParam = false, welcomeInteraction = false) => {
      companyAPI.onboarding
        .details(companyId)
        .then((response) => {
          const steps = response.data.progress || [];
          setState((_state) => {
            let activeStep;
            if (initialFromQueryParam && initialStep) {
              const activeStepIdx = steps.findIndex((step) => step.id === initialStep);
              if (activeStepIdx >= 0 && steps[activeStepIdx].status !== "locked") {
                activeStep = {
                  ...steps[activeStepIdx],
                  number: activeStepIdx + 1,
                };
              }
            }
            if (!activeStep) {
              if (!_state.activeStep) {
                const activeStepIdx = steps.findIndex((step) => step.id === response.data.current_step);
                activeStep = {
                  ...steps[activeStepIdx],
                  number: activeStepIdx + 1,
                };
              } else if (goNext) {
                let nextStepIdx = steps.findIndex((step) => step.id === _state.activeStep.id) + 1;
                if (nextStepIdx > steps.length) {
                  nextStepIdx = 0;
                }
                if (steps[nextStepIdx].status === "locked") {
                  toast.warning(t("onboarding.nextStepLocked"));
                  const pendingStatuses = ["not_started", "in_progress"];
                  const availableStepIdx = steps.findIndex((step) => pendingStatuses.includes(step.status));
                  if (availableStepIdx !== -1) {
                    activeStep = { ...steps[availableStepIdx], number: availableStepIdx + 1 };
                  }
                } else {
                  activeStep = { ...steps[nextStepIdx], number: nextStepIdx + 1 };
                }
              } else {
                activeStep = { ..._state.activeStep, ...steps.find((s) => s.id === _state.activeStep.id) };
              }
            }

            if (welcomeInteraction) {
              activeStep = { ..._state.activeStep, welcome: true };
            }
            return {
              companyName: response.data.company_name,
              loading: false,
              steps,
              activeStep,
              prevSystem: response.data.previous_system,
              sieImported: response.data.was_sie_file_imported,
            };
          });
        })
        .catch(() => {
          setState({ loading: false, activeStep: undefined, steps: [], prevSystem: undefined });
        });
    },
    [companyId, initialStep, t]
  );

  useEffect(() => {
    fetchDetails(false, true);
  }, [fetchDetails]);

  const goTo = useCallback(
    (stepId) => {
      const stepIndex = state.steps.findIndex((s) => s.id === stepId);
      setState((s) => ({ ...s, activeStep: { ...s.steps[stepIndex], number: stepIndex + 1 } }));
    },
    [state.steps]
  );

  const goToNext = useCallback(() => {
    const nextIndex = state.steps.findIndex((s) => s.id === state.activeStep.id) + 1;
    if (nextIndex <= state.steps.length) {
      if (state.steps[nextIndex].status === "locked") {
        toast.warning(t("onboarding.stepLocked"));
        return;
      }
      setState((s) => ({ ...s, activeStep: { ...s.steps[nextIndex], number: nextIndex + 1 } }));
    }
  }, [t, state.activeStep, state.steps]);

  const goToPrev = useCallback(() => {
    const prevIndex = state.steps.findIndex((s) => s.id === state.activeStep.id) - 1;
    if (prevIndex >= 0) {
      if (state.steps[prevIndex].status === "locked") {
        toast.warning(t("onboarding.stepLocked"));
        return;
      }
      setState((s) => ({ ...s, activeStep: { ...s.steps[prevIndex], number: prevIndex + 1 } }));
    }
  }, [t, state.activeStep, state.steps]);

  const stateValue = useMemo(
    () => ({
      loading: state.loading,
      companyId,
      companyName: state.companyName,
      prevSystem: state.prevSystem,
      activeStep: state.activeStep,
      steps: state.steps,
      sieImported: state.sieImported,
    }),
    [companyId, state.loading, state.activeStep, state.steps, state.prevSystem, state.sieImported, state.companyName]
  );
  const dispatchValue = useMemo(
    () => ({
      goToNext,
      goToPrev,
      goTo,
      fetchDetails,
    }),
    [goTo, goToNext, goToPrev, fetchDetails]
  );

  return (
    <OnboardingStateContext.Provider value={stateValue}>
      <OnboardingDispatchContext.Provider value={dispatchValue}>{children}</OnboardingDispatchContext.Provider>
    </OnboardingStateContext.Provider>
  );
}

export { OnboardingStateContext, OnboardingDispatchContext };

export default OnboardingProvider;
