import React from "react";
import { NavLink, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import LeftSidebar from "components/ui/sidebar";
import LayoutSwitcherMenuItem from "components/layouts/LayoutSwitcherMenuItem";
import { isStatusListEnabled } from "utils/permissions/helpers";
import { useAuthState } from "hooks/useAuth";

function Menu() {
  const { t } = useTranslation("navigation");
  const {
    user: { id },
  } = useAuthState();
  const statusListEnabled = isStatusListEnabled(id);
  const loc = useLocation().pathname;
  return (
    <LeftSidebar>
      <LayoutSwitcherMenuItem />
      <li>
        <NavLink to="/office-support-export" end replace>
          <i className="fe-download" />
          <span> {t("export")}</span>
        </NavLink>
      </li>
      {statusListEnabled && (
        <li>
          <NavLink
            to="/office-support/status-list/ongoing"
            className={({ isActive }) => {
              return isActive || loc.includes("/status-list/") ? "active" : undefined;
            }}
          >
            <i className="fe-check-square" />
            <span> {t("statusList")}</span>
          </NavLink>
        </li>
      )}
    </LeftSidebar>
  );
}

export default Menu;
