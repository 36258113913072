import React from "react";
import { createRoot } from "react-dom/client";

import { PayBankIDModal } from "components/modals";
import ConfirmModal from "./ConfirmModal";
import ConfirmModalWithAction from "./ConfirmModalWithAction";
import RemoveConfirmModal from "./RemoveConfirmModal";
import ConfirmInfoModal from "./ConfirmInfoModal";

const customConfirm = (DialogContent) =>
  new Promise((res) => {
    const root = createRoot(document.createElement("div"));
    const giveAnswer = (answer) => {
      root.unmount();
      res(answer);
    };
    root.render(<DialogContent giveAnswer={giveAnswer} />);
  });

const confirmRemove = (description) =>
  customConfirm(({ giveAnswer }) => <RemoveConfirmModal description={description} giveAnswer={giveAnswer} />);

const confirmExecute = (description, btnTitle, cancelBtnTitle, isSafeAction) =>
  customConfirm(({ giveAnswer }) => (
    <ConfirmModal
      description={description}
      btnTitle={btnTitle}
      cancelBtnTitle={cancelBtnTitle}
      giveAnswer={giveAnswer}
      isSafeAction={isSafeAction}
    />
  ));

const confirmExecuteWithAction = (description, onConfirmCallback) =>
  customConfirm(({ giveAnswer }) => (
    <ConfirmModalWithAction description={description} giveAnswer={giveAnswer} onConfirmCallback={onConfirmCallback} />
  ));

const confirmBankID = (bankIDMsg) => {
  return new Promise((resolve, reject) => {
    const root = createRoot(document.createElement("div"));
    const onConfirm = (token) => {
      root.unmount();
      resolve(token);
    };
    const onCancel = () => {
      root.unmount();
      resolve(null);
    };
    root.render(<PayBankIDModal handleSuccess={onConfirm} handleClose={onCancel} bankIDMsg={bankIDMsg} />);
  });
};

const confirmInfo = (description) =>
  customConfirm(({ giveAnswer }) => <ConfirmInfoModal description={description} giveAnswer={giveAnswer} />);

export { confirmRemove, confirmExecute, confirmExecuteWithAction, confirmBankID, confirmInfo };

export default customConfirm;
