import React, { useState } from "react";

import useTable from "components/tables/btable/useTable";
import {
  MultiAgencyFilter,
  MultiCompanyConsultFilter,
  MultiSelectSimpleFilter,
  SearchFilter,
  SelectSimpleFilter,
} from "components/filters";
import { SelectCompanyToOnboardModal } from "components/modals";
import PostRegisterCompanyConfirmTT from "components/modals/RegisterCompanyModal/PostRegisterCompanyConfirmTT";
import useModal from "hooks/useModal";
import { Button, ButtonGroup } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import { FilterButton } from "components/ui/buttons";
import { codesForAgency } from "components/perms/PermCodes";
import { useAuthState } from "hooks/useAuth";
import * as options from "../../../api/options";

function OnboardingFilters({ forSteps = false }) {
  const { user } = useAuthState();
  const navigate = useNavigate();
  const { filters, setFilters } = useTable();
  const { t } = useTranslation();
  const filtersCount =
    (filters.company_consults ? !!filters.company_consults.length : 0) +
    (filters.company_statuses ? !!filters.company_statuses.length : 0) +
    (filters.onboarding_statuses ? !!filters.onboarding_statuses.length : 0) +
    !!filters.previous_system +
    (filters.agencies ? !!filters.agencies.length : 0);
  const [more, setMore] = useState(!!filtersCount);
  const selectCompanyModal = useModal();
  const postTTConfigModal = useModal();
  const systemOptions = [
    { value: "fortnox", label: "Fortnox" },
    { value: "other", label: t("company:onboarding.otherSystem") },
  ];
  const companyStatuses = options.companyStatuses.asList();
  const onSelectCompanySumbit = (company, ttConfig = false) => {
    selectCompanyModal.close();
    if (company && ttConfig) {
      postTTConfigModal.open({ companyId: company.id, companyName: company.name });
    } else if (company) {
      navigate(`/onboarding/${company.id}`);
    }
  };
  const onSelectCompanyClose = () => {
    selectCompanyModal.close();
  };

  const canCompanyRegister =
    user.agencies.some((agency) => agency.perm_codes.includes(codesForAgency.companyRegister)) || user.is_superuser;
  return (
    <>
      <section className="table-filters-left">
        <SearchFilter onFilter={setFilters} defaultValue={filters.term} width={300} autoFocus />
        <FilterButton active={more} count={filtersCount} onClick={() => setMore(!more)} />
        <ButtonGroup>
          <Button as={Link} to="/onboarding" variant={forSteps ? "outline-secondary" : "primary"}>
            {t("common:progress")}
          </Button>
          <Button as={Link} to="/onboarding/finishing-steps" variant={forSteps ? "primary" : "outline-secondary"}>
            {t("company:finishingSteps")}
          </Button>
        </ButtonGroup>
      </section>
      {canCompanyRegister && (
        <section className="table-filters-right">
          <Button variant="first" onClick={selectCompanyModal.open}>
            <i className="fas fa-plus" />
            <span> {t("company:selectCompanyToOnboard")}</span>
          </Button>
        </section>
      )}
      {more && (
        <section className="more-filters">
          <MultiCompanyConsultFilter
            name="company_consults"
            onFilter={setFilters}
            placeholder={t("common:consult")}
            defaultValue={filters.company_consults}
          />
          <MultiSelectSimpleFilter
            isClearable
            placeholder={t("common:statuses.status")}
            name="company_statuses"
            onFilter={setFilters}
            defaultValue={filters.company_statuses}
            options={companyStatuses}
          />
          <MultiSelectSimpleFilter
            isClearable
            placeholder={t("company:onboardingStatus")}
            name="onboarding_statuses"
            onFilter={setFilters}
            defaultValue={filters.onboarding_statuses}
            options={[
              { label: t("company:onboarding.error"), value: "error" },
              { label: t("company:onboarding.not_started"), value: "not_started" },
              { label: t("company:onboarding.in_progress"), value: "in_progress" },
              { label: t("company:onboarding.finished"), value: "finished" },
            ]}
          />
          <SelectSimpleFilter
            isClearable
            placeholder={t("company:previousSystem")}
            name="previous_system"
            onFilter={setFilters}
            defaultValue={filters.previous_system}
            options={systemOptions}
          />
          <MultiAgencyFilter
            name="agencies"
            onFilter={setFilters}
            placeholder={t("common:agency")}
            defaultValue={filters.agencies}
          />
        </section>
      )}
      {selectCompanyModal.show && (
        <SelectCompanyToOnboardModal handleClose={onSelectCompanyClose} handleSubmit={onSelectCompanySumbit} />
      )}
      {postTTConfigModal.show && postTTConfigModal.data && (
        <PostRegisterCompanyConfirmTT
          companyId={postTTConfigModal.data.companyId}
          companyName={postTTConfigModal.data.companyName}
          close={postTTConfigModal.close}
        />
      )}
    </>
  );
}

export default OnboardingFilters;
