import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { FieldArray, Form, Formik, useFormikContext } from "formik";
import { Button, Card, Col, Row } from "react-bootstrap";
import { ConsultPicker, FormGroup } from "components/formik";
import { SubmitButton } from "components/ui/buttons";
import { toast } from "react-toastify";
import * as supportApi from "api2/office-support";
import * as options from "api/options";
import * as yup from "yup";
import OtherInputSelect from "../OfficeSupportAccountingForm/OtherOptionSimpleSelect";

function OfficeSupportRolesBankAttorneyForm({ company, changeEditedForm, initialData }) {
  const { t } = useTranslation("company");
  const [showMore, setShowMore] = useState(true);
  const bankOptions = options.bankChoices.asList();
  const permissionsOptions = [
    { value: "view", label: t("viewOnly") },
    { value: "pay", label: t("pay") },
  ];

  const bankName = (item) => {
    if (item?.bank_name) {
      return item.bank_name === "other"
        ? options.bankChoices.getOption("other")
        : options.bankChoices.getOption(item.bank_name);
    }
    return "";
  };
  const emptyBankAttorney = {
    consult: null,
    bank_name: bankName(company),
    other_bank_name: company.bank_name === "other" ? company.other_bank_name : "",
    permission: "",
    id: "",
  };

  const formikProps = {
    initialValues: {
      bank_attorneys:
        initialData.length > 0
          ? initialData.map((item) => ({
              consult: {
                value: item.consult,
                label: item.consult_name,
              },
              bank_name: bankName(item),
              other_bank_name: item.bank_name === "other" ? item.other_bank_name : "",
              permission: item.permission,
              id: item.id,
            }))
          : [emptyBankAttorney],
    },
    validationSchema: yup.object().shape({
      bank_attorneys: yup.array().of(
        yup.object().shape({
          consult: yup.object().nullable().required(),
          bank_name: yup.object().required(),
          other_bank_name: yup.string().when("bank_name", {
            is: (bank_name) => bank_name?.value === "other",
            then: () => yup.string().required(),
            otherwise: () => yup.string().notRequired(),
          }),
          permission: yup.string().required().oneOf(["view", "pay"]),
        })
      ),
    }),
    onSubmit: async (values, { setErrors, resetForm }) => {
      await supportApi.roles.bank
        .bulkUpdate(company.id, {
          ...values,
          bank_attorneys: values.bank_attorneys
            .filter((item) => item.consult)
            .map((item) => ({
              consult: item.consult.value,
              bank_name: item.bank_name?.value || null,
              other_bank_name: item.bank_name && item.bank_name.value === "other" ? item.other_bank_name || "" : "",
              permission: item.permission,
              id: item.id || null,
            })),
        })
        .then((response) => {
          resetForm({ values });
          toast.success(t("msg:saved"), { autoClose: 2000 });
        })
        .catch((error) => {
          toast.error(t("msg:fixErrors"));
          if (error.data?.__all__) {
            toast.error(error.data.__all__, { autoClose: 5000 });
            setErrors({ __all__: error.data.__all__ });
          } else {
            setErrors({ ...error.data });
          }
        });
    },
  };

  function FormUpdateChecker() {
    const { dirty } = useFormikContext();
    React.useEffect(() => {
      changeEditedForm({ id: "roles-bank-attorney-form", dirty });
    }, [dirty]);
    return null;
  }

  return (
    <Formik {...formikProps}>
      {({ values, touched, isSubmitting, setFieldValue, errors, resetForm, dirty }) => {
        return (
          <Form>
            <FormUpdateChecker />
            <Card>
              <Card.Header onClick={() => setShowMore((prevValue) => !prevValue)}>
                <Card.Title style={{ color: "#ADADAD", fontWeight: 400, fontSize: "12px" }}>
                  {t("header.bankAttorney")}
                  <i
                    style={{ textAlign: "right", float: "right" }}
                    className={showMore ? "fas fa-chevron-up fa-1x" : "fas fa-chevron-down fa-1x"}
                  />
                </Card.Title>
              </Card.Header>
              {showMore && (
                <Card.Body className="pt-0">
                  <FieldArray
                    name="bank_attorneys"
                    render={(arrayHelpers) => (
                      <>
                        {values.bank_attorneys.map((attorney, index) => (
                          <React.Fragment key={index}>
                            <Row key={`bank-button.${index}`}>
                              <Col>
                                {values.bank_attorneys.length > 1 && (
                                  <>
                                    {index + 1}.
                                    <Button style={{ color: "#FD6767" }} variant="text" size="lg" className="pl-1">
                                      <i className="fe-trash" onClick={() => arrayHelpers.remove(index)} />
                                    </Button>
                                  </>
                                )}
                                <input type="hidden" name={`bank_attorneys.${index}.id`} value={attorney.id} />
                              </Col>
                            </Row>
                            <Row key={`bank-consult.${index}`}>
                              <Col>
                                <ConsultPicker
                                  agencyId={company.agency_id}
                                  name={`bank_attorneys.${index}.consult`}
                                  label={t("title.consultant")}
                                  required
                                />
                                <FormGroup.LabeledInlineRadio
                                  name={`bank_attorneys.${index}.permission`}
                                  label={t("title.permission")}
                                  options={permissionsOptions}
                                  required
                                />
                              </Col>
                              <Col>
                                <OtherInputSelect
                                  label={t("title.bank")}
                                  name={`bank_attorneys.${index}.bank_name`}
                                  options={bankOptions}
                                  required
                                  otherName={`bank_attorneys.${index}.other_bank_name`}
                                  otherLabel={t("title.pleaseSpecifyBank")}
                                  otherPlaceholder={t("placeholder.bankName")}
                                  showInline={false}
                                />
                              </Col>
                            </Row>
                          </React.Fragment>
                        ))}
                        {values.bank_attorneys.length < 10 && (
                          <Row>
                            <Col>
                              <Button
                                style={{ color: "#6c757d" }}
                                color=""
                                variant="text"
                                onClick={() => arrayHelpers.push(emptyBankAttorney)}
                                className="add-btn"
                              >
                                <i style={{ color: "#348CD4" }} className="fas fa-plus mr-1" />
                                {t("actions.addConsultant")}
                              </Button>
                            </Col>
                          </Row>
                        )}
                      </>
                    )}
                  />
                  <Row>
                    <Col className="text-right">
                      {dirty && (
                        <Button variant="link" size="lg" onClick={() => resetForm()} className="cancel-btn">
                          {t("common:actions.cancel")}
                        </Button>
                      )}
                      <SubmitButton isSubmitting={isSubmitting} disabled={!dirty} />
                    </Col>
                  </Row>
                </Card.Body>
              )}
            </Card>
          </Form>
        );
      }}
    </Formik>
  );
}

export default OfficeSupportRolesBankAttorneyForm;
