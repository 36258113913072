import React from "react";
import { useNavigate } from "react-router-dom";
import { Card } from "react-bootstrap";
import * as companyAPI from "api2/companies";
import CompanyListLayout from "components/layouts/CompanyListLayout";
import TableProvider from "components/tables/btable/provider";
import { CompanyTable } from "components/tables";
import { useAuthState } from "hooks/useAuth";
import { useCompanyDispatch } from "hooks/useCompany";

function CompanyListPage() {
  const { user } = useAuthState();
  const navigate = useNavigate();
  const { selectCompany: selectCompanyAction } = useCompanyDispatch();
  const forStaff = user.is_superuser || user.is_consult;
  const getCompanies = forStaff ? companyAPI.staffCompanies : companyAPI.userCompanies;

  const initialFilters = {
    statuses: ["full"],
    favourites: forStaff,
  };

  const selectCompany = async (companyId, nextRoute = "/") => {
    await selectCompanyAction(companyId);
    navigate(nextRoute);
  };

  return (
    <CompanyListLayout>
      <Card>
        <Card.Body>
          <TableProvider tableId="companies" dataSource={getCompanies} initialFilters={initialFilters} keepTerm>
            <CompanyTable selectCompany={selectCompany} forStaff={forStaff} />
          </TableProvider>
        </Card.Body>
      </Card>
    </CompanyListLayout>
  );
}

export default CompanyListPage;
