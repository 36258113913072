import { useLocation } from "react-router-dom";
import { formatISO, isAfter, isDate } from "date-fns";
import _ from "lodash";
import { parseDate } from "./date";

export function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export function getBankIDMessage(data) {
  const unpaidSupplierInvoices = Object.values(data.unpaidSupplierInvoices || {});
  const unpaidOutlays = Object.values(data.unpaidOutlays || {});
  const unpaidDirectPayments = Object.values(data.unpaidDirectPayments || {});
  const unpaidSalaries = Object.values(data.unpaidSalaries || {});
  const unpaidTaxDP = Object.values(data.unpaidTaxDP || {});

  let msg = "Signera för betalning:\n";
  let total = 0;
  if (unpaidSupplierInvoices.length) {
    msg += "- Leverantörsfakturor:\n";
    unpaidSupplierInvoices.forEach((item) => {
      msg += `${item.supplier_name} (${item.invoice_no}) ${item.balance}SEK\n`;
      total += item.balance;
    });
  }
  if (unpaidOutlays.length) {
    msg += "- Utlägg:\n";
    unpaidOutlays.forEach((item) => {
      msg += `${item.created_by} ${item.balance}SEK\n`;
      total += item.balance;
    });
  }
  if (unpaidDirectPayments.length) {
    msg += "- Direkt betalning:\n";
    unpaidDirectPayments.forEach((item) => {
      msg += `${item.receiver} ${item.amount_sek}SEK\n`;
      total += item.amount_sek;
    });
  }
  if (unpaidSalaries.length) {
    msg += "- Löner:\n";
    unpaidSalaries.forEach((item) => {
      msg += `${item.receiver} ${item.amount_sek}SEK\n`;
      total += item.amount_sek;
    });
  }
  if (unpaidTaxDP.length) {
    msg += "- Skatteverket:\n";
    unpaidTaxDP.forEach((item) => {
      msg += `${item.payment_date} ${item.amount_sek}SEK\n`;
      total += item.amount_sek;
    });
  }
  msg += `\nTotal ${total}SEK`;
  return msg;
}

export function getDefaultRounding() {
  return window.localStorage.getItem("rounding") || "3";
}

export function scrollToSection(sectionID) {
  document.getElementById(sectionID).scrollIntoView();
}

const today = new Date();
today.setHours(0, 0, 0, 0);

export function filterActiveProjects(option) {
  if (!option.date_end) {
    return true;
  }
  return !!isAfter(parseDate(option.date_end), today);
}

export function filterActiveCC(option) {
  return option.is_active;
}

export function formatFilters(filters) {
  const newFilters = { ...filters };
  Object.keys(filters).forEach((key) => {
    if (isDate(filters[key])) {
      newFilters[key] = formatISO(filters[key], { representation: "date" });
    }
  });
  return newFilters;
}

export function enterKeyDownAsTab(event) {
  // prevent submitting form by Enter
  if (event.key === "Enter") {
    event.preventDefault();
    if (event.target.nodeName === "INPUT") {
      const { form } = event.target;
      const index = Array.prototype.indexOf.call(form, event.target);
      let elem;
      for (let i = index; i <= form.elements.length; i++) {
        elem = form.elements[i + 1];
        if (elem && elem.type === "text" && !elem.disabled) {
          break;
        }
        elem = null;
      }
      if (elem && event.target.className.includes("money-input")) {
        setTimeout(() => {
          elem.focus();
          elem.setSelectionRange(0, 1000);
        }, 100);
      } else if (elem) {
        elem.focus();
        elem.setSelectionRange(0, 1000);
      }
    }
  }
}

export function getSupplierName(supplier_obj, supplier_name) {
  if (supplier_obj && supplier_obj.supplier_name && supplier_obj.supplier_name !== supplier_name) {
    if (supplier_name) {
      supplier_name += ` (${supplier_obj.supplier_name})`;
    } else {
      supplier_name = supplier_obj.supplier_name;
    }
  }
  return supplier_name;
}

export function doNotSubmitFormOnEnter(event) {
  // prevent submitting form by Enter
  if (event.key === "Enter") {
    event.preventDefault();
  }
}

export function transformDotNotationObject(obj) {
  return _.transform(
    obj,
    (result, value, key) => {
      _.set(result, key, value);
    },
    {}
  );
}
