import React, { useState } from "react";
import { Button, Form, InputGroup } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { HintButton } from "components/ui/buttons";
import NumberFormat from "react-number-format";
import * as companyAPI from "api2/companies";

function BudgetMarginController({ companyId, budgetMargin, setBudgetMargin }) {
  const { t } = useTranslation("reports");
  const [value, setValue] = useState(budgetMargin);
  const apply = () => {
    companyAPI.budgets.updateMargin(companyId, value).catch(() => {});
    setBudgetMargin(value);
  };
  return (
    <Form.Group>
      <Form.Label>
        {t("budgetMargin")} <HintButton hint={t("budgetMarginHint")} />
      </Form.Label>
      <div className="d-flex">
        <InputGroup>
          <NumberFormat
            thousandSeparator={" "}
            decimalSeparator=","
            autoComplete="off"
            decimalScale={0}
            value={value}
            style={{ width: 80 }}
            isAllowed={({ floatValue }) => (floatValue ? floatValue >= 0 && floatValue <= 99 : true)}
            onValueChange={({ floatValue }) => {
              setValue(floatValue);
            }}
            className="money-input form-control"
            fixedDecimalScale
          />
          <InputGroup.Append>
            <InputGroup.Text>%</InputGroup.Text>
          </InputGroup.Append>
        </InputGroup>
        <Button type="button" variant="outline-primary" className="ml-1" onClick={apply}>
          {t("common:actions.apply")}
        </Button>
      </div>
    </Form.Group>
  );
}

export default BudgetMarginController;
