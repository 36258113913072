import React, { useState } from "react";
import { Button, ButtonGroup, Dropdown } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import { FilterButton } from "components/ui/buttons";
import useTable from "components/tables/btable/useTable";
import { CompanyEmployeeFilter, MonthDateFilter, SearchFilter } from "components/filters";
import qs from "qs";
import { codesForAbsences } from "components/perms/PermCodes";
import { PermCodeRequired } from "components/perms";
import useModal from "hooks/useModal";
import { FileUrlModal } from "components/modals";
import { formatDate } from "../../../utils/date";

function EmployeeAbsenceFilters({ companyId, forStatus }) {
  const { filters, setFilters } = useTable();
  const { t } = useTranslation("common");
  const filtersCount = !!filters.date + !!filters.user;
  const [more, setMore] = useState(!!filtersCount);
  const fileModal = useModal();

  const downloadFile = (paxml) => {
    let path = "file-paxml";
    if (!paxml) {
      path = "file-tlu";
    }
    const params = qs.stringify({
      mode: filters.mode,
      date: formatDate(filters.date),
      user: filters.user,
    });
    fileModal.open(`/absence-reports/${path}/?${params}`);
  };

  return (
    <>
      <section className="table-filters-left">
        <SearchFilter onFilter={setFilters} defaultValue={filters.term} width={300} />
        <FilterButton active={more} count={filtersCount} onClick={() => setMore(!more)} />
        {forStatus && (
          <Button variant={forStatus} onClick={() => {}}>
            {t(`common:statuses.${forStatus}`)}
          </Button>
        )}
        {!forStatus && (
          <ButtonGroup aria-label="Status filter" className="status-filter">
            <Button
              variant="outline-secondary"
              active={!filters.mode || filters.mode === "all"}
              onClick={() => setFilters({ mode: "" })}
            >
              {t("common:statuses.all")}
            </Button>
            <Button
              variant="outline-draft"
              active={filters.mode === "draft"}
              onClick={() => setFilters({ mode: "draft" })}
            >
              {t("common:statuses.draft")}
            </Button>
            <Button
              variant="outline-investigate"
              active={filters.mode === "disputed"}
              onClick={() => setFilters({ mode: "disputed" })}
            >
              {t("common:statuses.disputed")}
            </Button>
            <Button
              variant="outline-prelim"
              active={filters.mode === "prelim"}
              onClick={() => setFilters({ mode: "prelim" })}
            >
              {t("common:statuses.inApproval")}
            </Button>
            <Button
              variant="outline-paid"
              active={filters.mode === "approved"}
              onClick={() => setFilters({ mode: "approved" })}
            >
              {t("common:statuses.approved")}
            </Button>
          </ButtonGroup>
        )}
      </section>
      <PermCodeRequired code={codesForAbsences.download}>
        <section className="table-filters-right">
          <Dropdown>
            <Dropdown.Toggle variant="secondary" className="dropdown-toggle mr-0 waves-effect waves-light">
              <i className="fas fa-plus" /> {t("common:actions.download")}
            </Dropdown.Toggle>
            <Dropdown.Menu className="dropdown-menu-right">
              <Dropdown.Item onClick={downloadFile}>.PAXml (Fortnox, Crona)</Dropdown.Item>
              <Dropdown.Item onClick={() => downloadFile(false)}>.tlu (Visma)</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </section>
      </PermCodeRequired>
      {more && (
        <section className="more-filters">
          <MonthDateFilter
            label={t("common:dates.month")}
            defaultValue={filters.date}
            isClearable={false}
            onChange={(date) => setFilters({ date })}
          />
          <CompanyEmployeeFilter
            label={t("common:employee")}
            isClearable
            companyId={companyId}
            name="user"
            valueField="user_id"
            onFilter={setFilters}
            defaultValue={filters.user}
          />
        </section>
      )}
      {fileModal.show && (
        <FileUrlModal
          fileUrl={fileModal.data}
          companyId={companyId}
          downloadFileName="absence.paxml"
          handleClose={fileModal.close}
        />
      )}
    </>
  );
}

export default EmployeeAbsenceFilters;
