import React, { useContext } from "react";
import { Form, Formik, useFormikContext } from "formik";
import { Alert, Card, Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import * as yup from "yup";

import * as options from "api/options";
import { AllError, FormGroup } from "components/formik";
import StepHeader from "pages/companies/OnboardingWizard/steps/StepHeader";
import { OnboardingDispatchContext } from "pages/companies/OnboardingWizard/provider/OnboardingProvider";
import { SaveButton } from "pages/companies/OnboardingWizard/utils";
import "./GeneralInformationStep.scss";
import { withInitialAsync } from "hooks/useAsync";
import * as companyAPI from "api/company";
import _ from "lodash";
import ConditionalOverlayTrigger from "components/ui/OverlayTriggers/ConditionalOverlayTrigger";

function GeneralInformationStep({ data: item, companyId }) {
  const accounts = item.bank_accounts.map((account) => ({
    id: account.number,
    value: account.number,
    label: `${account.number} - ${account.name}`,
  }));

  const bgcAccounts = {
    asList: accounts,
    byId: _.keyBy(accounts, (account) => account.id),
  };
  const { fetchDetails } = useContext(OnboardingDispatchContext);
  const bgcAccount = item.booking_bank_account && bgcAccounts.byId[item.booking_bank_account];

  const formikProps = {
    initialValues: {
      ...item,
      payment_method: options.companyPaymentMethods.getOption(item.payment_method),
      payment_provider: options.paymentProviders.getOption(item.payment_provider),
      booking_bank_account: bgcAccount || accounts[0],
      bank_name:
        item.bank_name && item.bank_name === "other"
          ? item.other_bank_name || ""
          : options.bankChoices.getLabel(item.bank_name) || "",
      customer_invoice_enabled:
        item.customer_invoice_enabled !== undefined && item.customer_invoice_enabled !== null
          ? item.customer_invoice_enabled
          : true,
    },
    validationSchema: yup.object().shape({
      contact_first_name: yup.string().when("customer_invoice_enabled", {
        is: true,
        then: () => yup.string().required(),
        otherwise: () => yup.string().notRequired(),
      }),
      contact_last_name: yup.string().when("customer_invoice_enabled", {
        is: true,
        then: () => yup.string().required(),
        otherwise: () => yup.string().notRequired(),
      }),
      contact_email: yup
        .string()
        .email()
        .when("customer_invoice_enabled", {
          is: true,
          then: () => yup.string().required(),
          otherwise: () => yup.string().notRequired(),
        }),
      contact_phone: yup.string().when("customer_invoice_enabled", {
        is: true,
        then: () => yup.string().required(),
        otherwise: () => yup.string().notRequired(),
      }),
      address: yup.string().when("customer_invoice_enabled", {
        is: true,
        then: () => yup.string().required(),
        otherwise: () => yup.string().notRequired(),
      }),
      city: yup.string().when("customer_invoice_enabled", {
        is: true,
        then: () => yup.string().required(),
        otherwise: () => yup.string().notRequired(),
      }),
      zip_code: yup.string().when("customer_invoice_enabled", {
        is: true,
        then: () => yup.string().required(),
        otherwise: () => yup.string().notRequired(),
      }),
      phone: yup.string().when("customer_invoice_enabled", {
        is: true,
        then: () => yup.string().required(),
        otherwise: () => yup.string().notRequired(),
      }),
      bankgiro_no: yup.string().when("customer_invoice_enabled", {
        is: true,
        then: () =>
          yup.string().when(["payment_method", "payment_provider"], ([paymentMethod, paymentProvider], schema) => {
            const isBankgiro = paymentMethod && paymentMethod.value === "bankgiro";
            const isBgc = paymentProvider && paymentProvider.value === "bgc";
            if (isBankgiro || isBgc) {
              return schema.required();
            }
            return schema.notRequired();
          }),
        otherwise: () => yup.string().notRequired(),
      }),
      plusgiro_no: yup.string().when("customer_invoice_enabled", {
        is: true,
        then: () =>
          yup.string().when("payment_method", {
            is: (method) => method && method.value === "plusgiro",
            then: () => yup.string().required(),
            otherwise: () => yup.string().notRequired(),
          }),
        otherwise: () => yup.string().notRequired(),
      }),
      bic: yup.string().when("customer_invoice_enabled", {
        is: true,
        then: () =>
          yup.string().when("payment_method", {
            is: (method) => method && method.value === "bankaccount",
            then: () => yup.string().required(),
            otherwise: () => yup.string().notRequired(),
          }),
        otherwise: () => yup.string().notRequired(),
      }),
      iban: yup.string().when("customer_invoice_enabled", {
        is: true,
        then: () =>
          yup.string().when("payment_method", {
            is: (method) => method && method.value === "bankaccount",
            then: () => yup.string().required(),
            otherwise: () => yup.string().notRequired(),
          }),
        otherwise: () => yup.string().notRequired(),
      }),
      clearing_number: yup.string().when("customer_invoice_enabled", {
        is: true,
        then: () =>
          yup.string().when("payment_method", {
            is: (method) => method && method.value === "bankaccount",
            then: () => yup.string().required(),
            otherwise: () => yup.string().notRequired(),
          }),
        otherwise: () => yup.string().notRequired(),
      }),
      bank_account_number: yup.string().when("customer_invoice_enabled", {
        is: true,
        then: () =>
          yup.string().when("payment_method", {
            is: (method) => method && method.value === "bankaccount",
            then: () => yup.string().required(),
            otherwise: () => yup.string().notRequired(),
          }),
        otherwise: () => yup.string().notRequired(),
      }),
      bank_name: yup.string().when("customer_invoice_enabled", {
        is: true,
        then: () =>
          yup.string().when("payment_method", {
            is: (method) => method && method.value === "bankaccount",
            then: () => yup.string().required(),
            otherwise: () => yup.string().notRequired(),
          }),
        otherwise: () => yup.string().notRequired(),
      }),
    }),
    onSubmit: (values, { setErrors }) => {
      return companyAPI.onboarding.generalInfoStep
        .save(companyId, {
          ...values,
          booking_bank_account: values.booking_bank_account.value,
          payment_method: values.payment_method.value,
          payment_provider: values.payment_provider.value,
        })
        .then(() => {
          fetchDetails(true);
        })
        .catch((error) => {
          setErrors(error.data);
        });
    },
  };
  const { t } = useTranslation("company");
  return (
    <Formik {...formikProps}>
      {({ values, isSubmitting, errors, handleSubmit }) => {
        return (
          <Card className="general-information-step">
            <Card.Body>
              <StepHeader rightComponent={<SaveButton disabled={isSubmitting} onSave={handleSubmit} />} />
              <Col className="text-right">
                <FormGroup.Checkbox
                  label={t("onboarding.usesCustomerInvoices")}
                  name="customer_invoice_enabled"
                  disabled={values.billecta_id}
                />
              </Col>
            </Card.Body>
            <Card.Body>
              <GeneralInformationForm bgcAccounts={bgcAccounts} />
              <AllError errors={errors} />
            </Card.Body>
          </Card>
        );
      }}
    </Formik>
  );
}

function GeneralInformationForm({ bgcAccounts }) {
  const { t } = useTranslation("company");
  const { values, setFieldValue } = useFormikContext();
  const paymentProviders = options.paymentProviders.asList();
  const companyPaymentMethods = options.companyPaymentMethods.asList();

  const onBlurContactPhone = () => {
    if (!values.phone && values.contact_phone) {
      setFieldValue("phone", values.contact_phone);
    }
  };
  const isRequiredAndEnabled = values.customer_invoice_enabled;
  return (
    <Form noValidate>
      <h5>{t("bankDetails")}</h5>
      <Row>
        <Col lg={3}>
          <FormGroup.SimpleSelect
            name="payment_method"
            label={t("common:paymentMethod")}
            options={companyPaymentMethods}
            required={isRequiredAndEnabled}
            disabled={!isRequiredAndEnabled}
          />
        </Col>
        {(values.payment_method.value === "bankgiro" || values.payment_provider.value === "bgc") && (
          <Col sm={6} xl={3}>
            <FormGroup.Input
              label={t("common:bankGiroNo")}
              name="bankgiro_no"
              required={isRequiredAndEnabled}
              disabled={!isRequiredAndEnabled}
            />
          </Col>
        )}
        {values.payment_method.value === "plusgiro" && (
          <Col sm={6} xl={3}>
            <FormGroup.Input
              label={t("common:plusGiroNo")}
              name="plusgiro_no"
              required={isRequiredAndEnabled}
              disabled={!isRequiredAndEnabled}
            />
          </Col>
        )}
        <Col sm={6} xl={3}>
          <FormGroup.Input
            label="BIC"
            name="bic"
            required={values.payment_method.value === "bankaccount" && isRequiredAndEnabled}
            disabled={!isRequiredAndEnabled}
          />
        </Col>
        <Col sm={6} xl={3}>
          <FormGroup.Input
            label="IBAN"
            name="iban"
            required={values.payment_method.value === "bankaccount" && isRequiredAndEnabled}
            disabled={!isRequiredAndEnabled}
          />
        </Col>
        <Col sm={6} xl={3}>
          <ConditionalOverlayTrigger placement="bottom">
            <FormGroup.Input
              label={t("bankName")}
              name="bank_name"
              required={values.payment_method.value === "bankaccount" && isRequiredAndEnabled}
              disabled
            />
          </ConditionalOverlayTrigger>
        </Col>
        <Col sm={6} xl={3}>
          <FormGroup.Input
            type="number"
            name="clearing_number"
            label={t("common:clearingNo")}
            required={values.payment_method.value === "bankaccount" && isRequiredAndEnabled}
            disabled={!isRequiredAndEnabled}
          />
        </Col>
        <Col sm={6} xl={3}>
          <FormGroup.Input
            name="bank_account_number"
            label={t("common:bankAccountNo")}
            required={values.payment_method.value === "bankaccount" && isRequiredAndEnabled}
            disabled={!isRequiredAndEnabled}
          />
        </Col>
        <Col sm={6} xl={3}>
          <FormGroup.SimpleSelect
            name="booking_bank_account"
            label={`BGC ${t("common:account")}`}
            options={bgcAccounts.asList}
            required={isRequiredAndEnabled}
            disabled={!isRequiredAndEnabled}
          />
        </Col>
        <Col sm={6} xl={3}>
          <FormGroup.MoneyInput
            name="overdraft_facility"
            label={t("common:overdraftFacility")}
            disabled={!isRequiredAndEnabled}
          />
        </Col>
      </Row>
      <Row>
        <Col lg={3}>
          <FormGroup.SimpleSelect
            name="payment_provider"
            label={t("common:paymentProvider")}
            options={paymentProviders}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          {values.payment_provider.value === "bgc" && (
            <Alert variant="warning">{t("company:bgcPaymentProviderWarning")}</Alert>
          )}
        </Col>
      </Row>
      <h5>{t("onboarding.invoicingInformationContact")}</h5>
      <Row>
        <Col sm={6} xl={3}>
          <FormGroup.Input
            label={t("common:contact.firstName")}
            name="contact_first_name"
            required={isRequiredAndEnabled}
            disabled={!isRequiredAndEnabled}
          />
        </Col>
        <Col sm={6} xl={3}>
          <FormGroup.Input
            label={t("common:contact.lastName")}
            name="contact_last_name"
            required={isRequiredAndEnabled}
            disabled={!isRequiredAndEnabled}
          />
        </Col>
        <Col sm={6} xl={3}>
          <FormGroup.Input
            label={t("common:contact.emailLong")}
            name="contact_email"
            type="email"
            required={isRequiredAndEnabled}
            disabled={!isRequiredAndEnabled}
          />
        </Col>
        <Col sm={6} xl={3}>
          <FormGroup.Input
            label={t("common:contact.phone")}
            name="contact_phone"
            required={isRequiredAndEnabled}
            disabled={!isRequiredAndEnabled}
            onBlur={onBlurContactPhone}
          />
        </Col>
      </Row>
      <h5>{t("onboarding.invoicingInformationAddress")}</h5>
      <Row>
        <Col sm={6} xl={3}>
          <FormGroup.Input
            label={t("common:contact.address")}
            name="address"
            required={isRequiredAndEnabled}
            disabled={!isRequiredAndEnabled}
          />
        </Col>
        <Col sm={6} xl={3}>
          <FormGroup.Input
            label={t("common:contact.zipCode")}
            name="zip_code"
            required={isRequiredAndEnabled}
            disabled={!isRequiredAndEnabled}
          />
        </Col>
        <Col sm={6} xl={3}>
          <FormGroup.Input
            label={t("common:contact.city")}
            name="city"
            required={isRequiredAndEnabled}
            disabled={!isRequiredAndEnabled}
          />
        </Col>
        <Col sm={6} xl={3}>
          <FormGroup.Input
            label={t("common:contact.phone")}
            name="phone"
            required={isRequiredAndEnabled}
            disabled={!isRequiredAndEnabled}
          />
        </Col>
      </Row>
    </Form>
  );
}

const EnhancedGeneralInformationStep = withInitialAsync(
  GeneralInformationStep,
  ({ companyId }) => {
    return React.useCallback(
      (cancelToken) => companyAPI.onboarding.generalInfoStep.get(companyId, { cancelToken }),
      [companyId]
    );
  },
  {},
  true
);

export default EnhancedGeneralInformationStep;
