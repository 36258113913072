import React, { useMemo } from "react";
import { Alert, Col, Row } from "react-bootstrap";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { Form, Formik } from "formik";
import * as yup from "yup";

import { ConfirmButton, SubmitButton } from "components/ui/buttons";
import * as billectaAPI from "api/billecta";
import * as options from "api/options";
import { paymentTermsOptionsWithDefault } from "api/options";
import { findCountryByCode, getCountries } from "utils/countries";
import { AllError, FormGroup } from "components/formik";
import { codesForCustomerInvoices } from "components/perms/PermCodes";
import { PermCodeRequired } from "components/perms";
import { useCompanyState } from "hooks/useCompany";
import { useModalOnPage } from "components/modals/hooks/useModalOnPage";
import { handleActionErrors } from "api/errors";
import EInvoiceReceiverBox from "./EInvoiceReceiverBox";
import { confirmInfo } from "../../modals/ConfirmModal";

function CustomerForm({ companyId, customer, successCallback, resetOnSuccess = false, reload }) {
  const { t } = useTranslation("ci");
  const { company } = useCompanyState();
  const countries = useMemo(() => getCountries(t), [t]);
  const isModal = useModalOnPage();

  const customerTypes = options.customerTypes.asList();
  const languageChoices = options.languageChoices.asList();
  const deliveryOptions = options.customerDefaultDeliveries.asList();

  const priceGroups = options.priceGroups.asList();
  const initialInvoicePaymentTermsInDays =
    customer.DefaultActionConfig.InvoicePaymentTermsInDays !== 365
      ? customer.DefaultActionConfig.InvoicePaymentTermsInDays || 365
      : 365;
  const formikProps = {
    initialValues: {
      ...customer,
      DebtorType: options.customerTypes.getOption(customer.DebtorType) || customerTypes[0],
      CountryCode: findCountryByCode(customer.CountryCode),
      Notes: {
        PriceGroup: options.priceGroups.getOption(customer?.Notes?.PriceGroup || "A"),
        PropertyDesignation: customer?.Notes?.PropertyDesignation || "",
        ResidenceAssociationOrgNo: customer?.Notes?.ResidenceAssociationOrgNo || "",
        OtherInformation: customer?.Notes?.OtherInformation || "",
      },
      DefaultActionConfig: {
        ...customer.DefaultActionConfig,
        DeliveryMethod: options.customerDefaultDeliveries.getOption(customer.DefaultActionConfig.DeliveryMethod),
        CommunicationLanguage: options.languageChoices.getOption(
          customer.DefaultActionConfig.CommunicationLanguage || "SV"
        ),
        InvoicePaymentTermsInDays: options.paymentTerms.getOption(initialInvoicePaymentTermsInDays),
      },
    },
    enableReinitialize: true,
    validationSchema: yup
      .object()
      .shape({
        Name: yup.string().required(),
        OrgNo: yup.string(),
        Address: yup.string().required(),
        Address2: yup.string(),
        Email: yup
          .array()
          .of(yup.string().email())
          .when("DefaultActionConfig.DeliveryMethod", ([dm], schema) => {
            return dm.value?.toLowerCase() === "email" ? schema.min(1) : schema;
          }),
        City: yup.string().required(),
        CountryCode: yup.object().required(),
        DebtorNo: yup.string(),
        DebtorType: yup.object().required(),
        ZipCode: yup.string().required(),
        VatNumber: yup.string(),
        GLN: yup.string(),
        Notes: yup.object().shape({
          PriceGroup: yup.object().nullable().required(),
        }),
        DefaultActionConfig: yup.object().shape({
          DeliveryMethod: yup.object().nullable().required(),
          InvoicePaymentTermsInDays: yup.object().nullable().notRequired(),
        }),
      })
      .test("checkVAT", function (values) {
        const debtorType = values.DebtorType ? values.DebtorType.value : "";
        const countryEU = values.CountryCode.isEU;
        if (debtorType === "Company" && countryEU === true) {
          if (!values.VatNumber) {
            return this.createError({
              path: "VatNumber",
              message: t("common:errors.required"),
            });
          }
        }

        return true;
      }),
    onSubmit: async (values, { setErrors, resetForm }) => {
      await billectaAPI
        .customerSave(companyId, {
          ...customer,
          ...values,
          DebtorType: values.DebtorType.value,
          CountryCode: values.CountryCode.value,
          Email: values.Email.join(";"),
          Notes: {
            PriceGroup: values.Notes.PriceGroup.value,
            PropertyDesignation: values.Notes.PropertyDesignation,
            ResidenceAssociationOrgNo: values.Notes.ResidenceAssociationOrgNo,
            OtherInformation: values.Notes.OtherInformation,
          },
          DefaultActionConfig: {
            ...values.DefaultActionConfig,
            DeliveryMethod: values.DefaultActionConfig.DeliveryMethod.value,
            CommunicationLanguage: values.DefaultActionConfig.CommunicationLanguage.value,
            InvoicePaymentTermsInDays: values.DefaultActionConfig.InvoicePaymentTermsInDays.value,
          },
        })
        .then(async (response) => {
          toast.success(t("msg:saved"), { autoClose: 2000 });
          if (customer.DebtorPublicId) {
            await successCallback({
              ...customer,
              ...values,
              CountryCode: values.CountryCode.value,
            });
            if (resetOnSuccess) {
              resetForm();
            }
          } else {
            resetForm();
            if (successCallback) {
              successCallback(response.data);
            }
          }
          if (response.data.vies_checked) {
            if (response.data.vies_name) {
              await confirmInfo(t("ci:confirm.viesNameOk", { name: `<strong>${response.data.vies_name}</strong>` }));
            } else {
              await confirmInfo(t("ci:confirm.viesNameMissing"));
            }
          }
        })
        .catch((error) => {
          toast.error(t("msg:fixErrors"));
          setErrors(error.data);
        });
    },
  };

  async function deleteCustomer() {
    await billectaAPI
      .customerDestroy(companyId, customer.DebtorPublicId)
      .then(() => {
        toast.success(t("msg:deleted"), { autoClose: 2000 });
        document.body.dispatchEvent(new Event("customer/removed"));
        if (reload) {
          reload();
        }
      })
      .catch((error) => {
        handleActionErrors(error);
      });
  }

  async function activeToggle(newCustomer) {
    if (newCustomer.IsActive) {
      await billectaAPI
        .customerDeactive(companyId, newCustomer.DebtorPublicId)
        .then((response) => {
          toast.success(t("msg:deactivated"), { autoClose: 2000 });
          if (reload) {
            reload();
          }
        })
        .catch((error) => {
          handleActionErrors(error);
        });
    } else {
      await billectaAPI.customerActive(companyId, newCustomer.DebtorPublicId).then((response) => {
        toast.success(t("msg:activated"), { autoClose: 2000 });
        if (reload) {
          reload();
        }
      });
    }
  }

  return (
    <Formik {...formikProps}>
      {({ values, errors, setFieldValue, isSubmitting }) => {
        const debtorType = values.DebtorType ? values.DebtorType.value : "";
        const countryEU = values.CountryCode.isEU;
        const first2Chars = values.VatNumber.slice(0, 2);
        const VATcountryCode = /[A-Za-z]+/.test(first2Chars) ? first2Chars : null;
        const showVATEUWarn =
          debtorType === "Company" &&
          ((VATcountryCode && VATcountryCode !== values.CountryCode.value) ||
            (!!values.VatNumber && !VATcountryCode && countryEU && values.CountryCode !== "SE"));
        const showEUCompanyVATNumberWarning = debtorType === "Company" && values.CountryCode.isEU && !values.VatNumber;
        return (
          <Form noValidate>
            <Row>
              <Col sm={6} xl={2}>
                <FormGroup.SimpleSelect name="DebtorType" label={t("customerType")} options={customerTypes} />
              </Col>
              <Col sm={6} xl={2}>
                <FormGroup.Input
                  label={values.DebtorType.value === "Private" ? t("common:name") : t("common:companyName")}
                  name="Name"
                  required
                />
              </Col>
              <Col sm={6} xl={2}>
                <FormGroup.Input
                  label={values.DebtorType.value === "Private" ? t("common:contact.personalNumber") : t("common:orgNo")}
                  name="OrgNo"
                />
                <small className="d-block" style={{ marginTop: -14, marginBottom: 10 }}>
                  {values.DebtorType.value === "Private" ? "YYYYMMDD-XXXX" : "XXXXXX-XXXX"}
                </small>
              </Col>
              <Col sm={6} xl={3}>
                <FormGroup.Input label={t("customerNumber")} name="DebtorNo" helpText={t("helpCustomerNumber")} />
              </Col>
            </Row>
            <Row>
              <Col sm={4} xl={3}>
                <FormGroup.Input label={t("common:contact.address")} name="Address" required />
              </Col>
              <Col sm={4} xl={3}>
                <FormGroup.Input label={t("common:contact.address2")} name="Address2" />
              </Col>
              <Col sm={2} xl={3}>
                <FormGroup.Input label={t("common:contact.zipCode")} name="ZipCode" required />
              </Col>
            </Row>
            <Row>
              <Col sm={2} xl={2}>
                <FormGroup.Input label={t("common:contact.city")} name="City" required />
              </Col>
              <Col sm={2} xl={2}>
                <FormGroup.SimpleSelect
                  name="CountryCode"
                  label={t("common:contact.country")}
                  options={countries}
                  required
                />
              </Col>
              <Col sm={2} xl={2}>
                <FormGroup.Input label={t("common:contact.phone")} name="Phone" />
              </Col>
              <Col sm={4} xl={3}>
                <FormGroup.MultiEmail
                  name="Email"
                  label={t("common:contact.emailLong")}
                  helpText={t("helpEmail")}
                  showAddMe={false}
                  required={values.DefaultActionConfig.DeliveryMethod.value?.toLowerCase() === "email"}
                />
              </Col>
            </Row>
            <Row>
              <Col sm={4} xl={3}>
                <FormGroup.Input label={t("common:yourReference")} name="ContactName" />
              </Col>
              <Col sm={4} xl={3}>
                <FormGroup.Input label={t("common:ourReference")} name="DefaultActionConfig.OurReference" />
              </Col>
              <Col sm={4} xl={3}>
                <FormGroup.Input
                  label={t("common:money.vatNo")}
                  name="VatNumber"
                  required={values.DebtorType.value === "Company" && values.CountryCode.isEU === true}
                />
              </Col>
            </Row>
            <Row>
              <Col xl={4}>
                <FormGroup.SimpleSelect
                  name="DefaultActionConfig.InvoicePaymentTermsInDays"
                  label={t("common:paymentTerms")}
                  options={paymentTermsOptionsWithDefault()}
                />
              </Col>
              <Col xl={4}>
                <FormGroup.SimpleSelect name="Notes.PriceGroup" label={t("priceGroup")} options={priceGroups} />
              </Col>
            </Row>
            <Row>
              <Col xl={4}>
                <FormGroup.SimpleSelect
                  label={t("invoiceLang")}
                  name="DefaultActionConfig.CommunicationLanguage"
                  options={languageChoices}
                />
              </Col>
              <Col xl={4}>
                <FormGroup.SimpleSelect
                  name="DefaultActionConfig.DeliveryMethod"
                  label={t("sendInvoiceBy")}
                  options={deliveryOptions}
                  menuPlacement={isModal ? "bottom" : "auto"}
                  menuPosition={isModal ? "absolute" : "fixed"}
                  required
                />
              </Col>
            </Row>
            {values.DebtorType.value === "Private" && (
              <Row>
                <Col xl={4}>
                  <FormGroup.Input label={t("ci:propertyDesignation")} name="Notes.PropertyDesignation" />
                </Col>
                <Col xl={4}>
                  <FormGroup.Input label={t("ci:residenceNo")} name="Notes.ResidenceAssociationOrgNo" />
                </Col>
              </Row>
            )}
            <Row>
              <Col xl={4}>
                <FormGroup.Input as="textarea" label={t("ci:otherInformation")} name="Notes.OtherInformation" />
              </Col>
            </Row>
            {company.einvoice_enabled && customer.DebtorPublicId && (
              <EInvoiceReceiverBox
                customerId={customer.DebtorPublicId}
                companyId={companyId}
                initialOrgNo={values.OrgNo}
                onConnect={() => setFieldValue("DefaultActionConfig.DeliveryMethod", deliveryOptions[3])}
                onDisconnect={() => setFieldValue("DefaultActionConfig.DeliveryMethod", deliveryOptions[0])}
              />
            )}
            {showVATEUWarn && <Alert variant="warning">{t("ci:errors.vatNumberCountry")}</Alert>}
            {showEUCompanyVATNumberWarning && <Alert variant="warning">{t("warnDebtor")}</Alert>}
            <AllError errors={errors} />
            <hr />
            <PermCodeRequired code={codesForCustomerInvoices.manage}>
              <SubmitButton isSubmitting={isSubmitting} />
              {customer.DebtorPublicId && (
                <>
                  <ConfirmButton
                    variant="outline-danger"
                    confirmMessage={t("confirm.removeCustomer", {
                      customerName: customer.Name,
                    })}
                    label={t("common:actions.remove")}
                    className="float-right ml-2"
                    icon="fas fa-trash-alt"
                    onClick={() => deleteCustomer(customer)}
                  />
                  {customer.IsActive ? (
                    <ConfirmButton
                      variant="danger"
                      confirmMessage={t("confirm.deactivateCustomer", {
                        customerName: customer.Name,
                      })}
                      label={t("common:actions.deactivate")}
                      className="float-right"
                      icon="fas fa-trash-alt"
                      onClick={() => activeToggle(customer)}
                    />
                  ) : (
                    <ConfirmButton
                      variant="warning"
                      label={t("common:actions.activate")}
                      icon="fa-redo"
                      confirmMessage={t("confirm.activateCustomer", {
                        customerName: customer.Name,
                      })}
                      className="float-right"
                      onClick={() => activeToggle(customer)}
                    />
                  )}
                </>
              )}
            </PermCodeRequired>
          </Form>
        );
      }}
    </Formik>
  );
}

export default CustomerForm;
