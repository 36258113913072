import i18next from "i18next";

function t(text, group = "options") {
  return i18next.t(`${group}:${text}`);
}

function Options(dict_map) {
  return {
    asDict() {
      return dict_map;
    },
    asList() {
      return Object.keys(dict_map).map((key) => ({
        value: key,
        label: dict_map[key],
      }));
    },
    getLabel(value) {
      return dict_map[value] ? dict_map[value] : null;
    },
    getOption(value) {
      if (!dict_map[value]) {
        return null;
      }
      return { value, label: dict_map[value] };
    },
  };
}

function Options2(dictFn) {
  return {
    asDict() {
      return dictFn();
    },
    asList() {
      const dict_map = dictFn();
      return Object.keys(dict_map).map((key) => ({
        value: key,
        label: dict_map[key],
      }));
    },
    getLabel(value) {
      return dictFn()[value] || null;
    },
    getOption(value) {
      if (!dictFn()[value]) {
        return null;
      }
      return { value, label: dictFn()[value] };
    },
  };
}

// only one instance needed
const accountPlanOptions = Options2(() => ({
  optional: t("Optional"),
  mandatory: t("Mandatory"),
  not_allowed: t("Not allowed"),
}));
const companyPaymentMethods = Options2(() => ({
  bankgiro: "Bankgiro",
  plusgiro: "Plusgiro",
  bankaccount: t("Bank account"),
}));

const supplierPaymentMethods = Options2(() => ({
  BankGiro: "BankGiro",
  PlusGiro: "PlusGiro",
  BankAccount: t("Bank account"),
  ForeignAccount: t("Foreign Account"),
}));
const productTypes = Options2(() => ({
  Service: t("Service"),
  Commodity: t("Commodity"),
}));
const customerTypes = Options2(() => ({
  Private: t("Private"),
  Company: t("Company"),
}));
const vatOptions = Options2(() => ({
  0: t("No VAT"),
  6: t("6% VAT"),
  12: t("12% VAT"),
  25: t("25% VAT"),
}));
const companyTypes = Options2(() => ({
  limited: t("limited", "company"),
  sole_partnership: t("solePartnership", "company"),
  trading_partnership: t("tradingPartnership", "company"),
  limited_partnership: t("limitedPartnership", "company"),
  economic_association: t("economicAssociation", "company"),
  non_profit_association: t("nonProfitAssociation", "company"),
  joint_property_association: t("jointPropertyAssociation", "company"),
  housing_association: t("housingAssociation", "company"),
  foundation: t("foundation", "company"),
  private_person: t("privatePerson", "company"),
  other: t("otherCompanyType", "company"),
}));
const companyStatuses = Options2(() => ({
  full: t("statuses.full"),
  basic: t("statuses.basic"),
  inactive: t("statuses.inactive"),
}));
const createVerificationSeries = Options2(() => ({
  M: "M - Manual",
  I: "I - Yearly reconciliation",
}));

const paymentTerms = Options2(() => ({
  365: t("-Default setting-"),
  1: t("1 day"),
  10: t("10 days"),
  15: t("15 days"),
  20: t("20 days"),
  30: t("30 days"),
  35: t("35 days"),
  40: t("40 days"),
  45: t("45 days"),
  60: t("60 days"),
  90: t("90 days"),
}));

const paymentTermsOptionsWithoutDefault = () => {
  const array = paymentTerms.asList();
  array.pop();
  return array;
};

const paymentTermsOptionsWithDefault = () => {
  const array = paymentTerms.asList();
  array.unshift(array.pop());
  return array;
};

const discountTypes = Options2(() => ({
  Amount: "Amount",
  Percentage: "Percentage",
}));

export const archiveAuditorCategories = Options2(() => ({
  aga_statement: t("cdc.aga_statement"),
  commitment_notice: t("cdc.commitment_notice"),
  largest_invoices: t("cdc.largest_invoices"),
  ledgers_today: t("cdc.ledgers_today"),
  leasing_insurance: t("cdc.leasing_insurance"),
  authorities_grants: t("cdc.authorities_grants"),
  other: t("cdc.other"),
  requested_material: t("cdc.requested_material"),
}));

const salaryArchiveCategories = Options2(() => ({
  10: t("Communication"),
  20: t("Employee agreement"),
  30: t("Salary specifications"),
  35: t("Time report"),
  40: t("Other"),
}));

const salaryArchiveTypes = Options2(() => ({
  document: t("Document"),
  note: t("Note"),
}));

const consultArchiveCategories = Options2(() => ({
  kyc: t("KYC / ID-control"),
  service_agreement_incl_attachments: t("Service agreement incl attachments"),
  calculation_of_fixed_price: t("Calculation of fixed price"),
  termination_of_service: t("Termination of service"),
  meeting_notes: t("Meeting notes"),
  reassessment: t("Reassessment"),
  power_of_attorney: t("Power of Attorney"),
  assessment_of_assignment: t("Assessment of assignment - analysis and evaluation"),
  other: t("Other"),
}));

const verSeries = Options2(() => ({
  M: `M - ${t("type.manual", "ver")}`,
  C: `C - ${t("type.customerInvoice", "ver")}`,
  CP: `CP - ${t("type.customerInvoicePayment", "ver")}`,
  S: `S - ${t("type.supplierInvoice", "ver")}`,
  SP: `SP - ${t("type.supplierInvoicePayment", "ver")}`,
  R: `R -  ${t("type.outlays", "ver")}`,
  E: `E -  ${t("type.outlaysPrivateFunds", "ver")}`,
  N: `N - ${t("type.taxAuthority", "ver")}`,
  P: `P - ${t("type.payroll", "ver")}`,
  PP: `PP - ${t("type.payrollPayment", "ver")}`,
  EP: `EP -  ${t("type.paymentOrder", "ver")}`,
  AP: `AP - ${t("type.vatReport", "ver")}`,
  PER: `PER - ${t("type.periodisation", "ver")}`,
  X: `X - ${t("type.vatReport", "ver")}`,
  Z: `Z - ${t("type.import", "ver")}`,
  I: `I - ${t("type.importRevenue", "ver")}`,
  B: `B - ${t("type.importClosingEntries", "ver")}`,
  DM: `DM - ${t("type.outdated", "ver")}`,
  AB: `AB - ${t("type.automatiskBank", "ver")}`,
  AVS: `AVS - ${t("type.depreciation", "ver")}`,
}));

const sieFileVerSeries = Options2(() => ({
  Z: `Z - ${t("sie.importAllDesc")}`,
  I: `I: - ${t("sie.importRevenueDesc")}`,
  P: `P: - ${t("sie.importPayrollDesc")}`,
  B: `B: - ${t("sie.importClosingEntries")}`,
}));

const eventTypes = Options2(() => ({
  vat_monthly: t("Monthly VAT"),
  vat_quarterly: t("Quarterly VAT"),
  vat_yearly_eu: t("Yearly VAT - sales to EU"),
  vat_yearly_se: t("Yearly VAT - sales within SV only"),
  payroll_tax: t("Payroll Tax"),
}));

const statusListPeriodicityOptions = Options2(() => ({
  monthly: t("Monthly"),
  quarterly: t("Quarterly"),
  yearly_eu: t("Yearly - sales to EU"),
  yearly_se: t("Yearly - sales within SV only"),
}));
const statusListMainStatuses = Options2(() => ({
  not_started: t("mainStatuses.notStarted", "officeSupport"),
  ongoing: t("mainStatuses.ongoing", "officeSupport"),
  done: t("mainStatuses.done", "officeSupport"),
}));

const vatReportOptions = Options2(() => ({
  vat_yearly_eu: t("yearlyEU", "company"),
  vat_yearly_se: t("yearlySweden", "company"),
  vat_quarterly: t("quarterly", "company"),
  vat_monthly: t("monthly", "company"),
  none: t("noVat", "company"),
}));

const userRoles = Options2(() => ({
  regular: t("Regular user"),
  consult: t("Agency Consultant"),
}));

const contractCreateTerms = Options2(() => ({
  1: t("After the period"),
  2: t("Before the period"),
  3: t("In advance"),
}));

const auditorTitles = Options2(() => ({
  1: t("Chartered accountant"),
  2: t("Approved auditor"),
}));

const annualRoles = Options2(() => ({
  1: t("Board member"),
  2: t("CEO"),
  3: t("Chairman of the board"),
  4: t("Board member and CEO"),
  5: t("Chairman of the board and CEO"),
}));

const priceGroups = Options2(() => ({
  A: `A - ${t("Default", "common")}`,
  B: `B - ${t("money.unitPrice", "common")}`,
  C: `C - ${t("money.unitPrice", "common")}`,
  D: `D - ${t("money.unitPrice", "common")}`,
}));

const cashflowFreqs = Options2(() => ({
  0: "No repeat",
  1: "Every week",
  2: "Every month",
  3: "Every 3 months",
}));

const cashflowDelay = Options2(() => ({
  0: "No delay",
  1: "1 week",
  2: "2 weeks",
  3: "3 weeks",
  4: "4 weeks",
  5: "5 weeks",
  6: "6 weeks",
  7: "7 weeks",
  8: "8 weeks",
  9: "9 weeks",
  10: "10 weeks",
  11: "11 weeks",
  12: "12 weeks",
}));

const byWeekDays = Options2(() => ({
  0: t("MO"),
  1: t("TU"),
  2: t("WE"),
  3: t("TH"),
  4: t("FR"),
}));

const byMonths = Options2(() => ({
  1: "Jan",
  2: "Feb",
  3: "Mar",
  4: "Apr",
  5: "May",
  6: "Jun",
  7: "Jul",
  8: "Aug",
  9: "Sep",
  10: "Oct",
  11: "Nov",
  12: "Dec",
}));

const bdmPeriods = Options2(() => ({
  0: t("This month"),
  "-1": t("Last month"),
  "-3": t("last 3 months"),
  "-6": t("last 6 months"),
  "-12": t("last 12 months"),
}));

export const repActivities = Options2(() => ({
  1: t("rep.lunchDinner"),
  2: t("rep.lunchDinnerAlcohol"),
  3: t("rep.refreshment"),
  4: t("rep.alcohol"),
  5: t("rep.other"),
}));

export const repPurposes = Options2(() => ({
  1: t("Customer meeting"),
  2: t("Sales meeting"),
  3: t("Internal meeting"),
  4: t("Other purpose"),
}));

export const roundingChoices = Options2(() => ({
  1: "Ksek",
  2: "SEK",
  3: t("SEK with öre"),
}));

export const languageChoices = Options2(() => ({
  SV: t("swedish"),
  EN: t("english"),
}));

export const bankChoices = Options2(() => ({
  seb: "SEB",
  handelsbanken: "Handelsbanken",
  swedbank: "Swedbank",
  sparbanken_syd: "Sparbanken Syd",
  nordea_business: "Nordea business",
  nordea_netcorporate: "Nordea Netcorporate",
  danskebank: "Danskebank",
  lansforsakringar: "Länsförsäkringar",
  other: t("otherBank", "company"),
}));

export const vatPeriodNumber = Options2(() => ({
  none: t("noVat", "company"),
  vat_monthly: t("monthly", "common"),
  vat_quarterly: t("quarterly", "common"),
  vat_yearly: t("yearly"),
}));

export const activeChoices = Options2(() => ({
  0: t("inactive"),
  1: t("active"),
}));

export const taskCategories = Options2(() => ({
  standard: t("standard"),
  internal: t("internal"),
  absence: t("absence"),
  company: t("company"),
  custom: t("custom"),
  fixed: t("workTime"),
}));

export const absenceReasons = Options2(() => ({
  parental_leave: t("parental_leave"),
  compensation_timebank: t("compensation_timebank"),
  holiday: t("holiday"),
  sick_leave: t("sick_leave"),
  on_leave: t("on_leave"),
  child_care: t("child_care"),
  other: t("other"),
}));

export const euVATChoices = Options2(() => ({
  no: t("no"),
  yes_services: t("yes_quarterly"),
  yes_products: t("yes_monthly"),
}));

export const reconciliationPeriods = Options2(() => ({
  yearly: t("yearly"),
  quarterly: t("quarterly"),
  monthly: t("monthly"),
}));

export const bankRuleConditions = Options2(() => ({
  starts_with: t("starts_with"),
  ends_with: t("ends_with"),
  includes: t("includes"),
}));

export const bankRuleCharges = Options2(() => ({
  debit: t("debit"),
  credit: t("credit"),
}));

export const customerDefaultDeliveries = Options2(() => ({
  Email: t("actions.sendEmail", "ci"),
  Mail: t("actions.sendMail", "ci"),
  Manually: t("actions.sendDoNot", "ci"),
  EInvoice: t("actions.sendEInvoice", "ci"),
}));

const paymentProviders = Options2(() => ({
  bgc: "Bankgirocentralen",
  pis: "PIS",
}));

const invoiceReminderFees = Options2(() => ({
  0: t("0 Sek"),
  45: t("45 Sek"),
  60: t("60 Sek"),
}));

const manageAnnualReportOptions = Options2(() => ({
  not_started: t("md.notStarted"),
  ongoing: t("md.ongoing"),
  sent_to_client: t("md.sentClient"),
  audit_in_progress: t("md.auditProgress"),
  registered: t("md.registered"),
}));

const payrollSoftware = Options2(() => ({
  "-": "-",
  fortnox: "Fortnox",
  visma: "Visma",
  hogia: "Hogia",
  bjorn_lunden: "Bjorn Lunden",
  crona_lon: "Crona Lön",
  other: t("Other"),
}));

const accountingSoftware = Options2(() => ({
  "-": "-",
  bflow: "Bflow",
  fortnox: "Fortnox",
  visma: "Visma (E-ekonomi/Admin)",
  bjorn_lunden: "Bjorn Lunden",
  briljant: "Briljant",
  other: t("Other"),
}));
export const internalCustomerCategory = Options2(() => ({
  A: "A - Lojala, Lönsamma, Ordningsamma",
  B: "B - Potential till A-kund",
  C: "C - Ointresserade",
  D: "D - Svår att ha att göra med",
}));

export const startPages = {
  paths_by_code: {
    OS: "/office-support-companies",
    ACC: "/companies",
    TT: "/tt/calendar",
  },
  code_for: {
    officeSupport: "OS",
    accounting: "ACC",
    tt: "TT",
  },
};

const notesCategory = Options2(() => ({
  received_material: t("Received material"),
  returned_material: t("Returned material"),
  meeting_notes: t("Meeting notes"),
  advice: t("Advice"),
  digitalisation: t("Digitalisation"),
  other: t("Other2"),
  aml: "AML",
}));

export {
  Options,
  manageAnnualReportOptions,
  accountPlanOptions,
  companyPaymentMethods,
  supplierPaymentMethods,
  companyStatuses,
  companyTypes,
  createVerificationSeries,
  productTypes,
  vatOptions,
  customerTypes,
  paymentTerms,
  discountTypes,
  verSeries,
  eventTypes,
  userRoles,
  contractCreateTerms,
  auditorTitles,
  annualRoles,
  priceGroups,
  cashflowFreqs,
  cashflowDelay,
  byWeekDays,
  byMonths,
  bdmPeriods,
  salaryArchiveCategories,
  consultArchiveCategories,
  sieFileVerSeries,
  vatReportOptions,
  paymentProviders,
  paymentTermsOptionsWithoutDefault,
  paymentTermsOptionsWithDefault,
  invoiceReminderFees,
  salaryArchiveTypes,
  payrollSoftware,
  accountingSoftware,
  notesCategory,
  statusListPeriodicityOptions,
  statusListMainStatuses,
};
