import React, { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Form, Formik, useFormikContext } from "formik";
import { Button, Card, Col, Row } from "react-bootstrap";
import { AllError, FormGroup } from "components/formik";
import { convertToBooleanOrNull, getBooleanValueAsString } from "components/formik/utils/booleanHelpers";
import { SubmitButton } from "components/ui/buttons";
import * as options from "api/options";
import * as supportApi from "api2/office-support";
import { toast } from "react-toastify";
import * as yup from "yup";
import { sniCodes } from "../sniCodes";

function OfficeSupportClientDetailsForm({ companyId, changeEditedForm, data }) {
  const { t } = useTranslation("company");
  const [showMore, setShowMore] = useState(true);
  const sniCodesOptions = sniCodes.asList();
  const dataSniCodes = data?.sni_code?.map((code) => sniCodes.getOption(code)) || [];
  const companyTypeOptions = options.companyTypes.asList();
  const dataSniCodesValues = useMemo(
    () => data?.sni_code?.map((code) => sniCodes.getOption(code)) || [],
    [data?.sni_code]
  );
  const filteredSniCodesOptions = useMemo(
    () => sniCodesOptions.filter((option) => !dataSniCodesValues.includes(option.value)),
    [dataSniCodesValues, sniCodesOptions]
  );

  const formikProps = {
    initialValues: {
      client_no: data.client_no || "",
      name: data.name,
      org_no: data.org_no,
      company_type: options.companyTypes.getOption(data.company_type),
      based_in: data.based_in || "",
      sni_code: dataSniCodes,
      registered_aga: getBooleanValueAsString(data.registered_aga),
      approved_company_tax: getBooleanValueAsString(data.approved_company_tax),
      public_company: getBooleanValueAsString(data.public_company),
    },
    validationSchema: yup.object().shape({
      name: yup.string().required(),
      org_no: yup.string().required(),
      company_type: yup.object().nullable().required(),
    }),
    onSubmit: async (values, { setErrors, resetForm }) => {
      await supportApi.clientDetails
        .update(companyId, {
          client_no: values.client_no || null,
          name: values.name,
          org_no: values.org_no,
          company_type: !values.company_type ? null : values.company_type.value,
          based_in: values.based_in,
          sni_code: !values.sni_code ? null : values.sni_code?.map((item) => item.value),
          registered_aga: convertToBooleanOrNull(values.registered_aga),
          approved_company_tax: convertToBooleanOrNull(values.approved_company_tax),
          public_company: convertToBooleanOrNull(values.public_company),
        })
        .then((response) => {
          resetForm({ values });
          toast.success(t("msg:saved"), { autoClose: 2000 });
        })
        .catch((error) => {
          toast.error(t("msg:fixErrors"));
          if (error.data.__all__) {
            setErrors({ __all__: error.data.__all__ });
          } else {
            setErrors({ ...error.data });
          }
        });
    },
  };

  const filterOptionStartsWith = useCallback((option, inputValue) => {
    if (/^\d+$/.test(inputValue)) {
      return option.label.startsWith(inputValue);
    }
    return option.label.toLowerCase().includes(inputValue.toLowerCase());
  }, []);
  function FormUpdateChecker() {
    const { dirty } = useFormikContext();
    React.useEffect(() => {
      changeEditedForm({ id: "client-details", dirty });
    }, [dirty]);
    return null;
  }

  return (
    <Formik {...formikProps}>
      {({ values, touched, isSubmitting, setFieldValue, errors, resetForm, dirty }) => {
        return (
          <Form>
            <FormUpdateChecker />
            <Card>
              <Card.Header onClick={() => setShowMore((prevValue) => !prevValue)}>
                <Card.Title style={{ color: "#ADADAD", fontWeight: 400, fontSize: "12px" }}>
                  {t("clientDetails")}
                  <i
                    style={{ textAlign: "right", float: "right" }}
                    className={showMore ? "fas fa-chevron-up fa-1x" : "fas fa-chevron-down fa-1x"}
                  />
                </Card.Title>
              </Card.Header>
              {showMore && (
                <Card.Body className="pt-0">
                  <Row>
                    <Col>
                      <FormGroup.Input label={t("ci:customerNumber")} name="client_no" />
                    </Col>
                    <Col>
                      <FormGroup.Input label={t("common:companyName")} name="name" required />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <FormGroup.Input label={t("common:orgNo")} name="org_no" required disabled />
                    </Col>
                    <Col>
                      <FormGroup.SimpleSelect
                        label={t("company:companyType")}
                        name="company_type"
                        options={companyTypeOptions}
                        required
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col xl={6}>
                      <FormGroup.Input label={t("basedIn")} name="based_in" />
                    </Col>
                    {values.company_type.value === "limited" && (
                      <Col xl={6}>
                        <FormGroup.LabeledInlineYesNoRadio title={t("title.publicCompany")} name="public_company" />
                      </Col>
                    )}
                  </Row>
                  <Row>
                    <Col>
                      <FormGroup.SimpleSelect
                        label={t("sniCode")}
                        name="sni_code"
                        options={filteredSniCodesOptions}
                        filterOption={filterOptionStartsWith}
                        isMulti
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <FormGroup.LabeledInlineYesNoRadio label={t("generalTax")} name="registered_aga" />
                    </Col>
                    <Col>
                      <FormGroup.LabeledInlineYesNoRadio label={t("companyTax")} name="approved_company_tax" />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <AllError errors={errors} />
                    </Col>
                  </Row>
                  <Row>
                    <Col className="text-right">
                      {dirty && (
                        <Button variant="link" size="lg" onClick={() => resetForm()} className="cancel-btn">
                          {t("common:actions.cancel")}
                        </Button>
                      )}
                      <SubmitButton isSubmitting={isSubmitting} disabled={!dirty} />
                    </Col>
                  </Row>
                </Card.Body>
              )}
            </Card>
          </Form>
        );
      }}
    </Formik>
  );
}

export default OfficeSupportClientDetailsForm;
