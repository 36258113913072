import React, { useCallback, useContext } from "react";
import { Button, Table } from "react-bootstrap";
import { FieldArray, Form, Formik, useFormikContext } from "formik";
import { TableGroup } from "components/formik";
import { useTranslation } from "react-i18next";
import { formatDate, parseDate } from "utils/date";
import * as selectAPI from "api2/selects";
import * as companyAPI from "api2/companies";
import useAsync from "hooks/useAsync";
import { toast } from "react-toastify";
import { confirmRemove } from "components/modals/ConfirmModal";
import { CompanySettingStateContext } from "state/providers/CompanySettingProvider";
import { codeInList, codesFor } from "components/perms";
import { Loader } from "components/ui/loaders";

function SchedulesForm({ companyId, employeeId, unshift, remove, templates, canEdit = false }) {
  const { t } = useTranslation(["common", "company"]);
  const { values, errors, setFieldValue, setFieldError } = useFormikContext();
  const addEmpty = () => {
    unshift({ date_start: undefined, agency_time_template: null });
  };

  const onRemove = async (schedule, index) => {
    if (schedule.id) {
      const answer = await confirmRemove(t("company:confirm.removeEmployeeSchedule"));
      if (answer) {
        companyAPI.employees.schedules
          .delete(companyId, employeeId, schedule.id)
          .then(() => {
            remove(index);
            toast.success(t("msg:deleted"), { autoClose: 2000 });
          })
          .catch((error) => {
            if (error.data.__all__) {
              toast.error(error.data.__all__, { autoClose: 5000 });
            }
          });
      }
    } else {
      remove(index);
    }
  };

  const onSave = (schedule, index) => {
    if (!schedule.agency_time_template || !schedule.date_start) {
      setFieldError(`schedules[${index}].__all__`, t("company:errors.startAndTplRequired"));
    } else {
      companyAPI.employees.schedules
        .save(companyId, employeeId, {
          id: schedule.id,
          agency_time_template: schedule.agency_time_template.value,
          employee: employeeId,
          date_start: formatDate(schedule.date_start),
        })
        .then((response) => {
          setFieldValue(`schedules[${index}].id`, response.data.id);
          toast.success(t("msg:saved"), { autoClose: 2000 });
        })
        .catch((error) => {
          if (error.data.__all__) {
            toast.error(error.data.__all__);
          }
        });
    }
  };
  return (
    <div>
      {canEdit && (
        <Button variant="outline-secondary" className="mb-1" size="sm" onClick={addEmpty} disabled={!canEdit}>
          {t("common:actions.add")}
        </Button>
      )}
      <Table size="sm" id="scheduleTemplates">
        <thead>
          <tr>
            <th style={{ width: 200 }}>{t("common:dates.dateFrom")}</th>
            <th>{t("company:scheduleTemplate")}</th>
            <th />
          </tr>
        </thead>
        <tbody>
          {values.schedules.map((schedule, idx) => (
            <React.Fragment key={idx}>
              <tr>
                <TableGroup.DatePicker
                  name={`schedules[${idx}].date_start`}
                  popperClassName="popper-in-modal"
                  required
                />
                <TableGroup.SimpleSelect options={templates} name={`schedules[${idx}].agency_time_template`} />
                <td className="td-actions">
                  <Button
                    variant={schedule.id ? "success" : "primary"}
                    size="sm"
                    onClick={() => onSave(schedule, idx)}
                    disabled={!canEdit}
                  >
                    <i className="fa fa-save" />
                  </Button>
                  <Button variant="danger" size="sm" onClick={() => onRemove(schedule, idx)} disabled={!canEdit}>
                    <i className="fa fa-trash" />
                  </Button>
                </td>
              </tr>
              <TableGroup.RowErrors errors={errors.schedules && errors.schedules[idx]} />
            </React.Fragment>
          ))}
        </tbody>
      </Table>
    </div>
  );
}

function EmployeeSchedulesForm({ companyId, employeeId, schedules, templates }) {
  const formikProps = {
    initialValues: { schedules },
  };
  const { userPermCodes } = useContext(CompanySettingStateContext);
  const canEdit = codeInList(codesFor.employees.editSchedules, userPermCodes);
  return (
    <Formik {...formikProps}>
      <Form>
        <FieldArray
          name="schedules"
          render={(arrayHelpers) => (
            <SchedulesForm
              {...arrayHelpers}
              companyId={companyId}
              employeeId={employeeId}
              templates={templates}
              canEdit={canEdit}
            />
          )}
        />
      </Form>
    </Formik>
  );
}

function EmployeeScheduleWrapper({ companyId, employeeId }) {
  const getSchedules = useCallback(
    (cancelToken) => companyAPI.employees.schedules.list(companyId, employeeId, null, { cancelToken }),
    [companyId, employeeId]
  );
  const getTemplates = useCallback(
    (cancelToken) =>
      selectAPI.companyScheduleTemplates(companyId).then((response) => {
        return response;
      }),
    [companyId]
  );
  const [{ data: templates, loading: loadingTemplates }] = useAsync(getTemplates, []);
  const [{ data: schedules, loading: loadingSchedules }] = useAsync(getSchedules, []);
  if (loadingTemplates || loadingSchedules) {
    return <Loader />;
  }
  return (
    <EmployeeSchedulesForm
      schedules={schedules.map((schedule) => ({
        ...schedule,
        date_start: parseDate(schedule.date_start),
        agency_time_template: templates.find((tpl) => tpl.id === schedule.agency_time_template),
      }))}
      templates={templates}
      companyId={companyId}
      employeeId={employeeId}
    />
  );
}

export default EmployeeScheduleWrapper;
