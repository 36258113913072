import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import { Link } from "react-router-dom";
import cx from "classnames";
import React from "react";
import _ from "lodash";

//     height: 4px;
//     width: 4px;
//     background-color: #6BD481;
//     border-radius: 50%;
//     display: inline-block;
//     maring-bottom: 10px;
//     /* margin-top: 10px; */
//     /* padding: 0; */
//     top: 10px;
//     bottom: 10px;
//     /* margin-top: -10px; */
//     margin-bottom: 5px;

function ActionButton({ onClick, variant = "secondary", size = "sm", icon, ...props }) {
  return (
    <Button variant={variant} size={size} onClick={onClick} tabIndex="-1" {...props}>
      <i className={icon} />
    </Button>
  );
}

function TooltipActionButton({ onClick, variant, icon, text, placement = "top", extra, btnProps, ...props }) {
  const click = () => {
    onClick(extra);
  };

  return (
    <OverlayTrigger
      trigger={["hover", "click"]}
      placement={placement}
      overlay={
        <Tooltip id={String(_.uniqueId("tooltip_"))} {...props}>
          {text}
        </Tooltip>
      }
    >
      <ActionButton onClick={click} variant={variant} icon={icon} disabled={props.disabled} {...btnProps} />
    </OverlayTrigger>
  );
}

function TooltipActionLink({ onClick, link, icon, text, placement = "top", ...props }) {
  const { className } = props;
  return (
    <OverlayTrigger
      placement={placement}
      overlay={
        <Tooltip id={_.uniqueId("tooltip_link_")} {...props}>
          {text}
        </Tooltip>
      }
    >
      <Link to={link} className={cx("btn btn-secondary btn-sm", className)}>
        <i className={icon} />
      </Link>
    </OverlayTrigger>
  );
}

function ConditionalTooltip({ children, condition, text, placement = "bottom", className, ...props }) {
  if (condition && text && text?.length > 0) {
    return (
      <OverlayTrigger
        placement={placement}
        overlay={
          <Tooltip id={_.uniqueId("tooltip_link_")} className={className} {...props}>
            {text}
          </Tooltip>
        }
      >
        {children}
      </OverlayTrigger>
    );
  }
  return <>{children}</>;
}

export { ActionButton, TooltipActionButton, TooltipActionLink, ConditionalTooltip };
