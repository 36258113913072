export const handleParentCheckboxChange = (
  role,
  setPermsChecked,
  isSomethingChecked,
  codesMappedToRole,
  enabledCode
) => {
  setPermsChecked((p) => {
    if (isSomethingChecked) {
      return p.filter((backendCode) => backendCode !== codesMappedToRole.find((code) => code === backendCode));
    }

    if (enabledCode && !p.includes(enabledCode) && role !== "read") {
      return [...p, ...codesMappedToRole, enabledCode];
    }
    return [...p, ...codesMappedToRole];
  });
};

export const handleGlobalCheckboxChange = (setPermsChecked, isSomethingChecked, codesMappedToRole, enabledCodes) => {
  setPermsChecked((p) => {
    if (isSomethingChecked) {
      return p.filter((backendCode) => backendCode !== codesMappedToRole.find((code) => code === backendCode));
    }

    return [...p, ...codesMappedToRole, ...enabledCodes];
  });
};

// Find mapped codes in the array of codes from backend
const findCodes = (codesMappedToRole, permsChecked) => {
  return codesMappedToRole?.filter(
    (mappedCode) => mappedCode === permsChecked.find((backendCode) => backendCode === mappedCode)
  );
};

// Find codes for a single module
export const mapCodesToSingleModule = (childPerms, permsChecked, role) => {
  const codesMappedToRole = childPerms.filter((child) => child.roles.employee === role).map((child) => child.code);

  const foundCodes = findCodes(codesMappedToRole, permsChecked);

  return {
    codesMappedToRole,
    foundCodes,
  };
};

// Find codes within the whole array depending on a role
export const mapCodesToAllModules = (permissionModules, permsChecked, role) => {
  const { codesMappedToRole, enabledCodes } = permissionModules.reduce(
    (result, { enabled: { code }, children: childPerms }) => {
      const filteredCodes = childPerms.filter((child) => child.roles.employee === role).map((child) => child.code);
      result.codesMappedToRole.push(...filteredCodes);

      if (filteredCodes.length > 0 && !permsChecked.includes(code) && role !== "read") {
        result.enabledCodes.push(code);
      }

      return result;
    },
    { codesMappedToRole: [], enabledCodes: [] }
  );

  const foundCodes = findCodes(codesMappedToRole, permsChecked);

  return {
    codesMappedToRole,
    foundCodes,
    enabledCodes,
  };
};

export function isStatusListEnabled(userId) {
  return [
    1751, 1750, 1632, 1405, 1005, 948, 935, 877, 876, 869, 821, 820, 806, 788, 772, 753, 231, 139, 130, 22, 16,
  ].includes(userId);
}
