import React, { useCallback } from "react";
import * as companyAPI from "api/company";
import { toast } from "react-toastify";

import useAsync from "../../../hooks/useAsync";
import { useCompanyState } from "../../../hooks/useCompany";
import BaseVerificationsPreviewModal from "./BaseVerificationsPreviewModal";

function AssetSwitchAccountsVerificationsPreviewModal({ handleClose, data, forField }) {
  const {
    company: { id: companyId },
  } = useCompanyState();
  const dataSource = useCallback(
    (cancelToken) =>
      companyAPI.asset.previewAccountsSwitch(companyId, data.id, data, forField).catch((error) => {
        if (error.data.__all__) {
          toast.error(error.data.__all__);
        }
      }),
    [companyId, data, forField]
  );
  const [{ data: verifications, loading: loadingVerifications }] = useAsync(dataSource, []);
  return (
    <BaseVerificationsPreviewModal
      handleClose={handleClose}
      verifications={verifications}
      isLoading={loadingVerifications}
    />
  );
}

export default AssetSwitchAccountsVerificationsPreviewModal;
