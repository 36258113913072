import React, { useCallback, useMemo, useState } from "react";
import { isDate, isWithinInterval } from "date-fns";
import { useTranslation } from "react-i18next";
import _ from "lodash";

import { confirmExecute } from "components/modals/ConfirmModal";
import { resetSavedFilters } from "components/tables/btable/provider";
import { formatDate, parseDate } from "utils/date";
import { useCompanyState } from "hooks/useCompany";

const FinancialYearStateContext = React.createContext(undefined);
const FinancialYearDispatchContext = React.createContext(undefined);

function FinancialYearProvider({ children }) {
  const { t } = useTranslation(["common", "msg"]);
  const { financialYearSelected, financialYears } = useCompanyState();
  const [selected, setSelected] = useState(() =>
    financialYears.find((option) => option.value === financialYearSelected)
  );

  function update(newSelected) {
    setSelected(newSelected);
    resetSavedFilters();
  }

  const checkYears = useCallback(
    (dateObj) => {
      const usedYear = _.find(financialYears, (fy) =>
        isWithinInterval(dateObj, {
          start: fy.date_start,
          end: fy.date_end,
        })
      );
      if (usedYear && usedYear.id === selected.id) {
        return true;
      }
      return confirmExecute(
        t("msg:warnFinancialYear", {
          fYear: selected.label,
          date: formatDate(dateObj),
        })
      );
    },
    [financialYears, selected.id, selected.label, t]
  );

  const getForDate = useCallback(
    (selectedDate) => {
      let dateObj = selectedDate;
      if (!isDate(dateObj)) {
        dateObj = parseDate(selectedDate);
      }
      if (!dateObj) {
        return null;
      }
      dateObj.setHours(0, 0, 0, 0);
      return _.find(financialYears, (fy) =>
        isWithinInterval(dateObj, {
          start: fy.date_start,
          end: fy.date_end,
        })
      );
    },
    [financialYears]
  );

  const stateValue = useMemo(
    () => ({
      financialYear: selected,
      financialYears,
    }),
    [financialYears, selected]
  );
  const dispatchValue = useMemo(
    () => ({
      updateFinancialYear: update,
      checkYears,
      getForDate,
    }),
    [checkYears, getForDate]
  );

  return (
    <FinancialYearStateContext.Provider value={stateValue}>
      <FinancialYearDispatchContext.Provider value={dispatchValue}>{children}</FinancialYearDispatchContext.Provider>
    </FinancialYearStateContext.Provider>
  );
}

export { FinancialYearStateContext, FinancialYearDispatchContext };

export default FinancialYearProvider;
