import React from "react";
import { Card, Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import useTable from "components/tables/btable/useTable";
import EmployeeForm from "./EmployeeForm";

function EmployeeAddWidget({ companyId }) {
  const {
    dataActions: { reload },
  } = useTable();
  const employee = {
    user: null,
    is_active: true,
    employee_number: "",
    clearing_number: "",
    bank_account_number: "",
    account: null,
    monthly_salary: false,
    auto_gen_emails: false,
    is_contact_person: false,
    is_business_owner: false,
    contact_email: "",
    contact_phone: "",
    read_perms: false,
    approve_perms: false,
    pay_perms: false,
    invoice_perms: false,
    company_admin_perms: false,
    extra_emails_for_sending_in_receipts: [],
    approve_treshold: 0,
    salary_perms: false,
  };
  // 670919-9530
  const { t } = useTranslation("company");
  return (
    <Row>
      <Col>
        <Card className="card-border">
          <Card.Header className="border-success">
            <Card.Title>{t("addEmployeeSubtitle")}</Card.Title>
          </Card.Header>
          <Card.Body className="pt-0">
            <EmployeeForm companyId={companyId} employee={employee} successCallback={reload} />
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
}

export default EmployeeAddWidget;
