import React, { useEffect, useMemo, useState } from "react";
import { Button, ButtonGroup, Card, Table } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import cx from "classnames";
import { FieldArray, Form, Formik, useField } from "formik";
import * as yup from "yup";
import { FormGroup, TableGroup } from "components/formik";
import { useCompanyState } from "hooks/useCompany";
import useInitialAsync from "hooks/useInitialAsync";
import * as selectAPI from "api2/selects";
import * as supportAPI from "api2/office-support";
import { Loader } from "components/ui/loaders";
import { statusListPeriodicityOptions } from "api/options";
import { SubmitButton } from "components/ui/buttons";
import { formatDate, formatMonth, parseDate } from "utils/date";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { transformDotNotationObject } from "utils/others";
import useModal from "hooks/useModal";
import DescriptionModal from "./DescriptionModal";
import DeactivateModal from "./DeactivateModal";
import ActivateModal from "./ActivateModal";

import "./StatusListConfigPage.scss";

function WelcomePage({ companyId, reloadActivities }) {
  const { t } = useTranslation("officeSupport");

  const formikProps = {
    initialValues: {
      start_date: null,
    },
    validationSchema: yup.object().shape({
      start_date: yup.date().required(),
    }),
    onSubmit: async (values) => {
      return supportAPI.statusList
        .generate(companyId, formatDate(values.start_date))
        .then(() => {
          reloadActivities();
          toast.success(t("msg:generated"));
        })
        .catch(() => {});
    },
  };
  return (
    <Card className="status-list-welcome">
      <Card.Body>
        <h3>{t("config.welcomeTitle")}</h3>
        <h4>{t("config.welcomeSubTitle")}</h4>
        <Formik {...formikProps}>
          {({ values, isSubmitting }) => {
            return (
              <Form>
                <div>
                  <FormGroup.DateMonthPicker name="start_date" required label={t("common:dates.startDate")} />
                  <div>
                    <SubmitButton
                      disabled={isSubmitting}
                      title={t("common:actions.start")}
                      icon="fas fa-chevron-right"
                      iconAppend
                    />
                  </div>
                </div>
              </Form>
            );
          }}
        </Formik>
      </Card.Body>
    </Card>
  );
}

function StateSwitch({ activity, name, disabled, index, consults, isInitialized }) {
  const inputId = `id.${name}`;
  const deactivateModal = useModal();
  const activateModal = useModal();
  const [field, , helpers] = useField({ name, type: "checkbox" });
  const onSwitch = (event) => {
    const nextValue = event.target.checked;
    if (isInitialized) {
      if (nextValue === true) {
        activateModal.open();
      } else {
        deactivateModal.open();
      }
    } else {
      helpers.setValue(nextValue);
    }
  };
  return (
    <>
      <div className="custom-control custom-switch">
        <input
          type="checkbox"
          className="custom-control-input"
          id={inputId}
          name={name}
          {...field}
          onChange={onSwitch}
          disabled={disabled}
        />
        <label className="custom-control-label" htmlFor={inputId} />
      </div>
      {deactivateModal.show && (
        <DeactivateModal
          activity={activity}
          index={index}
          onChange={field.onChange}
          handleClose={deactivateModal.close}
        />
      )}
      {activateModal.show && (
        <ActivateModal
          activity={activity}
          index={index}
          onChange={field.onChange}
          consults={consults}
          handleClose={activateModal.close}
        />
      )}
    </>
  );
}

function MonthSwitch({ selected, setSelected }) {
  const { t } = useTranslation("officeSupport");
  return (
    <div className="month-switch form-group">
      <label className="form-label">Apply changes</label>
      <ButtonGroup>
        <Button variant="outline-secondary" active={selected === "today"} onClick={() => setSelected("today")}>
          <i className="fe-check" /> {t("nowForward")}
        </Button>
        <Button variant="outline-secondary" active={selected === "start"} onClick={() => setSelected("start")}>
          <i className="fe-check" /> {t("fromStartMonth")}
        </Button>
      </ButtonGroup>
    </div>
  );
}

function DescriptionButton({ index, companyId, activity }) {
  const { t } = useTranslation();
  const modal = useModal();

  return (
    <>
      {activity.note ? (
        <Button variant="link" onClick={modal.open}>
          <i className="fe-file-text" />
        </Button>
      ) : (
        <Button variant="link" onClick={modal.open}>
          <i className="fe-plus" />
          {t("common:actions.add")}
        </Button>
      )}
      {modal.show && (
        <DescriptionModal index={index} companyId={companyId} activity={activity} handleClose={modal.close} />
      )}
    </>
  );
}

function DynamicPeriodicitySelect({ allowed, name, periodicityOptions, ...props }) {
  const options = useMemo(() => {
    const allowedValues = allowed.map((item) => item.periodicity);
    return periodicityOptions.filter((item) => allowedValues.includes(item.value));
  }, [periodicityOptions, allowed]);

  return <TableGroup.SimpleSelect name={name} options={options} {...props} />;
}

function StatusListConfigPage({ companyId, consults, activities, reloadActivities, isInitialized }) {
  const { t, i18n } = useTranslation("officeSupport");
  const isEnglish = useMemo(() => i18n.language === "en", [i18n.language]);
  const navigate = useNavigate();
  const [changesType, setChangesType] = useState("today");
  const periodicityOptions = statusListPeriodicityOptions.asList();

  const resetConfiguration = () => {
    supportAPI.statusList.reset(companyId).then((response) => {
      reloadActivities();
    });
  };

  const formikProps = {
    enableReinitialize: true,
    initialValues: {
      activities: activities.map((activity) => ({
        ...activity,
        periodicity: statusListPeriodicityOptions.getOption(activity.periodicity || "monthly"),
        assignee: activity.assignee && { value: activity.assignee, label: activity.assignee_name },
        start_date: parseDate(activity.start_date),
      })),
    },
    validationSchema: yup.object().shape({
      activities: yup.array().of(
        yup.object().shape({
          assignee: yup
            .object()
            .nullable()
            .when("is_active", ([is_active], schema) => {
              return is_active ? schema.required() : schema;
            }),
          start_date: yup
            .date()
            .nullable()
            .when("is_active", ([is_active], schema) => {
              return is_active ? schema.required() : schema;
            }),
        })
      ),
    }),
    onSubmit: async (values, { setErrors, setFieldError, resetForm }) => {
      return supportAPI.statusList
        .updateMany(companyId, {
          change_since_type: changesType,
          activities: values.activities.map((item) => ({
            id: item.id,
            is_active: item.is_active,
            note: item.note,
            periodicity: item.periodicity.value,
            assignee: item.assignee.value,
            start_date: formatDate(item.start_date),
            deadline: formatDate(item.deadline),
          })),
        })
        .then(() => {
          if (!isInitialized) {
            navigate(0);
          }
          resetForm({ values });
          toast.success(t("msg:updated"));
        })
        .catch((error) => {
          setErrors(transformDotNotationObject(error.data));
        });
    },
  };

  if (activities.length === 0) {
    return <WelcomePage companyId={companyId} reloadActivities={reloadActivities} />;
  }
  // const sections = ["ongoing", "yearly"];
  const sections = ["ongoing"];
  return (
    <Card className="activity-config">
      <Formik {...formikProps} enableReinitialize={false}>
        {({ values, touched, isSubmitting, setFieldValue, errors, resetForm, dirty }) => {
          return (
            <Form>
              <Card.Body>
                {sections.map((section) => (
                  <section key={section}>
                    <div className="header">{t(`section.${section}`)}</div>
                    <div className="body">
                      {dirty && isInitialized && (
                        <p className="text-danger mb-1">Save changes first before activating new tasks.</p>
                      )}
                      {t("config.info1")}
                      <Table bordered responsive size="sm">
                        <thead>
                          <tr>
                            <th>{t("activity")}</th>
                            <th>{t("config.state")}</th>
                            <th width="200">{t("config.periodicity")}</th>
                            <th>{t("config.assignee")}</th>
                            <th width="200">{t("common:dates.startMonth")}</th>
                            <th width="200">{t("common:dates.deadline")}</th>
                            <th width="100">{t("common:description")} </th>
                          </tr>
                        </thead>
                        <tbody>
                          <FieldArray
                            name="activities"
                            render={(arrayHelper) =>
                              values.activities.map(
                                (activity, index) =>
                                  activity.section === section && (
                                    <tr key={activity.id} className={cx({ disabled: !activity.is_active })}>
                                      <td>{isEnglish ? activity.name_en : activity.name_sv}</td>
                                      <td>
                                        <StateSwitch
                                          index={index}
                                          activity={activity}
                                          name={`activities[${index}].is_active`}
                                          disabled={dirty && isInitialized}
                                          consults={consults}
                                          isInitialized={isInitialized}
                                        />
                                      </td>
                                      <DynamicPeriodicitySelect
                                        allowed={activity.allowed_periodicity}
                                        name={`activities[${index}].periodicity`}
                                        isDisabled={!activity.is_active}
                                        periodicityOptions={periodicityOptions}
                                      />
                                      <TableGroup.SimpleSelect
                                        name={`activities[${index}].assignee`}
                                        isDisabled={!activity.is_active}
                                        options={consults}
                                        required
                                      />
                                      <td>
                                        <span>{formatMonth(activity.start_date)}</span>
                                      </td>
                                      <TableGroup.DatePicker
                                        tdProps={{ className: "td-datePicker" }}
                                        name={`activities[${index}].deadline`}
                                        disabled={!activity.is_active}
                                      />
                                      <td>
                                        <DescriptionButton index={index} companyId={companyId} activity={activity} />
                                      </td>
                                    </tr>
                                  )
                              )
                            }
                          />
                        </tbody>
                      </Table>
                    </div>
                  </section>
                ))}
              </Card.Body>
              {(dirty || !isInitialized) && (
                <Card.Body className="form-submit">
                  {isInitialized && <MonthSwitch selected={changesType} setSelected={setChangesType} />}
                  <div>
                    {isInitialized ? (
                      <Button variant="link" onClick={resetForm}>
                        {t("common:actions.cancel")}
                      </Button>
                    ) : (
                      <Button variant="link" onClick={resetConfiguration}>
                        {t("common:actions.cancel")}
                      </Button>
                    )}
                    <SubmitButton title="actions.saveChanges" />
                  </div>
                </Card.Body>
              )}
            </Form>
          );
        }}
      </Formik>
    </Card>
  );
}

export function StatusListConfigPageWrapper(props) {
  const {
    company: { id, agency_id, status_list_initialized: isInitialized },
  } = useCompanyState();
  const { i18n } = useTranslation();
  const [reload, setReload] = useState(1);
  const { loading, item: consults } = useInitialAsync(
    ({ cancelToken }) =>
      selectAPI.agencyConsults(
        {
          agency: agency_id,
        },
        { cancelToken }
      ),
    []
  );

  useEffect(() => {
    // refresh selected options
    setReload((counter) => (counter > 0 ? counter + 1 : counter));
  }, [setReload, i18n.language]);

  const { loading: loadingList, item: activities } = useInitialAsync(
    ({ cancelToken }) => supportAPI.statusList.activities(id, { cancelToken }),
    [],
    [reload]
  );

  const reloadActivities = () => {
    setReload(reload + 1);
  };

  if (loading || loadingList) {
    return <Loader />;
  }
  return (
    <StatusListConfigPage
      companyId={id}
      consults={consults}
      activities={activities}
      reloadActivities={reloadActivities}
      isInitialized={isInitialized}
      {...props}
    />
  );
}

export default StatusListConfigPageWrapper;
