import React, { useCallback } from "react";
import { Link } from "react-router-dom";

import "./CompanyNotificationBar.scss";
import * as companyAPI from "api2/companies";
import useAsync from "hooks/useAsync";
import { Trans, useTranslation } from "react-i18next";

function CompanyNotificationBar({ companyId }) {
  const dataSource = useCallback(
    (cancelToken) => companyAPI.notifications.list(companyId, { cancelToken }),
    [companyId]
  );
  const [{ data: notifications, loading }] = useAsync(dataSource, []);

  const renderNotifications = () => {
    return notifications.map((notification) => {
      switch (notification.code) {
        case "bank-expiration":
          return (
            <BankConnection
              key={notification.code}
              companyId={companyId}
              i18nKey="msg:notification.bank-expiration"
              icon="fe-alert-triangle"
            />
          );
        case "bank-unmapped":
          return (
            <BankConnection
              key={notification.code}
              companyId={companyId}
              i18nKey="msg:notification.bank-unmapped"
              icon="fe-bell"
              classNames="company-notification-bar--yellow"
            />
          );
        default:
          return null;
      }
    });
  };

  if (loading || !notifications) {
    return null;
  }

  return renderNotifications();
}

function BankConnection({ companyId, i18nKey, icon, classNames }) {
  const { t } = useTranslation();

  return (
    <div className={`company-notification-bar ${classNames}`}>
      <i className={icon} />
      <Trans i18nKey={i18nKey}>
        {t(i18nKey)}
        <Link to={`/companies/${companyId}/settings/bank-connections`} />
      </Trans>
    </div>
  );
}

export default CompanyNotificationBar;
