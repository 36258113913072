import React from "react";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import cx from "classnames";
import { useAuthState } from "hooks/useAuth";
import { useCompanyState } from "hooks/useCompany";
import { getActiveClass, isActive, isTargetBlank } from "utils/layout";
import { codesFor, HasAnyPermCode, HasPermCode } from "components/perms";
import { ConditionalTooltip } from "components/ui/buttons/TooltipButton";

function LayoutSwitcherMenuItem() {
  const { t } = useTranslation("navigation");
  const { user } = useAuthState();
  const { company } = useCompanyState();
  const canSeeButtons = user.is_superuser || user.is_consult;
  if (!canSeeButtons) {
    return "";
  }

  const ttTarget = isTargetBlank("tt-layout");
  const accountingTarget = isTargetBlank("default-layout");
  const agencyTarget = isTargetBlank("office-support-layout");
  const excludedPerms = Object.values(codesFor.officeSupport).concat(Object.values(codesFor.agency));
  const accountingAccess = HasAnyPermCode(excludedPerms) || !company;
  const agencyAccess = HasPermCode(codesFor.officeSupport.enabled) || !company;

  const handleClick = function (e, hasAccess) {
    if (!hasAccess) e.preventDefault();
  };
  return (
    <li className="layout-switcher">
      <NavLink
        end
        to="/tt/calendar"
        className={cx("tt-btn", getActiveClass("tt-layout"))}
        target={ttTarget ? "_blank" : "_self"}
      >
        <span className="layout-switcher-btn">
          <i className="fe-clock" />
        </span>
        {ttTarget && (
          <span className="layout-open-btn">
            <i className="fe-external-link" />
          </span>
        )}
      </NavLink>
      <ConditionalTooltip
        condition={!accountingAccess}
        text={t(`msg:insufficient-permission`)}
        placement="bottom"
        className={cx("tooltip-permissions")}
      >
        <NavLink
          end
          to={company && !isActive("default-layout") ? "/" : "/companies"}
          className={cx("accounting-btn", getActiveClass("default-layout"))}
          target={accountingTarget ? "_blank" : "_self"}
          onClick={(event) => handleClick(event, accountingAccess)}
          aria-disabled={!accountingAccess}
        >
          <span className="layout-switcher-btn">
            <i className="fe-book-open" />
          </span>
          {accountingTarget && (
            <span className="layout-open-btn">
              <i className="fe-external-link" />
            </span>
          )}
        </NavLink>
      </ConditionalTooltip>
      <ConditionalTooltip
        condition={!agencyAccess}
        text={t(`msg:insufficient-permission`)}
        placement="bottom"
        className={cx("tooltip-permissions")}
      >
        <NavLink
          end
          to={
            company && !isActive("office-support-layout")
              ? "/office-support-companies/client-details"
              : "/office-support-companies"
          }
          className={cx("office-support-btn", getActiveClass("office-support-layout"))}
          target={agencyTarget ? "_blank" : "_self"}
          onClick={(event) => handleClick(event, agencyAccess)}
          aria-disabled={!agencyAccess}
        >
          <span className="layout-switcher-btn">
            <i className="os-icon" />
          </span>
          {agencyTarget && (
            <span className="layout-open-btn">
              <i className="fe-external-link" />
            </span>
          )}
        </NavLink>
      </ConditionalTooltip>
    </li>
  );
}

export default LayoutSwitcherMenuItem;
