import React, { useContext } from "react";
import { Form, Formik } from "formik";
import { Card, Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import * as yup from "yup";

import * as options from "api/options";
import { AllError, ConsultPicker, FormGroup } from "components/formik";
import StepHeader from "pages/companies/OnboardingWizard/steps/StepHeader";
import { OnboardingDispatchContext } from "pages/companies/OnboardingWizard/provider/OnboardingProvider";
import { SaveButton } from "pages/companies/OnboardingWizard/utils";
import * as companyAPI from "api/company";
import "./CompanyInformationStep.scss";
import { withInitialAsync } from "hooks/useAsync";
import { formatDate, parseDate } from "utils/date";
import { isLastDayOfMonth } from "date-fns";
import ConditionalOverlayTrigger from "components/ui/OverlayTriggers/ConditionalOverlayTrigger";
import { getBooleanValueAsString } from "components/formik/utils/booleanHelpers";

function CompanyInformationStep({ companyId, data: initialData }) {
  const { t } = useTranslation("company");
  const { fetchDetails } = useContext(OnboardingDispatchContext);
  const financialYearSet = !!initialData.financial_year_date_end;

  const formikProps = {
    initialValues: {
      name: initialData.name || "",
      org_no: initialData.org_no || "",
      company_type: options.companyTypes.getOption(initialData.company_type || "limited"),
      consult: initialData.consult && {
        value: initialData.consult,
        label: initialData.consult_name,
      },
      registered_aga: getBooleanValueAsString(initialData.registered_aga),
      auditor_enabled: getBooleanValueAsString(initialData.auditor_enabled),
      vat_report_period: options.vatReportOptions.getOption(initialData.vat_report_period),
      eu_vat: options.euVATChoices.getOption(initialData.eu_vat),
      approved_company_tax: getBooleanValueAsString(initialData.approved_company_tax),
      reconciliation_period: options.reconciliationPeriods.getOption(initialData.reconciliation_period),
      financial_year_date_start: parseDate(initialData.financial_year_date_start),
      financial_year_date_end: parseDate(initialData.financial_year_date_end),
    },
    validationSchema: yup.object().shape({
      name: yup.string().required(),
      org_no: yup.string().required(),
      financial_year_date_start: yup.date().nullable().required(),
      financial_year_date_end: yup
        .date()
        .nullable()
        .required()
        .test("lastDayMonth", function (dateEnd) {
          if (dateEnd && !isLastDayOfMonth(dateEnd)) {
            return this.createError({
              path: this.path,
              message: t("msg:lastDayOfMonth"),
            });
          }
          return true;
        })
        .when("financial_year_date_start", ([startDate], schema) => {
          return startDate ? schema.min(startDate, t("common:errors.mustLaterStartDate")) : schema;
        }),
      consult: yup.object().nullable().required(),
    }),
    onSubmit: (values, { setErrors }) => {
      return companyAPI.onboarding.companyInfoStep
        .save(companyId, {
          ...values,
          company_type: values.company_type.value,
          consult: values.consult.value,
          eu_vat: values.eu_vat.value,
          reconciliation_period: values.reconciliation_period.value,
          vat_report_period: values.vat_report_period.value,
          financial_year_date_start: formatDate(values.financial_year_date_start),
          financial_year_date_end: formatDate(values.financial_year_date_end),
        })
        .then((response) => {
          fetchDetails(true);
        })
        .catch((error) => {
          setErrors(error.data);
        });
    },
  };
  return (
    <Formik {...formikProps}>
      {({ isSubmitting, handleSubmit, errors }) => {
        return (
          <Card className="company-information-step">
            <Card.Body>
              <StepHeader
                rightComponent={
                  <SaveButton
                    disabled={isSubmitting}
                    onSave={handleSubmit}
                    withIcon={false}
                    buttonText={t("common:actions.saveAndContinue")}
                  />
                }
              />
            </Card.Body>
            <Card.Body className="btn-outline-secondary-blue">
              <CompanyInformationForm financialYearSet={financialYearSet} agencyId={initialData.agency_id} />
              <AllError errors={errors} />
            </Card.Body>
          </Card>
        );
      }}
    </Formik>
  );
}

function CompanyInformationForm({ financialYearSet, agencyId }) {
  const { t } = useTranslation("company");
  const euVATChoices = options.euVATChoices.asList();
  const reconciliationPeriods = options.reconciliationPeriods.asList();
  const companyTypeOptions = options.companyTypes.asList();
  const vatReportOptions = options.vatReportOptions.asList();
  return (
    <Form noValidate>
      <Row>
        <Col>
          <ConditionalOverlayTrigger placement="bottom">
            <FormGroup.Input label={t("common:companyName")} name="name" required disabled />
          </ConditionalOverlayTrigger>
        </Col>
        <Col>
          <ConditionalOverlayTrigger placement="bottom" useSpan>
            <FormGroup.LabeledInlineYesNoRadio label={t("registeredGeneralTax")} name="registered_aga" disabled />
          </ConditionalOverlayTrigger>
        </Col>
        <Col>
          <ConditionalOverlayTrigger placement="bottom" useSpan>
            <FormGroup.LabeledInlineYesNoRadio label={t("companyTax")} name="approved_company_tax" disabled />
          </ConditionalOverlayTrigger>
        </Col>
      </Row>
      <Row>
        <Col>
          <ConditionalOverlayTrigger placement="bottom">
            <FormGroup.Input label={t("common:orgNo")} name="org_no" required disabled />
          </ConditionalOverlayTrigger>
        </Col>
        <Col>
          <ConditionalOverlayTrigger placement="bottom" useSpan>
            <FormGroup.LabeledInlineYesNoRadio label={t("auditor")} name="auditor_enabled" disabled />
          </ConditionalOverlayTrigger>
        </Col>
        <Col>
          <Row>
            <Col>
              <FormGroup.DatePicker
                label={`${t("common:financialYear")} ${t("common:starts")}`}
                name="financial_year_date_start"
                disabled={financialYearSet}
                isClearable={!financialYearSet}
                required
              />
            </Col>
            <Col>
              <FormGroup.DatePicker
                label={t("common:ends")}
                name="financial_year_date_end"
                disabled={financialYearSet}
                isClearable={!financialYearSet}
                required
              />
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <Col>
          <ConditionalOverlayTrigger placement="bottom" useSpan>
            <FormGroup.SimpleSelect
              label={t("company:companyType")}
              name="company_type"
              options={companyTypeOptions}
              disabled
            />
          </ConditionalOverlayTrigger>
        </Col>
        <Col>
          <ConditionalOverlayTrigger placement="bottom" useSpan>
            <FormGroup.SimpleSelect
              label={t("company:vatReport")}
              name="vat_report_period"
              options={vatReportOptions}
              disabled
            />
          </ConditionalOverlayTrigger>
        </Col>
        <Col>
          <FormGroup.SimpleSelect
            label={t("company:reconciliationPeriod")}
            name="reconciliation_period"
            options={reconciliationPeriods}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <ConsultPicker agencyId={agencyId} label={t("common:consult")} name="consult" required />
        </Col>
        <Col>
          <FormGroup.SimpleSelect label={t("company:euVAT")} name="eu_vat" options={euVATChoices} />
        </Col>
        <Col />
      </Row>
    </Form>
  );
}

const EnhancedCompanyInformationStep = withInitialAsync(
  CompanyInformationStep,
  ({ companyId }) => {
    return React.useCallback(
      (cancelToken) => companyAPI.onboarding.companyInfoStep.get(companyId, { cancelToken }),
      [companyId]
    );
  },
  {},
  true
);

export default EnhancedCompanyInformationStep;
