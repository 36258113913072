import React, { useState } from "react";
import { Button, ButtonGroup, Dropdown } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Link, useResolvedPath } from "react-router-dom";

import { FilterButton } from "components/ui/buttons";
import useTable from "components/tables/btable/useTable";

import {
  CompanyEmployeeFilter,
  DateFilter,
  DateRangeFilters,
  MoneyRangeFilter,
  SearchFilter,
  SelectSimpleFilter,
} from "components/filters";
import { codesForOutlays } from "components/perms/PermCodes";
import { PermCodeRequired } from "components/perms";

function OutlayFilters({ companyId, forStatus, allowedModes }) {
  const { filters, setFilters } = useTable();
  const url = useResolvedPath("").pathname;
  const { t } = useTranslation("common");
  const filtersCount =
    !!filters.booking_date__start +
    !!filters.booking_date__end +
    !!filters.created_by +
    !!filters.paid_by +
    !!filters.amount_sek_min +
    !!filters.amount_sek_max +
    !!filters.receipt_date;
  const [more, setMore] = useState(!!filtersCount);
  const show = {
    createBtn: !forStatus || forStatus === "approvalAndFix",
  };
  const paidWithOptions = [
    { value: "P", label: t(`outlay:paidWithP`) },
    { value: "F", label: t(`outlay:paidWithF`) },
  ];

  return (
    <>
      <section className="table-filters-left">
        <SearchFilter onFilter={setFilters} defaultValue={filters.term} width={300} />
        <FilterButton active={more} count={filtersCount} onClick={() => setMore(!more)} />
        {forStatus && allowedModes && (
          <div className="status-filter">
            {allowedModes.map((mode) => (
              <Button
                key={mode.value}
                variant={`outline-${mode.variant}`}
                active={filters.mode === mode.value}
                onClick={() => setFilters({ mode: mode.value })}
              >
                {t(mode.label)}
              </Button>
            ))}
          </div>
        )}
        {forStatus && !allowedModes && (
          <Button variant={forStatus} onClick={() => {}}>
            {t(`common:statuses.${forStatus}`)}
          </Button>
        )}
        {!forStatus && (
          <ButtonGroup aria-label="Status filter" className="status-filter">
            <Button
              variant="outline-secondary"
              active={!filters.mode || filters.mode === "all"}
              onClick={() => setFilters({ mode: "" })}
            >
              {t("common:statuses.all")}
            </Button>
            <Button
              variant="outline-draft"
              active={filters.mode === "draft"}
              onClick={() => setFilters({ mode: "draft" })}
            >
              {t("common:statuses.draft")}
            </Button>
            <Button
              variant="outline-investigate"
              active={filters.mode === "investigate"}
              onClick={() => setFilters({ mode: "investigate" })}
            >
              {t("common:statuses.investigate")}
            </Button>
            <Button
              variant="outline-prelim"
              active={filters.mode === "prelim"}
              onClick={() => setFilters({ mode: "prelim" })}
            >
              {t("common:statuses.inApproval")}
            </Button>
            <Button
              variant="outline-unpaid"
              active={filters.mode === "unpaid"}
              onClick={() => setFilters({ mode: "unpaid" })}
            >
              {t("common:statuses.unpaid")}
            </Button>
            <Button
              variant="outline-sent-bank"
              active={filters.mode === "sent_bank"}
              onClick={() => setFilters({ mode: "sent_bank" })}
            >
              {t("common:statuses.sentBank")}
            </Button>
            <Button
              variant="outline-paid"
              active={filters.mode === "paid"}
              onClick={() => setFilters({ mode: "paid" })}
            >
              {t("common:statuses.paid")}
            </Button>
          </ButtonGroup>
        )}
      </section>
      <section className="table-filters-right">
        <PermCodeRequired code={codesForOutlays.manage}>
          {show.createBtn && (
            <Dropdown>
              <Dropdown.Toggle variant="first" className="dropdown-toggle mr-0 waves-effect waves-light">
                <i className="fas fa-plus" /> {t("common:actions.create")}
              </Dropdown.Toggle>
              <Dropdown.Menu className="dropdown-menu-right">
                <Dropdown.Item as={Link} to={`${url}/receipt/form`}>
                  <i className="fas fa-receipt mr-1" />
                  {t("common:receipt")}
                </Dropdown.Item>
                <Dropdown.Item as={Link} to={`${url}/mileage/form`}>
                  <i className="fas fa-car mr-1" />
                  {t("common:mileage")}
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          )}
        </PermCodeRequired>
      </section>
      {more && (
        <section className="more-filters">
          <DateFilter
            label={t("common:dates.receiptDate")}
            defaultValue={filters.receipt_date}
            onChange={(date) => setFilters({ receipt_date: date })}
          />
          <DateRangeFilters
            label={t("common:dates.bookingDate")}
            dateStart={filters.booking_date__start}
            dateEnd={filters.booking_date__end}
            onChange={({ start, end }) => setFilters({ booking_date__start: start, booking_date__end: end })}
          />
          <MoneyRangeFilter
            label={t("common:money.amountRange")}
            numberFrom={filters.amount_sek_min}
            numberTo={filters.amount_sek_max}
            onChange={(values) =>
              setFilters({
                amount_sek_min: values.numberFrom,
                amount_sek_max: values.numberTo,
              })
            }
          />
          <CompanyEmployeeFilter
            label={t("common:employee")}
            isClearable
            companyId={companyId}
            name="created_by"
            valueField="user_id"
            onFilter={setFilters}
            defaultValue={filters.created_by}
          />
          <SelectSimpleFilter
            label={t("outlay:paidWith")}
            isClearable
            name="paid_by"
            options={paidWithOptions}
            onFilter={setFilters}
            defaultValue={filters.paid_by}
          />
        </section>
      )}
    </>
  );
}

export default OutlayFilters;
