import { NavLink } from "react-router-dom";
import React from "react";
import { useTranslation } from "react-i18next";

function StatusListNavTabs() {
  const { t } = useTranslation("navigation");
  return (
    <ul className="nav nav-tabs nav-bordered nav-card pt-3" role="tablist">
      <li className="nav-item">
        <NavLink to="/office-support/status-list/ongoing" className="nav-link">
          {t("ongoingAccounting")}
        </NavLink>
      </li>
      <li className="nav-item">
        <NavLink to="/office-support/status-list/yearly" className="nav-link">
          {t("annual")}
        </NavLink>
      </li>
    </ul>
  );
}

export default StatusListNavTabs;
