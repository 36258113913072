import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, ButtonGroup } from "react-bootstrap";
import { Link, useResolvedPath } from "react-router-dom";
import useTable from "components/tables/btable/useTable";
import { CheckboxFilter, MonthDateFilter, SearchFilter, SelectSimpleFilter } from "components/filters";
import { FilterButton } from "components/ui/buttons";
import * as options from "api/options";
import { codesFor, HasPermCode } from "components/perms";
import { codesForConsultManage } from "components/perms/PermCodes";

function ProjectionBar({ setFilters }) {
  const { t } = useTranslation("common");
  const [projection, setProjection] = useState("");

  const updateProjection = (newValue) => {
    setProjection(newValue);
    setFilters({ projection: newValue });
  };
  return (
    <ul className="nav projection-bar" role="tablist">
      <li className="nav-item">
        <ButtonGroup>
          <Button variant="outline-secondary" active={!projection} onClick={() => updateProjection("")}>
            <i className="fe-check" /> {t("consult")}
          </Button>
          <Button variant="outline-secondary" active={projection === "cus"} onClick={() => updateProjection("cus")}>
            <i className="fe-check" /> {t("customer")}
          </Button>
        </ButtonGroup>
      </li>
    </ul>
  );
}

function SalaryArchiveFilters({ companyId }) {
  const archiveCategories = options.salaryArchiveCategories.asList();
  const { filters, setFilters } = useTable();
  const url = useResolvedPath("").pathname;
  const { t } = useTranslation("common");
  const filtersCount = !!filters.financial_year + !!filters.category + !!filters.month;
  const [more, setMore] = useState(!!filtersCount);
  const showProjection = HasPermCode(codesForConsultManage.enabled) && HasPermCode(codesFor.salaries.archive_all);

  return (
    <>
      <section className="table-filters-left">
        <SearchFilter onFilter={setFilters} defaultValue={filters.term} />
        <FilterButton active={more} count={filtersCount} onClick={() => setMore(!more)} />
      </section>
      <section className="table-filters-right">
        {showProjection && <ProjectionBar setFilters={setFilters} />}
        <Link to={`${url}/form`} className="btn btn-first">
          <i className="fas fa-plus" /> {t("common:actions.create")}
        </Link>
      </section>
      {more && (
        <section className="more-filters">
          <SelectSimpleFilter
            name="category"
            label={t("common:category")}
            options={archiveCategories}
            isClearable
            onFilter={setFilters}
            defaultValue={filters.category}
          />
          <MonthDateFilter
            name="month"
            label={t("common:dates.month")}
            defaultValue={filters.month}
            isClearable
            onChange={(d) => setFilters({ ...filters, month: d })}
          />
          <CheckboxFilter
            label={t("common:archived")}
            name="is_archived"
            wrapperClass="pt-3"
            defaultChecked={!!filters.is_archived}
            defaultValue={!!filters.is_archived}
            onFilter={(selected) => {
              setFilters({ ...filters, ...selected });
            }}
          />
        </section>
      )}
    </>
  );
}

export default SalaryArchiveFilters;
