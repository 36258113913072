import React, { useContext, useRef } from "react";
import { Button, ButtonGroup, Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import { toast } from "react-toastify";
import { Form, Formik } from "formik";

import { confirmBankID, confirmExecute } from "components/modals/ConfirmModal";
import { AllError, FormGroup, UserPicker } from "components/formik";
import * as selectAPI from "api2/selects";
import * as companyAPI from "api2/companies";
import { RemoveButton, SubmitButton } from "components/ui/buttons";
import { CodeRequiredInList, codesFor } from "components/perms";
import { codesForEmployees } from "components/perms/PermCodes";
import { CompanySettingStateContext } from "state/providers/CompanySettingProvider";
import { roundMoney } from "utils/money";
import { debounce } from "lodash";

const getCompanyAccounts = debounce((companyId, params, callback) => {
  selectAPI.companyAccounts(companyId, { ...params, for_employee: true }).then((data) => {
    return callback(data);
  });
}, 500);

function EmployeeForm({ companyId, employee, successCallback, deleteCallback, formName }) {
  const { t } = useTranslation("company");
  const {
    info: { agency_id: agencyId },
    userPermCodes,
  } = useContext(CompanySettingStateContext);

  const formRef = useRef();

  function isBankIdTokenNeeded(values) {
    // TODO how it should work after permission refactor
    return false;
  }

  const onSendWelcomeEmail = async () => {
    const answer = await confirmExecute(
      employee.welcome_email_sent_at ? t("action.sendWelcomeEmailAgain") : t("action.sendWelcomeEmail")
    );
    if (answer) {
      companyAPI.employees
        .sendWelcomeEmail(companyId, employee.id)
        .then((response) => {
          toast.success(t("msg:sent"));
          successCallback({ welcome_email_sent_at: new Date() });
        })
        .catch(() => toast.error(t("msg:canNotExecuteAction")));
    }
  };

  const formikProps = {
    initialValues: {
      ...employee,
      user: employee.user && {
        value: employee.user,
        label: employee.user_name,
      },
      account: employee.account && {
        value: employee.account,
        label: employee.account,
      },
      time_bank: employee.time_bank / 3600,
      contact_email: employee.contact_email || employee.user_email,
    },
    validationSchema: yup.object().shape({
      user: yup
        .object()
        .nullable()
        .shape({
          value: yup.number(),
          label: yup.string(),
        })
        .required(),
      employee_number: yup.string().max(6),
      clearing_number: yup.string().max(5),
      bank_account_number: yup.string().max(20),
      contact_email: yup
        .string()
        .email()
        .when("is_contact_person", ([is_contact_person], schema) => {
          return !is_contact_person ? schema : schema.required();
        }),
    }),
    onSubmit: async (values, { setErrors, resetForm }) => {
      const bankIdToken = isBankIdTokenNeeded(values) ? await confirmBankID("Employee settings set") : "";
      await companyAPI.employees
        .save(companyId, {
          ...values,
          id: employee.id,
          user: values.user && values.user.value,
          account: values.account && values.account.value,
          time_bank: values.time_bank ? roundMoney(values.time_bank * 3600, 0) : 0,
          contact_email: values.is_contact_person || values.is_business_owner ? values.contact_email : "",
          bank_id_token: bankIdToken,
        })
        .then((response) => {
          if (!employee.id) {
            resetForm();
          }
          toast.success(t("msg:saved"), { autoClose: 2000 });
          if (successCallback) {
            successCallback(response.data);
          }
        })
        .catch((error) => {
          toast.error(t("msg:fixErrors"));
          setErrors(error.data);
        });
    },
  };

  const onDelete = async () => {
    companyAPI.employees
      .delete(companyId, employee.id)
      .then(() => {
        toast.success(t("msg:deleted"));
        if (deleteCallback) {
          deleteCallback();
        }
      })
      .catch(() => toast.error(t("msg:canNotExecuteAction")));
  };

  const onUserSelected = (selected, setFieldValue) => {
    setFieldValue("contact_email", selected.email);
  };

  return (
    <Formik innerRef={formRef} {...formikProps}>
      {({ values, isSubmitting, setFieldValue, errors }) => {
        return (
          <Form>
            <Row>
              <Col sm={6} xl={3}>
                <UserPicker
                  name="user"
                  agencyId={agencyId}
                  isDisabled={!!employee.id}
                  setValue={setFieldValue}
                  onChange={(selected) => onUserSelected(selected, setFieldValue)}
                  showAddButton={!employee.id}
                  required
                />
              </Col>
            </Row>
            <Row>
              <Col sm={6} xl={3}>
                <FormGroup.Input label={t("employeeNo")} name="employee_number" />
              </Col>
              <Col sm={6} xl={3}>
                <FormGroup.Input label={t("common:clearingNo")} name="clearing_number" />
              </Col>
              <Col sm={6} xl={3}>
                <FormGroup.Input label={t("common:bankAccountNo")} name="bank_account_number" />
              </Col>
              <Col sm={6} xl={3}>
                <FormGroup.AsyncSelect
                  name="account"
                  label={t("common:accountF")}
                  loadOptions={(params, callback) => getCompanyAccounts(companyId, params, callback)}
                  minSearchLength={0}
                  isClearable
                />
              </Col>
            </Row>
            <Row className="mt-2 mb-2">
              <Col>
                <FormGroup.Checkbox formName={formName} label={t("receiverOfNotification")} name="is_contact_person" />
              </Col>
            </Row>
            {values.is_contact_person && (
              <Row className="ml-4">
                <Col xl={4}>
                  <FormGroup.Input label={t("common:contact.contactEmail")} name="contact_email" required />
                </Col>
              </Row>
            )}
            <Row>
              <Col xl={12}>
                <FormGroup.Checkbox formName={formName} label={t("common:statuses.active")} name="is_active" />
                <FormGroup.Checkbox formName={formName} label={t("monthlySalary")} name="monthly_salary" />
                {values.monthly_salary && (
                  <Row>
                    <Col xs={4} md={2} className="mt-2 ml-3">
                      <FormGroup.MoneyInput name="time_bank" label={`${t("timeBank")} (h)`} />
                    </Col>
                  </Row>
                )}
                <FormGroup.Checkbox formName={formName} label={t("autoGenEmail")} name="auto_gen_emails" />
                {(values.approve_perms || employee.permission_codes?.includes(codesFor.outlays.approve)) && (
                  <FormGroup.Checkbox formName={formName} label={t("hasOutlayAutoApprove")} name="has_auto_approve" />
                )}
              </Col>
            </Row>
            {/* todo show always or when emp has at least one approve code */}
            <Row className="mt-2">
              <Col xl={3}>
                <FormGroup.MoneyInput
                  label={t("treshold")}
                  helpText={t("tresholdHelp")}
                  decimalPlaces={0}
                  name="approve_treshold"
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <FormGroup.MultiEmail
                  name="extra_emails_for_sending_in_receipts"
                  label={t("extraReceiptSendingInEmails")}
                  helpText={t("helpExtraReceiptSendingInEmails")}
                  showAddMe={false}
                />
              </Col>
            </Row>
            {!employee.id && values.is_active && (
              <Row>
                <Col xl={12}>
                  <label className="label-control">{t("setPresetPermissions")}</label>
                  <FormGroup.Checkbox formName={formName} label={t("read")} name="read_perms" />
                  <FormGroup.Checkbox formName={formName} label={t("approve")} name="approve_perms" />
                  <FormGroup.Checkbox formName={formName} label={t("salary")} name="salary_perms" />
                  <FormGroup.Checkbox formName={formName} label={t("pay")} name="pay_perms" />
                  <FormGroup.Checkbox formName={formName} label={t("invoice")} name="invoice_perms" />
                  <FormGroup.Checkbox formName={formName} label={t("companyManager")} name="company_manager_perms" />
                  <FormGroup.Checkbox formName={formName} label={t("companyAdmin")} name="company_admin_perms" />
                </Col>
              </Row>
            )}
            <AllError errors={errors} />
            <hr />
            <SubmitButton isSubmitting={isSubmitting} />
            {employee.id && (
              <ButtonGroup className="float-right">
                <Button
                  className="float-right"
                  variant={employee.welcome_email_sent_at ? "warning" : "info"}
                  onClick={onSendWelcomeEmail}
                >
                  {employee.welcome_email_sent_at ? t("action.sendWelcomeEmailAgain") : t("action.sendWelcomeEmail")}
                </Button>
                <CodeRequiredInList code={codesForEmployees.delete} codeList={userPermCodes}>
                  <RemoveButton
                    variant="danger"
                    disabled={isSubmitting}
                    confirmMessage={t("confirm.removeEmployee", {
                      name: employee.user_name,
                    })}
                    onClick={onDelete}
                  />
                </CodeRequiredInList>
              </ButtonGroup>
            )}
          </Form>
        );
      }}
    </Formik>
  );
}

export default EmployeeForm;
