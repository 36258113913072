import React, { useContext, useState, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { Card, Col, Row } from "react-bootstrap";
import { CheckboxFilter } from "components/filters";
import StepHeader from "pages/companies/OnboardingWizard/steps/StepHeader";
import "./FinishedStep.scss";
import { withInitialAsync } from "hooks/useAsync";
import * as companyAPI from "api/company";
import { toast } from "react-toastify";
import { OnboardingDispatchContext } from "pages/companies/OnboardingWizard/provider/OnboardingProvider";
import { SaveButton } from "pages/companies/OnboardingWizard/utils";

function FinishedStep({ data: initialData, companyId }) {
  const [data, setData] = useState(initialData);
  const { t } = useTranslation("company");
  const { fetchDetails } = useContext(OnboardingDispatchContext);

  const updateFinishingStep = useCallback(
    (name) => {
      companyAPI.onboarding
        .changeFinishingStep(companyId, name, !data.finishing_steps[name])
        .catch(() => {})
        .then(() => {
          setData((_data) => {
            _data.finishing_steps[name] = !data.finishing_steps[name];
            return _data;
          });
        });
    },
    [companyId, data.finishing_steps]
  );

  const updateCompanyStatus = useCallback(() => {
    companyAPI.onboarding.finishStep
      .save(companyId, { status: "full" })
      .then((response) => {
        toast.success(t("msg:updated"));
        fetchDetails(false, false, true);
      })
      .catch((error) => {
        if (error.data.__all__) {
          toast.error(error.data.__all__);
        }
      });
  }, [companyId, fetchDetails, t]);

  return (
    <Card className="finished-step">
      <Card.Body className="pb-5">
        <StepHeader
          rightComponent={
            <SaveButton onSave={updateCompanyStatus} buttonText={t("company:finish&activate")} withIcon={false} />
          }
        />
      </Card.Body>
      <section className="finishing-steps">
        <h3 className="mb-3">{t("company:finishingSteps")}:</h3>
        <Row lg={4}>
          <Col>
            <CheckboxFilter
              name="cancel_licenses"
              label={t("company:cancelLicences")}
              defaultChecked={data.finishing_steps.cancel_licenses}
              onFilter={() => updateFinishingStep("cancel_licenses")}
            />
          </Col>
        </Row>
        <Row lg={4}>
          <Col>
            <CheckboxFilter
              name="intro_meeting_booked"
              label={t("company:introMeetingBooked")}
              defaultChecked={data.finishing_steps.intro_meeting_booked}
              onFilter={() => updateFinishingStep("intro_meeting_booked")}
            />
          </Col>
        </Row>
        <Row lg={4}>
          <Col>
            <CheckboxFilter
              name="intro_meeting"
              label={t("company:introMeeting")}
              defaultChecked={data.finishing_steps.intro_meeting}
              onFilter={() => updateFinishingStep("intro_meeting")}
            />
          </Col>
        </Row>
        <Row lg={4}>
          <Col>
            <CheckboxFilter
              name="followup_meeting"
              label={t("company:followupMeeting")}
              defaultChecked={data.finishing_steps.followup_meeting}
              onFilter={() => updateFinishingStep("followup_meeting")}
            />
          </Col>
        </Row>
      </section>
    </Card>
  );
}

const EnhancedFinishedStep = withInitialAsync(
  FinishedStep,
  ({ companyId }) => {
    return React.useCallback(
      (cancelToken) => companyAPI.onboarding.finishStep.get(companyId, { cancelToken }),
      [companyId]
    );
  },
  {},
  true
);

export default EnhancedFinishedStep;
