import React, { useCallback, useContext, useEffect, useState } from "react";
import { Button, Modal, Table } from "react-bootstrap";
import cx from "classnames";
import { useTranslation } from "react-i18next";
import { formatMoney } from "utils/money";
import { truncateText } from "utils/text";
import { BasketContext } from "../BasketProvider";

function SummaryStage() {
  const { t } = useTranslation("common");
  const [items, setItems] = useState([]);
  const {
    loading,
    error,
    actions: { checkBasket, reset, setLoading },
  } = useContext(BasketContext);
  const totalAmount = items.reduce((total, item) => total + item.payment_amount, 0);
  const check = useCallback(() => {
    checkBasket()
      .then((response) => {
        setItems(response.data);
      })
      .catch(() => {});
  }, [checkBasket]);

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      check();
      // we are waiting 1s as some banks (mostly SEB can have small delay for getting statuses after signing
    }, 2000);
  }, [check, setLoading]);
  const allOk = items.filter((item) => ["fail", "pending"].includes(item.state)).length === 0;

  return (
    <>
      <div className="title">
        <h2>{t("basket.paymentsSummary")}</h2>
        {loading && (
          <h4>
            <i className="fa fa-spinner fa-spin" /> {t("basket.checkingPayments")}
          </h4>
        )}
        {!loading && error && <h4 className="text-error">{error || t("basket.error")}</h4>}
        {!loading && items.length > 0 && allOk && <h4 className="text-success">{t("basket.paymentsSentOk")}</h4>}
      </div>
      <Modal.Body>
        <Table>
          <thead>
            <tr>
              <th style={{ width: 320 }}>{t("receiver")}</th>
              <th className="text-right">{t("basket.refOrOcr")}</th>
              <th className="text-right" style={{ minWidth: 120 }}>
                {t("dates.paymentDate")}
              </th>
              <th className="text-right">{t("money.amount")}</th>
              <th className="text-right">{t("statuses.status")}</th>
            </tr>
          </thead>
          <tbody>
            {!loading && !items.length && (
              <tr>
                <td colSpan={5}>{t("noResultsFound")}</td>
              </tr>
            )}
            {items.map((item) => (
              <tr
                key={item.id}
                className={cx(`state state--${item.state}`, { "has-error": item.state === "fail" || error })}
              >
                <td>
                  <span>{item.title}</span>
                  {item.error && <p className="text-error">{item.error}</p>}
                  {item.state === "partially_signed" && <p className="text-warn">{t("basket.partiallySignedInfo")}</p>}
                  {item.state === "pending" && <p className="text-warn">{t("basket.pendingSignedInfo")}</p>}
                </td>
                <td className="text-right" title={item.reference}>
                  {truncateText(item.reference, 30)}
                </td>
                <td className="text-right">{item.payment_date}</td>
                <td className="text-right">{formatMoney(item.payment_amount, 2, 2)} SEK</td>
                <td className="text-right">
                  <i className="fe-check-circle" />
                  <i className="fe-x" />
                </td>
              </tr>
            ))}
          </tbody>
          {items.length > 0 && (
            <tfoot>
              <tr>
                <th colSpan={2} />
                <th colSpan={2} className="text-right total">
                  = {formatMoney(totalAmount, 2, 2)} SEK
                </th>
                <th />
              </tr>
            </tfoot>
          )}
        </Table>
      </Modal.Body>
      <Modal.Footer>
        {error && (
          <Button variant="first" onClick={reset} disabled={loading}>
            {t("actions.tryAgain")}
          </Button>
        )}
      </Modal.Footer>
    </>
  );
}

export default SummaryStage;
