import client from "./client";
import { formatDate } from "../utils/date";

export const psd2 = {
  connectionStatus: (companyId, config) =>
    client.get(`/psd2/ais/connection-status/`, {
      headers: {
        "x-company": companyId,
      },
      ...config,
    }),
  consents: {
    list: (companyId, filters, config) => {
      return client.get(`/psd2/ais/consents/`, {
        params: filters,
        headers: {
          "x-company": companyId,
        },
        ...config,
      });
    },
    create: (companyId, bic, forRenew, config) => {
      return client.post(
        `/psd2/ais/consents/create/`,
        { for_renew: forRenew, bic_fi: bic },
        {
          headers: {
            "x-company": companyId,
          },
          ...config,
        }
      );
    },
    details: (companyId, consentId, config) => {
      return client.get(`/psd2/ais/consents/${consentId}/detail/`, {
        headers: {
          "x-company": companyId,
        },
        ...config,
      });
    },
    delete: (companyId, consentId, config) => {
      return client.delete(`/psd2/ais/consents/${consentId}/delete/`, {
        headers: {
          "x-company": companyId,
        },
        ...config,
      });
    },
    scaStatus: (companyId, consentId, config) => {
      return client.get(`/psd2/ais/consents/${consentId}/sca/detail/`, {
        headers: {
          "x-company": companyId,
        },
        ...config,
      });
    },
  },
  bankAccounts: {
    list: (companyId, consentId, config) => {
      return client.get(`/psd2/ais/consents/${consentId}/accounts/`, {
        headers: {
          "x-company": companyId,
        },
        ...config,
      });
    },
    bulkUpdate: (companyId, consentId, data, config) => {
      return client.put(`/psd2/ais/consents/${consentId}/accounts/bulk-update/`, data, {
        headers: {
          "x-company": companyId,
        },
        ...config,
      });
    },
    paymentAccountToggle: (companyId, consentId, accountId, config) => {
      return client.patch(`/psd2/ais/consents/${consentId}/accounts/${accountId}/payment-account-toggle/`, null, {
        headers: {
          "x-company": companyId,
        },
        ...config,
      });
    },
  },
  bankStatementRefetch: (companyId, consentId, config) => {
    return client.post(`/psd2/ais/bank-statement-refetch/`, null, {
      headers: {
        "x-company": companyId,
      },
      ...config,
    });
  },
  kyc: {
    initiate: (companyId, config) => {
      return client.post(`/psd2/kyc/initiate/`, null, {
        headers: {
          "x-company": companyId,
        },
        ...config,
      });
    },
    check: (companyId, config) => {
      return client.get(`/psd2/kyc/check/`, {
        headers: {
          "x-company": companyId,
        },
        ...config,
      });
    },
  },
  paymentInitiation: (companyId, verificationsIds, config) => {
    return client.post(
      `/psd2/pis/payment-init/`,
      { verifications: verificationsIds },
      {
        headers: {
          "x-company": companyId,
        },
        ...config,
      }
    );
  },
  basket: {
    create: (companyId, itemsToPay, isSameDevice, config) => {
      return client.post(
        `/psd2/pis/signing-basket/create/`,
        { items: itemsToPay, same_device: isSameDevice },
        {
          timeout: 5 * 60000,
          headers: {
            "x-company": companyId,
          },
          ...config,
        }
      );
    },
    signCheck: (companyId, basketId, isSameDevice, config) => {
      return client.get(`/psd2/pis/signing-basket/${basketId}/sca-check/`, {
        params: { same_device: isSameDevice },
        headers: {
          "x-company": companyId,
        },
        ...config,
      });
    },
    paymentsCheck: (companyId, basketId, config) => {
      return client.get(`/psd2/pis/signing-basket/${basketId}/check/`, {
        timeout: 5 * 60000,
        headers: {
          "x-company": companyId,
        },
        ...config,
      });
    },
  },
  single: {
    create: (companyId, payload, config) => {
      return client.post(`/psd2/pis/single-payment/create/`, payload, {
        timeout: 60000,
        headers: {
          "x-company": companyId,
        },
        ...config,
      });
    },
    signCheck: (companyId, paymentId, isSameDevice, config) => {
      return client.get(`/psd2/pis/single-payment/${paymentId}/sca-check/`, {
        params: { same_device: isSameDevice },
        headers: {
          "x-company": companyId,
        },
        ...config,
      });
    },
    paymentCheck: (companyId, paymentId, config) => {
      return client.get(`/psd2/pis/single-payment/${paymentId}/check/`, {
        timeout: 5 * 60000,
        headers: {
          "x-company": companyId,
        },
        ...config,
      });
    },
  },
};

export const eInvoice = {
  search: (companyId, filters, config) => {
    return client.get(`/einvoice/receivers/search/`, {
      params: filters,
      headers: {
        "x-company": companyId,
      },
      ...config,
    });
  },
  detail: (companyId, receiverId, config) => {
    return client.get(`/einvoice/receivers/${receiverId}/detail/`, {
      headers: {
        "x-company": companyId,
      },
      ...config,
    });
  },
  remove: (companyId, receiverId, config) => {
    return client.delete(`/einvoice/receivers/${receiverId}/delete/`, {
      headers: {
        "x-company": companyId,
      },
      ...config,
    });
  },
  connect: (companyId, receiverId, data, config) => {
    return client.put(`/einvoice/receivers/${receiverId}/connect/`, data, {
      headers: {
        "x-company": companyId,
      },
      ...config,
    });
  },
};

export const bankMatching = {
  detail: (companyId, filters, config) => {
    return client.get(`/psd2/bank-matching/detail/`, {
      params: filters,
      headers: {
        "x-company": companyId,
      },
      ...config,
    });
  },
  fetchBank: (companyId, dateFrom, dateTo, config) => {
    return client.post(
      `/psd2/bank-matching/fetch-bank/`,
      {
        date_from: formatDate(dateFrom),
        date_to: formatDate(dateTo),
      },
      {
        headers: {
          "x-company": companyId,
        },
        timeout: 2 * 60000,
        ...config,
      }
    );
  },
  fetchTax: (companyId, dateFrom, dateTo, config) => {
    return client.post(
      `/psd2/bank-matching/fetch-tax/`,
      {
        date_from: formatDate(dateFrom),
        date_to: formatDate(dateTo),
      },
      {
        headers: {
          "x-company": companyId,
        },
        ...config,
      }
    );
  },
  manualMatch: (companyId, data, config) => {
    return client.post(`/psd2/bank-matching/manual-match/`, data, {
      headers: {
        "x-company": companyId,
      },
      ...config,
    });
  },
  autoMatch: (companyId, params, config) => {
    return client.post(`/psd2/bank-matching/auto-match/`, null, {
      params,
      headers: {
        "x-company": companyId,
      },
      timeout: 120000,
      ...config,
    });
  },
  resetMatching: (companyId, params, config) => {
    return client.delete(`/psd2/bank-matching/reset-match/`, {
      params,
      headers: {
        "x-company": companyId,
      },
      ...config,
    });
  },
  resetBalances: (companyId, params, config) => {
    return client.delete(`/psd2/bank-matching/reset-balances/`, {
      params,
      headers: {
        "x-company": companyId,
      },
      ...config,
    });
  },
  bankTransactionDelete: (companyId, transactionId, config) => {
    return client.delete(`/psd2/bank-matching/bank-transactions/${transactionId}/delete/`, {
      headers: {
        "x-company": companyId,
      },
      ...config,
    });
  },
  bankTransactionResetMatch: (companyId, transactionId, config) => {
    return client.delete(`/psd2/bank-matching/bank-transactions/${transactionId}/reset-match/`, {
      headers: {
        "x-company": companyId,
      },
      ...config,
    });
  },
  bflowTransactionResetMatch: (companyId, transactionId, config) => {
    return client.delete(`/psd2/bank-matching/bflow-transactions/${transactionId}/reset-match/`, {
      headers: {
        "x-company": companyId,
      },
      ...config,
    });
  },
};

export const skatteverket = {
  connectionStatus: (companyId, config) =>
    client.get(`/skatteverket/connection-status/`, {
      headers: {
        "x-company": companyId,
      },
      ...config,
    }),
  testConnection: (companyId, config) =>
    client.get(`/skatteverket/test-connection/`, {
      headers: {
        "x-company": companyId,
      },
      ...config,
    }),
};
