import React, { useContext, useState } from "react";
import { Alert, Button, Card, Col, Row, Tooltip, OverlayTrigger } from "react-bootstrap";
import { Form, Formik } from "formik";
import * as yup from "yup";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import { SubmitButton } from "components/ui/buttons";
import { AllError, FormGroup } from "components/formik";
import { formatDate } from "utils/date";
import { formatMoney } from "utils/money";
import * as taxAPI from "api2/taxes";
import { FinancialYearDispatchContext } from "state/providers/FinancialYearProvider";
import { useCompanyState } from "hooks/useCompany";
import { enterKeyDownAsTab } from "utils/others";
import { BaseVerificationsPreviewModal } from "components/modals/VerificationPreviewModals";
import useModal from "../../../hooks/useModal";
import { handleActionErrors } from "../../../api/errors";

function TaxAuthorityForm({ companyId, tax, onSave }) {
  const { t } = useTranslation("tax");
  const { checkYears } = useContext(FinancialYearDispatchContext);
  const [showMore, setShowMore] = useState(false);
  const verPreviewModal = useModal();
  const getBalance = (values) => {
    const outgoingBalance =
      (values.incoming_balance || 0) +
      (values.personnel_tax || 0) +
      (values.soc_sec_fees || 0) +
      (values.prel_tax || 0) +
      (values.vat || 0) +
      (values.interest || 0) +
      (values.fees || 0) +
      (values.sick_pay_return || 0) +
      (values.company_tax || 0) +
      (values.refunded_debited_preliminary_tax || 0) +
      (values.special_payroll_tax || 0) +
      (values.other || 0);
    return {
      outgoingBalance,
      amount: outgoingBalance > 0 ? 0 : Math.abs(outgoingBalance),
    };
  };
  const {
    company: { ocr_tax },
  } = useCompanyState();
  const formatPayload = (values) => {
    return {
      ...values,
      due_date: formatDate(values.due_date),
      amount_currency: values.amount_currency.value,
    };
  };
  const { outgoingBalance: initialOutgoingBalance, amount: initialAmount } = getBalance(tax);
  const formikProps = {
    initialValues: {
      ...tax,
      outgoingBalance: initialOutgoingBalance,
      amount: initialAmount,
    },
    validationSchema: yup.object().shape({
      due_date: yup.date().nullable().required(),
    }),

    onSubmit: async (values, { setErrors }) => {
      const executeAnyway = await checkYears(values.booking_date);
      if (!executeAnyway) {
        return;
      }
      await taxAPI.authority
        .save(companyId, formatPayload(values))
        .then((response) => {
          toast.success(t("msg:saved"), { autoClose: 2000 });
          if (onSave) {
            onSave(response.data);
          }
        })
        .catch((error) => {
          toast.error(t("msg:fixErrors"));
          setErrors(error.data);
        });
    },
  };
  const onVerificationPreviewClick = (values) => {
    return taxAPI.authority
      .verificationPreview(companyId, formatPayload(values))
      .then((response) => {
        verPreviewModal.open({ verifications: response.data });
      })
      .catch((error) => {
        handleActionErrors(error);
      });
  };

  function calculateBalance(values, setFieldValue) {
    const { outgoingBalance, amount } = getBalance(values);
    setFieldValue("outgoingBalance", outgoingBalance, false);
    setFieldValue("amount", amount, false);
  }

  const setStateAsync = () => {
    return new Promise((resolve) => {
      setShowMore(true);
      resolve();
    });
  };
  async function onInterestEnter(event) {
    if (["Enter", "Tab"].includes(event.key)) await setStateAsync();
    enterKeyDownAsTab(event);
  }
  const isAtLeastOneAmountProvided = (values) => {
    const amounts = [
      values.personnel_tax,
      values.soc_sec_fees,
      values.prel_tax,
      values.vat,
      values.interest,
      values.fees,
      values.sick_pay_return,
      values.company_tax,
      values.refunded_debited_preliminary_tax,
      values.special_payroll_tax,
      values.other,
    ];
    for (let i = 0; i < amounts.length; i++) {
      if (amounts[i] !== 0) {
        return true;
      }
    }
    return false;
  };
  return (
    <Formik {...formikProps}>
      {({ values, isSubmitting, setFieldValue, errors, dirty }) => {
        return (
          <Form id="taxForm" onKeyDown={enterKeyDownAsTab}>
            <Card>
              <Card.Body>
                <Row>
                  <Col lg={3}>
                    <FormGroup.BookingDatePicker name="due_date" label={t("common:dates.dueDate")} required />
                  </Col>
                </Row>
                <fieldset disabled={!values.due_date}>
                  <Row>
                    <Col lg={3}>
                      <FormGroup.MoneyInput
                        name="incoming_balance"
                        label={t("incomingBalance")}
                        disabled
                        onBlur={() => calculateBalance(values, setFieldValue)}
                      />
                    </Col>
                    <Col lg={3}>
                      <FormGroup.MoneyInput
                        name="personnel_tax"
                        label={t("personalTax")}
                        required
                        onBlur={() => calculateBalance(values, setFieldValue)}
                      />
                    </Col>
                    <Col lg={3}>
                      <FormGroup.MoneyInput
                        name="soc_sec_fees"
                        label={t("socSecFees")}
                        required
                        onBlur={() => calculateBalance(values, setFieldValue)}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={3}>
                      <FormGroup.MoneyInput
                        name="prel_tax"
                        label={t("prelTax")}
                        required
                        onBlur={() => calculateBalance(values, setFieldValue)}
                      />
                    </Col>
                    <Col lg={3}>
                      <FormGroup.MoneyInput
                        name="vat"
                        label={t("common:money.vat")}
                        required
                        onBlur={() => calculateBalance(values, setFieldValue)}
                      />
                    </Col>
                    <Col lg={3}>
                      <FormGroup.MoneyInput
                        name="interest"
                        label={t("interestFees")}
                        required
                        onBlur={() => calculateBalance(values, setFieldValue)}
                        onKeyDown={(event) => onInterestEnter(event)}
                      />
                    </Col>
                  </Row>
                  <button
                    type="button"
                    className="btn btn-link p-0 mb-3 mt-2"
                    onClick={() => setShowMore((prevValue) => !prevValue)}
                  >
                    <span style={{ color: "#ADADAD" }}>
                      {showMore ? t("common:actions.showLess") : t("common:actions.showMore")}
                    </span>{" "}
                    <i className={showMore ? "fas fa-chevron-up" : "fas fa-chevron-down"} />
                  </button>
                  {showMore && (
                    <>
                      <Row>
                        <Col lg={3}>
                          <FormGroup.MoneyInput
                            name="fees"
                            label={t("fees")}
                            required
                            onBlur={() => calculateBalance(values, setFieldValue)}
                          />
                        </Col>
                        <Col lg={3}>
                          <FormGroup.MoneyInput
                            name="sick_pay_return"
                            label={t("sickPayReturn")}
                            required
                            onBlur={() => calculateBalance(values, setFieldValue)}
                          />
                        </Col>
                        <Col lg={3}>
                          <FormGroup.MoneyInput
                            name="company_tax"
                            label={t("companyTax")}
                            required
                            onBlur={() => calculateBalance(values, setFieldValue)}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col lg={3}>
                          <FormGroup.MoneyInput
                            name="refunded_debited_preliminary_tax"
                            label={t("refundedDebitedPreliminaryTax")}
                            required
                            onBlur={() => calculateBalance(values, setFieldValue)}
                          />
                        </Col>
                        <Col lg={3}>
                          <FormGroup.MoneyInput
                            name="special_payroll_tax"
                            label={t("specialPayrollTax")}
                            required
                            onBlur={() => calculateBalance(values, setFieldValue)}
                          />
                        </Col>
                        <Col lg={3}>
                          <FormGroup.MoneyInput
                            name="other"
                            label={t("bread:Other")}
                            required
                            onBlur={() => calculateBalance(values, setFieldValue)}
                          />
                        </Col>
                      </Row>
                    </>
                  )}
                </fieldset>
                <Row>
                  <Col>
                    <Alert variant="info" className="font-14">
                      {t("outgoingTaxBalanceOnAcc")}: <strong>{formatMoney(values.outgoingBalance)} SEK</strong>
                      <br />
                      {t("paymentToTaxAcc")}: <strong>{formatMoney(values.amount)} SEK</strong>
                    </Alert>
                    <Alert variant="warning">{t("toSkatt", { ocr: ocr_tax })}</Alert>
                  </Col>
                </Row>
                <AllError errors={errors} />
              </Card.Body>
              <Card.Footer className="space">
                <Col>
                  <OverlayTrigger
                    placement="bottom"
                    overlay={
                      <Tooltip>
                        {!isAtLeastOneAmountProvided(values) ? t("msg:oneAmountNeeded") : t("ver:previewVer")}
                      </Tooltip>
                    }
                  >
                    <Button
                      disabled={!isAtLeastOneAmountProvided(values)}
                      className="mr-1"
                      variant="second"
                      onClick={() => onVerificationPreviewClick(values)}
                    >
                      <i className="mdi mdi-file-search-outline" /> {t("ver:verShort")}
                    </Button>
                  </OverlayTrigger>
                  {!isAtLeastOneAmountProvided(values) ? (
                    <OverlayTrigger placement="bottom" overlay={<Tooltip>{t("msg:oneAmountNeeded")}</Tooltip>}>
                      <SubmitButton disabled isSubmitting={isSubmitting} />
                    </OverlayTrigger>
                  ) : (
                    <SubmitButton isSubmitting={isSubmitting} />
                  )}
                </Col>
              </Card.Footer>
            </Card>
            {verPreviewModal.show && (
              <BaseVerificationsPreviewModal
                handleClose={verPreviewModal.close}
                verifications={verPreviewModal.data.verifications}
                isLoading={false}
                topText={t("ver:preview.topTextTaxAuthority")}
              />
            )}
          </Form>
        );
      }}
    </Formik>
  );
}

export default TaxAuthorityForm;
