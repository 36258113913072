import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { FilterButton } from "components/ui/buttons";
import useTable from "components/tables/btable/useTable";
import { DateRangeFilters, SearchFilter } from "components/filters";

function TaxAuthorityFilters({ companyId, forStatus }) {
  const { filters, setFilters } = useTable();
  const { t } = useTranslation("common");
  const filtersCount = !!filters.due_date__start + !!filters.due_date__end;
  const [more, setMore] = useState(!!filtersCount);

  return (
    <>
      <section className="table-filters-left">
        <SearchFilter onFilter={setFilters} defaultValue={filters.term} width={300} disabled />
        <FilterButton active={more} count={filtersCount} onClick={() => setMore(!more)} />
      </section>
      <section className="table-filters-right">
        <Link to="/consult/taxes/form" className="btn btn-first">
          <i className="fas fa-plus" /> {t("common:actions.create")}
        </Link>
      </section>
      {more && (
        <section className="more-filters">
          <DateRangeFilters
            label={t("common:dates.dueDate")}
            dateStart={filters.due_date__start}
            dateEnd={filters.due_date__end}
            onChange={({ start, end }) => setFilters({ due_date__start: start, due_date__end: end })}
          />
        </section>
      )}
    </>
  );
}

export default TaxAuthorityFilters;
