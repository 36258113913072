import React from "react";
import { Card } from "react-bootstrap";

import TableProvider from "components/tables/btable/provider";
import { UserExtendedTable } from "components/tables";
import * as userAPI from "api2/users";
import { UserAddWidget } from "components/forms/UserForm";
import { useParams } from "react-router-dom";

function AgencyUserListPage({ tableId = "agency-users" }) {
  const { agencyId } = useParams();
  const initialFilters = {
    is_active: true,
  };
  return (
    <TableProvider
      tableId={tableId}
      dataSource={(filters, config) => userAPI.agencies.userList(agencyId, filters, config)}
      initialFilters={initialFilters}
    >
      <Card>
        <Card.Body>
          <UserExtendedTable agencyId={agencyId} />
        </Card.Body>
      </Card>
      <UserAddWidget agencyId={agencyId} />
    </TableProvider>
  );
}

export default AgencyUserListPage;
