import React from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import {
  HistoryButton,
  TooltipActionButton,
  TooltipActionLink,
  VerificationBookingButton,
} from "components/ui/buttons";
import { Button, Modal } from "react-bootstrap";
import { confirmRemove } from "components/modals/ConfirmModal";
import useTable from "components/tables/btable/useTable";
import * as vatAPI from "api2/vat";
import useModal from "hooks/useModal";
import { BookVATReportModal } from "components/modals";
import FileUrlModal from "components/modals/FileUrlModal/FileUrlModal";
import { getFileNameDispositionContent } from "../../../utils/file";

function VATReportActions({ row, companyId }) {
  const { t } = useTranslation("tax");
  const {
    dataActions: { reload },
  } = useTable();
  const reportModal = useModal();
  const vatIssuedVerificationsModal = useModal();
  const show = {
    logBtn: !!row.vat_report,
    downloadBtn: !!row.vat_report,
    bookingBtn: !!row.vat_report,
    deleteBtn: !!row.vat_report,
  };

  const onCloseReportModal = (success) => {
    reportModal.close();
    if (success) {
      reload();
    }
  };
  const handleBackToReportModal = () => {
    vatIssuedVerificationsModal.close();
    reportModal.open();
  };
  const openVatIssuedVerifications = () => {
    vatIssuedVerificationsModal.open(`/vat/reports/draft/${row.id}/issued-pdf/`);
    reportModal.close();
    return null;
  };
  const downloadXML = async () => {
    await vatAPI.reports.reportXml(companyId, row.id).then((response) => {
      const fileName = getFileNameDispositionContent(response, false) || `${row.description}.xml`;
      const element = document.createElement("a");
      const url = window.URL.createObjectURL(response.data);
      element.href = url;
      element.download = fileName;
      document.body.appendChild(element);
      element.click();
      element.remove();
      URL.revokeObjectURL(url);
    });
  };

  const onDelete = async () => {
    const answer = await confirmRemove(t("confirm.removeVAT", { title: row.description }));
    if (answer) {
      vatAPI.reports
        .remove(companyId, row.vat_report)
        .then(() => {
          toast.success(t("msg:deleted"));
          reload();
        })
        .catch(() => toast.error(t("msg:canNotExecuteAction")));
    }
  };

  return (
    <>
      <TooltipActionButton text={t("common:documents")} onClick={reportModal.open} icon="fas fa-images" />
      {show.downloadBtn && <TooltipActionButton text="XML" icon="fas fa-download" onClick={downloadXML} />}
      {show.logBtn && <HistoryButton apiResource={vatAPI.reports.history} apiParams={[companyId, row.vat_report]} />}
      {show.bookingBtn && (
        <VerificationBookingButton companyId={companyId} verification={{ ...row, id: row.vat_report }} />
      )}
      {show.editBtn && (
        <TooltipActionLink
          text={t("common:actions.change")}
          link={`/consult/salaries/${row.id}/change`}
          icon="fas fa-pen"
        />
      )}
      {show.deleteBtn && (
        <TooltipActionButton text={t("common:actions.remove")} onClick={onDelete} icon="fas fa-trash" />
      )}
      {reportModal.show && (
        <BookVATReportModal
          companyId={companyId}
          data={row}
          downloadXML={downloadXML}
          handleClose={onCloseReportModal}
          openVatIssuedVerifications={openVatIssuedVerifications}
        />
      )}
      {vatIssuedVerificationsModal.show && (
        <FileUrlModal
          companyId={companyId}
          show
          handleClose={vatIssuedVerificationsModal.close}
          fileUrl={vatIssuedVerificationsModal.data}
        >
          <Modal.Footer className="space">
            <Button variant="outline-primary" onClick={handleBackToReportModal}>
              {t("common:actions.goBack")}
            </Button>
          </Modal.Footer>
        </FileUrlModal>
      )}
    </>
  );
}

export default VATReportActions;
