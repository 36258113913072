import React from "react";
import { useNavigate, useOutletContext, useParams, useResolvedPath } from "react-router-dom";
import { toast } from "react-toastify";

import useInitialAsync from "hooks/useInitialAsync";
import * as invoiceAPI from "api2/supplier-invoices";
import { RocketLoader } from "components/ui/loaders";
import {
  MoveToFileArchiveBtn,
  RemoveBtn,
  SaveAsDraftBtn,
  SupplierInvoiceForm,
} from "components/forms/SupplierInvoiceForm/SupplierInvoiceForm";
import { useMenuNumbersDispatch } from "hooks/useMenuNumbers";
import { useTranslation } from "react-i18next";
import { PermCodeRequired } from "components/perms";
import { codesForSupplierInvoices } from "components/perms/PermCodes";
import { Button, ButtonGroup, Col, Row } from "react-bootstrap";
import { useCompanyDispatch } from "hooks/useCompany";
import { SubmitButton } from "components/ui/buttons";

function findNextDraft(currentInvoiceId) {
  const draftSiList = JSON.parse(sessionStorage.getItem("consult-drafts")) || [];
  const currentIndex = draftSiList.findIndex((item) => item[0] === currentInvoiceId);
  if (draftSiList.length > currentIndex + 1) {
    const nextDraft = draftSiList[currentIndex + 1];
    draftSiList.splice(currentIndex, 1);
    sessionStorage.setItem("consult-drafts", JSON.stringify(draftSiList));
    return nextDraft;
  }
  return null;
}

function NextDraftInvoiceBtn({ invoiceId, invoiceStatus, onNextFn }) {
  const { t } = useTranslation("si");
  const onNextInvoice = async () => {
    await onNextFn(invoiceId);
  };
  if (invoiceId && invoiceStatus === 0) {
    return (
      <PermCodeRequired code={codesForSupplierInvoices.manage}>
        <Button onClick={onNextInvoice} className="mr-2" variant="second">
          {t("nextDraftInvoice")}
        </Button>
      </PermCodeRequired>
    );
  }
  return null;
}

function GlobalSupplierInvoiceEditPage() {
  const { reload: reloadMenuNumbers } = useMenuNumbersDispatch();
  const { t } = useTranslation("si");
  const { selectCompany: selectCompanyAction } = useCompanyDispatch();
  const navigate = useNavigate();
  const company = useOutletContext();
  const currentPath = useResolvedPath("").pathname;
  const { id: verificationId } = useParams();

  const { loading, item } = useInitialAsync(
    ({ cancelToken }) => {
      return invoiceAPI.details(company.id, verificationId, { cancelToken });
    },
    {},
    [verificationId]
  );

  const moveToNextDraft = async (invoiceId) => {
    const nextDraft = findNextDraft(invoiceId);
    if (nextDraft) {
      const nextDraftInvoiceId = nextDraft[0];
      const nextDraftCompanyId = nextDraft[1];
      const nextLoc = currentPath.replace(invoiceId, nextDraftInvoiceId);
      if (nextDraftCompanyId !== company.id) {
        await selectCompanyAction(nextDraftCompanyId).then((response) => {
          toast.info(t("msg:companySwitched"));
          navigate(nextLoc, { replace: true });
        });
      } else {
        navigate(nextLoc, { replace: true });
      }
    } else {
      navigate("/consult-draft-si", { replace: true });
    }
  };

  const onUpdate = async (invoice) => {
    reloadMenuNumbers();
    await moveToNextDraft(invoice.id);
  };

  const onDelete = async (invoice) => {
    reloadMenuNumbers();
    await moveToNextDraft(invoice.id);
  };

  if (loading) {
    return <RocketLoader />;
  }

  return (
    <SupplierInvoiceForm invoice={item} company={company} onSave={onUpdate}>
      {({ showMoveToArchiveBtn, isSubmitting, setFieldValue }) => (
        <Row>
          <Col xl={showMoveToArchiveBtn ? 7 : 8}>
            <ButtonGroup>
              <NextDraftInvoiceBtn invoiceStatus={item.status} invoiceId={item.id} onNextFn={moveToNextDraft} />
              <SaveAsDraftBtn invoiceStatus={item.status} isSubmitting={isSubmitting} setFieldValue={setFieldValue} />
              <SubmitButton
                isSubmitting={isSubmitting}
                onClick={() => {
                  setFieldValue("status", 1);
                }}
              />
            </ButtonGroup>
          </Col>
          <Col xl={showMoveToArchiveBtn ? 5 : 4}>
            <ButtonGroup className="float-right">
              <MoveToFileArchiveBtn
                showMoveToArchiveBtn={showMoveToArchiveBtn}
                invoice={item}
                companyId={company.id}
                postDeleteFn={onDelete}
              />
              <RemoveBtn companyId={company.id} invoice={item} postDeleteFn={onDelete} />
            </ButtonGroup>
          </Col>
        </Row>
      )}
    </SupplierInvoiceForm>
  );
}

export default GlobalSupplierInvoiceEditPage;
