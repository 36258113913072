import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import _ from "lodash";
import { Alert, Button, Modal, OverlayTrigger, Popover, Table, Tooltip } from "react-bootstrap";
import cx from "classnames";
import { useTranslation } from "react-i18next";
import { isWeekend } from "date-fns";
import { Form, Formik, useFormikContext } from "formik";
import { formatMoney } from "utils/money";
import { formatDate, formatDatetime, parseDate } from "utils/date";
import { truncateText } from "utils/text";
import { TableGroup } from "components/formik";
import { BasketContext } from "../BasketProvider";
import TableLoader from "../../../tables/btable/TableLoader";

function AmountColumn({ item }) {
  const { t } = useTranslation("common");
  const fxQuote = item.fx_quote; // international payment rate and fee
  if (!fxQuote) {
    return <span>{formatMoney(item.payment_amount_sek)} SEK</span>;
  }
  return (
    <OverlayTrigger
      trigger={["hover", "click"]}
      placement="top"
      overlay={
        <Tooltip id={String(_.uniqueId("tooltip_"))} className="fx_currency_tooltip">
          <div>
            1 {item.payment_currency} = {formatMoney(fxQuote.rate, 2, 4)} SEK
          </div>
          <div>
            <span className="text-muted">{t("fixedFee")}:</span> {fxQuote.fee} SEK
          </div>
        </Tooltip>
      }
    >
      <span>
        {formatMoney(item.payment_amount_sek, 2, 2)} SEK <i className="fe-info" />
      </span>
    </OverlayTrigger>
  );
}

function ItemRow({ item, index, loading, hasError, onRemove }) {
  const fxQuote = item.fx_quote; // international payment rate and fee
  const { t } = useTranslation("common");
  return (
    <tr className={cx({ "has-error": hasError })}>
      <td>
        <span>{item.title}</span>
        {!item.error && item.last_payment && (
          <p className="text-warn">
            <i className="fe-alert-circle" />
            {t("basket.paymentInitiated", {
              by: item.last_payment.created_by,
              at: formatDatetime(item.last_payment.created_at),
            })}
          </p>
        )}
        {item.error && <p className="text-error">{item.error}</p>}
      </td>
      <td className="text-right" title={item.reference}>
        {truncateText(item.reference, 40)}
        {item.reference_updated && (
          <OverlayTrigger
            placement="top"
            overlay={
              <Popover id={String(_.uniqueId("hint_"))} className="popover-hint">
                <Popover.Content>{t("basket.internationalNumberChanged")}</Popover.Content>
              </Popover>
            }
          >
            <i className="fas fa-question-circle ml-1" />
          </OverlayTrigger>
        )}
      </td>
      <TableGroup.DatePicker
        name={`payments[${index}].payment_date`}
        minDate={new Date()}
        popperClassName="popper-in-modal"
        isClearable={false}
        disabled={!!fxQuote}
        filterDate={(date) => {
          return !isWeekend(date);
        }}
      />
      <td className="text-right">
        <AmountColumn item={item} />
      </td>
      <td className="trash-item">
        <Button type="button" onClick={() => onRemove(item.id)} disabled={loading}>
          <i className="fe-trash-2" />
        </Button>
      </td>
    </tr>
  );
}

function SameDeviceSubmit({ ...btnProps }) {
  const { submitForm } = useFormikContext();
  const { t } = useTranslation("common");
  const {
    actions: { setSameDevice },
  } = useContext(BasketContext);
  const sameDeviceSubmit = () => {
    setSameDevice(true);
    return submitForm();
  };
  return (
    <Button variant="link" type="button" onClick={sameDeviceSubmit} {...btnProps}>
      {t("others:bankid.sameDevice")}
    </Button>
  );
}

function InitialStage({ initialSelection }) {
  const { t } = useTranslation("common");
  const [items, setItems] = useState([]);
  const [info, setInfo] = useState("");
  const isSigningBasket = useRef(true);
  const initialSelectionIds = useMemo(() => initialSelection.map((item) => item.id), [initialSelection]);
  const {
    loading,
    error,
    actions: { getInitialList, createBasket, startSinglePayments },
  } = useContext(BasketContext);

  const formikProps = {
    enableReinitialize: true,
    initialValues: { payments: items.map((item) => ({ ...item, payment_date: parseDate(item.payment_date) })) },
    onSubmit: async (values, { setValues }) => {
      if (isSigningBasket.current === true) {
        createBasket(values.payments).catch((response) => {
          setValues({
            payments: values.payments.map((item, idx) => {
              return {
                ...item,
                error: response.data[idx] ? response.data[idx] : "",
              };
            }),
          });
        });
      } else {
        startSinglePayments(values.payments);
      }
    },
  };

  const totalAmount = items.reduce((total, ver) => total + ver.payment_amount_sek, 0);

  useEffect(() => {
    getInitialList(initialSelectionIds).then((response) => {
      isSigningBasket.current = response.data.basket_support;
      setItems(
        response.data.payments_data.map((_item) => {
          if (_item.fx_quote) {
            return {
              ..._item,
              payment_amount_sek:
                _item.payment_amount * parseFloat(_item.fx_quote.rate) + parseFloat(_item.fx_quote.fee),
            };
          }
          return { ..._item, payment_amount_sek: _item.payment_amount };
        })
      );
      setInfo(response.data.info);
    });
  }, [getInitialList, initialSelectionIds]);

  const removeItem = (itemId) => {
    setItems((_items) => _items.filter((item) => item.id !== itemId));
  };

  const hasError = error || items.filter((item) => item.error).length > 0;
  const today = formatDate(new Date());
  return (
    <>
      <div className="title">
        <h2>{t("basket.paymentsInitiation")}</h2>
        {info && !hasError && <h4 className="text-warning">{info}</h4>}
        {loading && items.length > 0 && (
          <h4>
            <i className="fa fa-spinner fa-spin" /> {t("basket.checkingPayments")}
          </h4>
        )}
        {!loading && hasError && <h4 className="text-error">{error || t("basket.error")}</h4>}
      </div>
      <Formik {...formikProps}>
        {({ values }) => {
          const hasTodayExecution =
            values.payments.filter((item) => formatDate(item.payment_date) === today).length > 0;
          return (
            <Form id="psdInitialForm">
              <Modal.Body className="overflow-hidden">
                {loading && <TableLoader />}
                <Table>
                  <thead>
                    <tr>
                      <th style={{ width: 320 }}>{t("receiver")}</th>
                      <th className="text-right">{t("basket.refOrOcr")}</th>
                      <th className="text-right">{t("dates.paymentDate")}</th>
                      <th className="text-right">{t("money.amount")}</th>
                      <th />
                    </tr>
                  </thead>
                  <tbody>
                    {!loading && !items.length && (
                      <tr>
                        <td colSpan={5}>{t("noResultsFound")}</td>
                      </tr>
                    )}
                    {values.payments.map((item, index) => (
                      <ItemRow
                        key={item.id}
                        item={item}
                        index={index}
                        loading={loading}
                        hasError={item.error || error}
                        onRemove={removeItem}
                      />
                    ))}
                  </tbody>
                  <tfoot>
                    <tr>
                      <th colSpan={2} />
                      <th colSpan={2} className="text-right total">
                        = {formatMoney(totalAmount, 2, 2)} SEK
                      </th>
                      <th />
                    </tr>
                  </tfoot>
                </Table>
              </Modal.Body>
              <Modal.Footer>
                {hasTodayExecution && (
                  <Alert variant="warning" className="text-center">
                    {t("basket.todayInfo")}
                  </Alert>
                )}
                {items.length > 0 && (
                  <Button variant="first" type="submit" disabled={loading}>
                    {hasError ? t("actions.tryAgain") : t("basket.proceed")}
                  </Button>
                )}
              </Modal.Footer>
              <Modal.Footer>{items.length > 0 && <SameDeviceSubmit disabled={loading} />}</Modal.Footer>
            </Form>
          );
        }}
      </Formik>
    </>
  );
}

export default InitialStage;
