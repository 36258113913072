import React from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { TooltipActionButton } from "components/ui/buttons";
import { confirmRemove } from "components/modals/ConfirmModal";
import * as vatAPI from "api2/vat";
import useTable from "components/tables/btable/useTable";

function EventActions({ row }) {
  const { t } = useTranslation("others");
  const {
    dataActions: { reload },
  } = useTable();

  const onDelete = async () => {
    const answer = await confirmRemove(t("confirm.removeEvent", { title: row.description }));
    if (answer) {
      vatAPI.events
        .remove(row.id)
        .then(() => {
          toast.success(t("msg:deleted"));
          reload();
        })
        .catch((error) => {
          if (error.data && error.data.__all__) {
            toast.error(error.data.__all__);
          } else {
            toast.error(t("msg:canNotExecuteAction"));
          }
        });
    }
  };

  return (
    <TooltipActionButton variant="danger" text={t("common:actions.remove")} onClick={onDelete} icon="fas fa-trash" />
  );
}

export default EventActions;
