import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import { BankIDLoader } from "components/ui/loaders";
import { BasketContext } from "../BasketProvider";

function DecoupledStage() {
  const { t } = useTranslation("common");
  const {
    bic,
    error,
    isSameDevice,
    actions: { checkSigning },
  } = useContext(BasketContext);
  const statusRef = useRef(false);
  const [data, setData] = useState({
    info: "",
    qrCode: null,
  });

  const checkStatusInterval = useMemo(() => {
    if (isSameDevice) {
      return 5000;
    }
    return bic === "NDEASESS" ? 2000 : 1000;
  }, [bic, isSameDevice]); // BFLAKT-2981

  useEffect(() => {
    const interval = setInterval(() => {
      if (!statusRef.current) {
        statusRef.current = true;
        checkSigning()
          .then((response) => {
            if (response.data.stage !== "") {
              setData({
                info: response.data.info,
                qrCode: response.data.image,
              });
            }
          })
          .finally(() => {
            statusRef.current = false;
          });
      }
    }, checkStatusInterval);

    return () => clearInterval(interval);
  }, [checkSigning, checkStatusInterval]);

  useEffect(() => {
    checkSigning().then((response) => {
      setData({
        info: response.data.info,
        qrCode: response.data.image,
      });
    });
  }, [checkSigning]);

  return (
    <>
      <div className="title">
        <h2>{t("basket.paymentsSigning")}</h2>
        {data.info && <h4>{data.info}</h4>}
        {error && <h4 className="text-error">{error || t("basket.error")}</h4>}
      </div>
      {isSameDevice ? (
        <Modal.Body className="stage-decoupled">
          <a href={data.qrCode} className="btn btn-first mt-4">
            {t("others:bankid.signTitle")} <i className="fe-external-link" />
          </a>
        </Modal.Body>
      ) : (
        <Modal.Body className="stage-decoupled">
          {data.info && !error && !data.qrCode && <BankIDLoader bankIdLogo text={false} />}
          {!error && data.qrCode && <img src={data.qrCode} alt="SCAN ME" />}
        </Modal.Body>
      )}
    </>
  );
}

export default DecoupledStage;
