import { Button, Modal } from "react-bootstrap";
import React, { useEffect, useRef, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import * as authAPI from "api2/auth";
import "./PayBankIDModal.scss";

function PayBankIDModalNew({ handleSuccess, handleClose, bankIDMsg = "" }) {
  const { t } = useTranslation("others");
  const [signData, setSignData] = useState({ token: "", qr: "", error: "" });
  const [retryCount, setRetryCount] = useState(0);
  const ref = useRef(1);
  const successRef = useRef(false);

  useEffect(() => {
    authAPI
      .bankIDSignRequest(bankIDMsg)
      .then((data) => {
        setSignData({ qr: data.qr, token: data.token, error: "" });
      })
      .catch((error) => {
        setSignData({ token: "", qr: "", error: error.data.__all__ });
      });
  }, [bankIDMsg, retryCount]);

  useEffect(() => {
    let interval;
    if (signData.token) {
      interval = setInterval(() => {
        return authAPI
          .bankIDSignCheck(signData.token)
          .then((response) => {
            if (response.status === 200) {
              setSignData((_d) => ({ ..._d, qr: response.data.qr }));
            }
            if (response.status === 201) {
              if (!successRef.current) {
                // if bank id will not get response in 2s then we may get duplicated 201 response and duplicated payments
                // this trick prevents that
                successRef.current = true;
                clearInterval(interval);
                handleSuccess(signData.token);
              }
            }
          })
          .catch((error) => {
            clearInterval(interval);
            setSignData((_d) => ({ ..._d, error: error.data.__all__ }));
          });
      }, 1200);
    }
    return () => {
      ref.current = 0; // prevent to continue if closed before token set
      clearInterval(interval);
    };
  }, [signData.token, handleSuccess]);

  const retry = () => {
    setRetryCount(retryCount + 1);
  };
  return (
    <Modal show onHide={handleClose} size="lg" scrollable className="bankID-sign-modal">
      <button type="button" className="close" onClick={handleClose}>
        <i className="fe-x" />
      </button>
      {signData.error ? (
        <Modal.Body>
          <p className="modal-title">{t("common:errors.sthWentWrong")}</p>
          <p>{signData.error}</p>
          <Button variant="outline-danger" onClick={retry}>
            {t("payBankRetry")}
          </Button>
        </Modal.Body>
      ) : (
        <Modal.Body>
          <p className="modal-title">{t("others:bankid.signTitle")}</p>
          <ol>
            <Trans i18nKey="others:bankid.steps">
              <li>Open the BankID app on your phone</li>
              <li>Tap the QR symbol in the BankID app</li>
              <li>Scan the QR code with your camera</li>
            </Trans>
          </ol>
          <div className="qrCode">{signData.qr && <img src={signData.qr} alt="BankID QR Code" />}</div>
          <a
            href={`https://app.bankid.com/?autostarttoken=${signData.auto_start_token}&redirect=null`}
            rel="noreferrer"
            target="_blank"
            className="btn btn-link underline"
          >
            {t("others:bankid.sameDevice")}
          </a>
        </Modal.Body>
      )}
      <Modal.Footer>
        <Button variant="link" onClick={handleClose}>
          {t("common:actions.cancel")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default PayBankIDModalNew;
