import client from "./client";

export function history(companyId, invoiceId, config) {
  return client.get(`/customer-invoices/${invoiceId}/history/`, {
    headers: {
      "x-company": companyId,
    },
    ...config,
  });
}
