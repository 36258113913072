import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { Link, useLocation, useNavigate, useResolvedPath } from "react-router-dom";
import useTable from "components/tables/btable/useTable";
import {
  CheckboxFilter,
  FinancialYearFilter,
  MonthDateFilter,
  SearchFilter,
  SelectSimpleFilter,
} from "components/filters";
import { PermCodeRequired } from "components/perms";
import { codesForCompanyArchive } from "components/perms/PermCodes";
import archiveCategories, { categoryByValue } from "utils/calc/companyArchiveCategories";
import { FiltersContext } from "state/providers/FiltersProvider";

function CompanyArchiveFilters() {
  const { filters, setFilters } = useTable();
  const navigate = useNavigate();
  const { state } = useLocation();
  const { pathname } = useResolvedPath("");
  const { t } = useTranslation("common");
  const { updateFilter: sectionUpdateFilter } = useContext(FiltersContext);

  const saveFilterToState = (filter) => {
    navigate(pathname, {
      state: {
        ...state,
        ...filter,
      },
    });
  };

  return (
    <>
      <section className="table-filters-left">
        <SearchFilter
          onFilter={(selected) => {
            saveFilterToState(selected);
            setFilters(selected);
          }}
          defaultValue={filters.term}
          label="as"
          hiddenLabel
        />
        <SelectSimpleFilter
          name="category"
          label={t("common:category")}
          options={archiveCategories}
          getOptionLabel={(option) => t(`options:cdc.${option.value}`)}
          formatGroupLabel={(group) => t(`options:cdc.${group.label}`)}
          isClearable
          defaultValue={filters.category}
          getDefaultFn={categoryByValue}
          onFilter={(selected) => {
            saveFilterToState(selected);
            setFilters(selected);
          }}
        />
        <MonthDateFilter
          name="month"
          label={t("common:dates.month")}
          defaultValue={filters.month}
          isClearable
          onChange={(d) => setFilters({ ...filters, month: d })}
        />
        <FinancialYearFilter
          label={t("common:financialYear")}
          onFilter={(selected) => {
            saveFilterToState(selected);
            setFilters(selected);
            sectionUpdateFilter(selected);
          }}
          defaultValue={filters.financial_year}
          isClearable
        />
        <CheckboxFilter
          label={t("common:archived")}
          name="is_archived"
          wrapperClass="pt-3"
          defaultChecked={!!filters.is_archived}
          defaultValue={!!filters.is_archived}
          onFilter={(selected) => {
            setFilters({ ...filters, ...selected });
          }}
        />
      </section>
      <section className="table-filters-right align-items-center">
        <PermCodeRequired code={codesForCompanyArchive.manage}>
          <Link to={`${pathname}/form`} className="btn btn-first">
            <i className="fas fa-plus" /> {t("common:actions.create")}
          </Link>
        </PermCodeRequired>
      </section>
    </>
  );
}

export default CompanyArchiveFilters;
