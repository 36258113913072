import React, { useEffect, useRef, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { Trans, useTranslation } from "react-i18next";

import { useAuthDispatch } from "hooks/useAuth";
import * as authAPI from "api2/auth";
import "./BankIDLoginModal.scss";

function BankIDLoginModal({ loginData, handleClose }) {
  const { t } = useTranslation();
  const statusRef = useRef(false);
  const [signData, setSignData] = useState(() => ({ ...loginData, error: "" }));
  const { bankIDLoginCheck, loggedInAction } = useAuthDispatch();

  useEffect(() => {
    const interval = setInterval(() => {
      if (!statusRef.current) {
        statusRef.current = true;
        authAPI
          .bankIDLoginCheck(signData.token)
          .then((response) => {
            if (response.status === 200) {
              setSignData((_d) => ({ ..._d, qr: response.data.qr }));
              statusRef.current = false;
            } else if (response.status === 201) {
              loggedInAction(response.data.token, response.data.user);
            }
          })
          .catch((error) => {
            setSignData((_d) => ({
              ..._d,
              error: error.data ? error.data.__all__ : t("common:basket.pendingSignedInfo"),
            }));
          });
      }
    }, 1200);
    return () => {
      clearInterval(interval);
    };
  }, [t, bankIDLoginCheck, loggedInAction, signData.token]);

  return (
    <Modal show onHide={handleClose} size="lg" scrollable className="bankID-login-modal">
      <button type="button" className="close" onClick={handleClose}>
        <i className="fe-x" />
      </button>
      {signData.error ? (
        <Modal.Body>
          <p className="modal-title">{t("common:errors.sthWentWrong")}</p>
          <p>{signData.error}</p>
        </Modal.Body>
      ) : (
        <Modal.Body>
          <p className="modal-title">{t("others:bankid.loginTitle")}</p>
          <ol>
            <Trans i18nKey="others:bankid.steps">
              <li>Open the BankID app on your phone</li>
              <li>Tap the QR symbol in the BankID app</li>
              <li>Scan the QR code with your camera</li>
            </Trans>
          </ol>
          <div className="qrCode">
            <img src={signData.qr} alt="BankID QR Code" />
          </div>
          <a
            href={`https://app.bankid.com/?autostarttoken=${signData.auto_start_token}&redirect=null`}
            rel="noreferrer"
            target="_blank"
            className="btn btn-link underline"
          >
            {t("others:bankid.sameDevice")}
          </a>
        </Modal.Body>
      )}
      <Modal.Footer>
        <Button variant="link" onClick={handleClose}>
          {t("common:actions.cancel")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default BankIDLoginModal;
