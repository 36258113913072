import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Col, Row } from "react-bootstrap";

import { BExpandedTable } from "components/tables/btable";
import { CostCenterEditWidget } from "components/forms/company-settings-forms/CostCenterForm";
import Filters from "./CostCenterFilters";

function CostCenterExtendedTable({ companyId }) {
  const { t } = useTranslation("company");
  const headers = useMemo(
    () => [
      {
        field: "name",
        label: t("common:name"),
      },
      {
        field: "serial",
        label: t("serialOrNumber"),
      },
      {
        field: "assigned_employee_name",
        label: t("assignedToEmployee"),
        canSort: false,
      },
      {
        field: "is_active",
        label: t("common:statuses.active"),
        render: (values) => <span>{values.is_active ? t("common:actions.yes") : t("common:actions.no")}</span>,
      },
    ],
    [t]
  );

  return (
    <Row>
      <Col>
        <BExpandedTable
          headers={headers}
          FiltersComponent={<Filters />}
          renderExpandedRow={(row) => <CostCenterEditWidget companyId={companyId} id={row.id} />}
        />
      </Col>
    </Row>
  );
}

export default CostCenterExtendedTable;
