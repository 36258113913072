import React from "react";
import { TooltipActionButton } from "components/ui/buttons";
import { useTranslation } from "react-i18next";

import LockAccountingModal from "components/modals/LockAccountingModal";
import useModal from "hooks/useModal";
import useTable from "components/tables/btable/useTable";
import * as userAPI from "api2/users";
import { useNavigate } from "react-router-dom";

function CompanyActions({ row, forStaff, userId }) {
  const { t } = useTranslation(["company", "navigation"]);
  const {
    dataActions: { updateRow },
  } = useTable();
  const lockModalControl = useModal();
  const navigate = useNavigate();

  function toggleFavourite(companyId) {
    return userAPI.consultToggleFavourite(companyId).then((response) => {
      updateRow({ ...row, ...response.data });
    });
  }

  const onLockModalOpen = () => {
    lockModalControl.open(row);
  };

  const onLockModalClose = (company) => {
    lockModalControl.close();
    if (company && company.id) {
      updateRow(company);
    }
  };

  function settingsRedirection() {
    navigate(`/companies/${row.id}/settings`, { replace: true });
  }

  return (
    <>
      {forStaff && (
        <TooltipActionButton
          text={t("toggleFavourite")}
          icon="fas fa-heart"
          onClick={() => toggleFavourite(row.id)}
          variant={row.is_favourite ? "warning" : "secondary"}
        />
      )}
      <TooltipActionButton text={t("navigation:settings")} icon="fa fa-cog" onClick={() => settingsRedirection()} />
      {forStaff && (
        <>
          <TooltipActionButton
            text={t("lockAccounting")}
            icon="fas fa-lock"
            onClick={onLockModalOpen}
            variant={row.lock_accounting_date ? "danger" : "secondary"}
          />

          {lockModalControl.show && (
            <LockAccountingModal company={lockModalControl.data} handleClose={onLockModalClose} />
          )}
        </>
      )}
    </>
  );
}

export default CompanyActions;
