import * as yup from "yup";
import { toast } from "react-toastify";
import { disallowedAssetAccountsForDepreciationList, getDefaultAccountsBasedOnAssetAccount } from "utils/assets";
import { roundMoney } from "utils/money";

const yupForAssetForm = (assetIsEditable, partialUpdateFor) =>
  yup.object().shape({
    depreciation_disallowed: yup.bool().nullable().notRequired(),
    is_initially_depreciated_fully: yup.bool().nullable().notRequired(),
    description: yup.string().required(),
    cost_account: yup
      .object()
      .nullable()
      .when(["depreciation_disallowed", "is_initially_depreciated_fully"], {
        is: (depreciation_disallowed, is_initially_depreciated_fully) => {
          return !(depreciation_disallowed || is_initially_depreciated_fully);
        },
        then: (schema) => schema.required(),
      }),
    asset_account: yup.object().nullable().required(),
    depreciation_account: yup
      .object()
      .nullable()
      .when("depreciation_disallowed", ([depreciation_disallowed], schema) => {
        return !depreciation_disallowed ? schema.required() : schema;
      }),
    acquisition_date: yup.date().nullable().required(),
    acquisition_value: yup.number().nullable().required(),
    residual_value: yup.number().nullable().required(),
    global_depreciation_months: yup
      .number()
      .nullable()
      .max(2400)
      .when("depreciation_disallowed", ([depreciation_disallowed], schema) => {
        return !depreciation_disallowed ? schema.required() : schema;
      }),
    global_depreciation_start: yup
      .date()
      .nullable()
      .when(["depreciation_disallowed"], {
        is: (depreciation_disallowed) => {
          return !(depreciation_disallowed || partialUpdateFor === "accounts");
        },
        then: (schema) => schema.required(),
      }),
    outside_depreciated_months: assetIsEditable
      ? yup.number().nullable().required().max(2400)
      : yup.number().nullable().notRequired(),
    outside_depreciated_amount: assetIsEditable
      ? yup.number().nullable().required()
      : yup.number().nullable().notRequired(),
  });

function onAssetAccountChange(account, setFieldValue, values, accountById, t) {
  if (!disallowedAssetAccountsForDepreciationList.includes(account.number)) {
    toast.warning(t("msgOnAssetAccountSwitch"));
    const defaultAccountsBasedOnAssetAccount = getDefaultAccountsBasedOnAssetAccount(account.number);
    setFieldValue("depreciation_disallowed", false);
    setFieldValue("asset_account", account);
    setFieldValue("depreciation_account", accountById[defaultAccountsBasedOnAssetAccount.deprecation]);
    setFieldValue("cost_account", accountById[defaultAccountsBasedOnAssetAccount.cost]);
  } else {
    toast.warning(t("msgOnAssetAccountSwitchWithoutDeprecation"));
    setFieldValue("residual_value", values.acquisition_value);
    setFieldValue("depreciation_disallowed", true);
    setFieldValue("project_for_depreciation", null);
    setFieldValue("cost_center_for_depreciation", null);
    setFieldValue("cost_account", null);
    setFieldValue("depreciation_account", null);
    setFieldValue("is_initially_depreciated_fully", false);
    setFieldValue("outside_depreciated_months", 0);
    setFieldValue("outside_depreciated_amount", 0);
  }
}
const updateDeprecationEndDate = (dateStart, depreciationMonths, setFieldValue) => {
  if (depreciationMonths && dateStart) {
    const endDateOfDeprecation = new Date(dateStart);
    const endMonth = parseInt(endDateOfDeprecation.getMonth(), 10) + parseInt(depreciationMonths, 10) - 1;
    endDateOfDeprecation.setMonth(endMonth);
    setFieldValue("depreciation_end", endDateOfDeprecation);
  }
};

const getCountOfAutoVerToCreate = (values) => {
  if (values.depreciation_disallowed || values.is_initially_depreciated_fully) {
    return 0;
  }
  let months;
  const _bflowStartOfDeprecation = new Date(values.global_depreciation_start);
  const bflowStartOfDeprecation = new Date(
    _bflowStartOfDeprecation.getFullYear(),
    _bflowStartOfDeprecation.getMonth(),
    1
  );
  const startMonth =
    parseInt(bflowStartOfDeprecation.getMonth(), 10) + parseInt(values.outside_depreciated_months, 10) - 1;
  bflowStartOfDeprecation.setMonth(startMonth);
  const today = new Date();
  months = (today.getFullYear() - bflowStartOfDeprecation.getFullYear()) * 12;
  months -= bflowStartOfDeprecation.getMonth();
  months += today.getMonth();
  if (months < 0) {
    months = 0;
  } else if (months > values.global_depreciation_months - values.outside_depreciated_months) {
    months = values.global_depreciation_months - values.outside_depreciated_months;
  }
  return months;
};

const onDepreciationStartChange = (selected, setFieldValue, values) => {
  setFieldValue("global_depreciation_start", selected);
  updateDeprecationEndDate(selected, values.global_depreciation_months, setFieldValue);
};
const onDepreciationMonthsChange = (selected, setFieldValue, values) => {
  if (selected < 5000) {
    setFieldValue("global_depreciation_months", selected);
    updateDeprecationEndDate(values.global_depreciation_start, selected, setFieldValue);
  }
};

const depreciationAmountTotal = (values) => {
  if (
    values.asset_account?.number &&
    disallowedAssetAccountsForDepreciationList.includes(values.asset_account.number)
  ) {
    return 0;
  }
  if (values.acquisition_value && values.residual_value) {
    return values.acquisition_value - values.residual_value;
  }
  return values.acquisition_value ? values.acquisition_value : 0;
};
const depreciationAmountPerMonth = (values, isFullyDepreciatedChecked) => {
  if (values.id && values.status === "active_ongoing") {
    return values.estimated_monthly_depreciation_amount;
  }
  if (
    !depreciationAmountTotal(values) ||
    !values.global_depreciation_months ||
    isFullyDepreciatedChecked ||
    (values.outside_depreciated_months && values.global_depreciation_months - values.outside_depreciated_months <= 0)
  ) {
    return 0;
  }
  const depPerMonth =
    (depreciationAmountTotal(values) - values.outside_depreciated_amount) /
    (values.global_depreciation_months - values.outside_depreciated_months);
  if (depPerMonth < 1) {
    return 0;
  }
  return roundMoney(depPerMonth, 0);
};
const outputBalance = (values, output_balance) => {
  if (output_balance) {
    return output_balance;
  }
  if (!values.acquisition_value) {
    return 0;
  }
  return values.acquisition_value - values.outside_depreciated_amount;
};

const onIsFullyDepreciatedClicked = (setFieldValue, values) => {
  if (!values.is_initially_depreciated_fully) {
    setFieldValue("outside_depreciated_amount", depreciationAmountTotal(values));
    setFieldValue("outside_depreciated_months", values.global_depreciation_months);
    setFieldValue("cost_center_for_depreciation", null);
    setFieldValue("project_for_depreciation", null);
  }
  setFieldValue("is_initially_depreciated_fully", !values.is_initially_depreciated_fully);
};

export {
  yupForAssetForm,
  onAssetAccountChange,
  onDepreciationStartChange,
  onDepreciationMonthsChange,
  depreciationAmountTotal,
  depreciationAmountPerMonth,
  outputBalance,
  onIsFullyDepreciatedClicked,
  getCountOfAutoVerToCreate,
};
