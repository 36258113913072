import React, { useCallback, useContext, useEffect, useRef, useState } from "react";
import { Outlet, useLocation, useParams } from "react-router-dom";
import { Alert, Button, ButtonGroup, Card, Form, InputGroup } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { differenceInMonths, isAfter, isBefore } from "date-fns";
import axios from "axios";
import qs from "qs";
import * as selectAPI from "api2/selects";
import useAsync from "hooks/useAsync";
import { RocketLoader } from "components/ui/loaders";
import { formatDate, parseDate } from "utils/date";
import { BSelect } from "components/formik/pickers";
import * as companyAPI from "api2/companies";
import { toast } from "react-toastify";
import { roundMoney } from "utils/money";
import BudgetViewSwitcher from "pages/companies/settings/BudgetPage/BudgetViewSwitcher";
import { FileUrlModal } from "components/modals";
import useModal from "hooks/useModal";
import UnsavedWarn from "components/ui/UnsavedWarn";
import { CompanySettingStateContext } from "state/providers/CompanySettingProvider";
import NumberFormat from "react-number-format";
import { getPreviousFinancialYearResults } from "./utils";
import "./BudgetLayout.scss";
import { HintButton } from "../../ui/buttons";
import { SearchFilter } from "../../filters";

function getClosestFinancialYear(financialYears) {
  const today = new Date();
  let dateStart;
  let dateEnd;
  for (let i = 0; i < financialYears.length; i++) {
    dateStart = parseDate(financialYears[i].date_start);
    dateEnd = parseDate(financialYears[i].date_end);
    if (isBefore(dateStart, today) && isAfter(dateEnd, today)) {
      return financialYears[i];
    }
  }
  return financialYears[financialYears.length - 1];
}

function FinancialYearFilter({ label, name = "financial_year", options, companyId, defaultValue, onFilter, ...props }) {
  return (
    <Form.Group>
      <Form.Label>{label}</Form.Label>
      <BSelect
        defaultValue={options.filter((option) => option.value === defaultValue)}
        options={options}
        onChange={(selected) => onFilter(selected)}
        isClearable={false}
        {...props}
      />
    </Form.Group>
  );
}

const LAST_BUDGET = "lb";
const LAST_RESULT = "lr";

function NominalAmountInput({ nominalAmount, setNominalAmount, setEditMode }) {
  const { t } = useTranslation("common");
  return (
    <Form.Group>
      <Form.Label>
        {t("nominalAmount")} <HintButton hint={t("reports:budgetNominalHint")} />
      </Form.Label>
      <InputGroup>
        <NumberFormat
          thousandSeparator={" "}
          decimalSeparator=","
          autoComplete="off"
          decimalScale={0}
          value={nominalAmount}
          style={{ width: 90 }}
          isAllowed={({ floatValue }) => (floatValue ? floatValue >= 0 : true)}
          onValueChange={({ floatValue }) => {
            setNominalAmount(floatValue || 0);
            setEditMode(true);
          }}
          className="money-input form-control"
          fixedDecimalScale
        />
        <InputGroup.Append>
          <InputGroup.Text>SEK</InputGroup.Text>
        </InputGroup.Append>
      </InputGroup>
    </Form.Group>
  );
}

function BudgetModificator({ onBudgetModify, disabled, copyBudgetOption, setCopyBudgetOption }) {
  const { t } = useTranslation("reports");
  const [loading, setLoading] = useState(false);
  const onApply = async (percentValue) => {
    setLoading(true);
    await onBudgetModify(percentValue);
    setLoading(false);
  };

  const onCopyFrom = (option) => {
    if (copyBudgetOption === option) {
      setCopyBudgetOption("");
    } else {
      setCopyBudgetOption(option);
    }
  };

  return (
    <>
      <div className="form-group mr-1">
        <label className="form-label">{t("copyLastYear")}</label>
        <div>
          <ButtonGroup>
            <Button
              variant="outline-primary"
              active={copyBudgetOption === LAST_BUDGET}
              onClick={() => onCopyFrom(LAST_BUDGET)}
            >
              {t("budget")}
            </Button>
            <Button
              variant="outline-primary"
              active={copyBudgetOption === LAST_RESULT}
              onClick={() => onCopyFrom(LAST_RESULT)}
            >
              {t("dashboard.result")}
            </Button>
          </ButtonGroup>
        </div>
      </div>
      <IncreaseByButton disabled={loading || !copyBudgetOption || disabled} onIncrease={onApply} />
    </>
  );
}

function IncreaseByButton({ onIncrease, disabled }) {
  const [value, setValue] = useState(0);
  const { t } = useTranslation("reports");
  const increase = () => {
    onIncrease(value);
  };
  return (
    <div className="form-group">
      <label className="form-label">&nbsp;</label>
      <div className="d-flex">
        <InputGroup>
          <input
            type="number"
            className="form-control text-right"
            style={{ width: 80 }}
            value={value}
            onChange={(e) => setValue(parseFloat(e.target.value || 0))}
          />
          <InputGroup.Append>
            <InputGroup.Text>%</InputGroup.Text>
          </InputGroup.Append>
        </InputGroup>
        <Button type="button" variant="outline-primary" className="ml-1" onClick={increase} disabled={disabled}>
          {t("common:actions.apply")}
        </Button>
      </div>
    </div>
  );
}

function PL12ReportPDFButton({ companyId, financialYear }) {
  const plPDFModal = useModal();
  const { t } = useTranslation("reports");

  const openProfitLossPDFModal = () => {
    const params = qs.stringify(
      {
        date_from: formatDate(financialYear.date_start),
        date_to: formatDate(financialYear.date_end),
        financial_year: financialYear.id,
        rounding: "2",
        costcenter_ids: [],
        project_ids: [],
        with_budget: true,
      },
      { indices: false }
    );
    plPDFModal.open(`/reports/budget-year/pdf/?${params}`);
  };

  return (
    <>
      <div className="form-group">
        <label className="form-label"> </label>
        <div>
          <Button variant="outline-primary" onClick={openProfitLossPDFModal}>
            {t("reportPDF")}
          </Button>
        </div>
      </div>
      {plPDFModal.show && (
        <FileUrlModal companyId={companyId} fileUrl={plPDFModal.data} handleClose={plPDFModal.close} />
      )}
    </>
  );
}

function PLStandardReportPDFButton({ companyId, financialYear }) {
  const plPDFModal = useModal();
  const { t } = useTranslation("reports");

  const openProfitLossPDFModal = () => {
    const params = qs.stringify(
      {
        date_from: formatDate(financialYear.date_start),
        date_to: formatDate(financialYear.date_end),
        financial_year: financialYear.id,
        rounding: "3",
        costcenter_ids: [],
        project_ids: [],
        with_budget: true,
      },
      { indices: false }
    );
    plPDFModal.open(`/reports/budget-standard/pdf/?${params}`);
  };

  return (
    <>
      <div className="form-group">
        <label className="form-label"> </label>
        <div>
          <Button variant="outline-primary" onClick={openProfitLossPDFModal}>
            {t("reportPDF")}
          </Button>
        </div>
      </div>
      {plPDFModal.show && (
        <FileUrlModal fileUrl={plPDFModal.data} companyId={companyId} handleClose={plPDFModal.close} />
      )}
    </>
  );
}

function BudgetLayout({ view, companyId, financialYears, initialBudgetNominal }) {
  const { t } = useTranslation("reports");
  const serverBudget = useRef({ current: {}, previous: {} });
  const [saving, setSaving] = useState(false);
  const [refresh, setRefresh] = useState(1);
  const [editMode, setEditMode] = useState(false);
  const [nominalAmount, setNominalAmount] = useState(initialBudgetNominal);
  const [copyBudgetOption, setCopyBudgetOption] = useState("");
  const [financialYear, setFinancialYear] = useState(() => getClosestFinancialYear(financialYears));
  const [showAllAccounts, setShowAllAccounts] = useState(false);
  const [accountTerm, setAccountTerm] = useState("");
  const [budget, setBudget] = useState({
    current: {},
    previous: {},
  });

  useEffect(() => {
    const signal = axios.CancelToken.source();

    function getBudget() {
      companyAPI.budgets
        .details(
          companyId,
          { financial_year: financialYear.id },
          {
            date_from: financialYear.date_start,
            date_to: financialYear.date_end,
          },
          {
            cancelToken: signal.token,
            paramsSerializer: (params) => {
              return qs.stringify(params, { indices: false });
            },
          }
        )
        .then((response) => {
          serverBudget.current = {
            current: response.data.current_budgets,
            previous: response.data.previous_budgets,
          };
          setBudget({ current: response.data.current_budgets, previous: response.data.previous_budgets });
        })
        .catch((error) => {
          if (!axios.isCancel(error)) {
            setBudget({
              current: {},
              previous: {},
            });
          }
        });
    }

    getBudget();

    return () => {
      signal.cancel("aborted");
    };
  }, [companyId, financialYear.id, financialYear.date_start, financialYear.date_end, view, refresh]);

  const saveBudget = () => {
    let data;
    setSaving(true);
    if (view === "monthly") {
      const numberOfMonths =
        differenceInMonths(parseDate(financialYear.date_end), parseDate(financialYear.date_start)) + 1;
      data = Object.keys(budget.current).map((accountNumber) => {
        const { sum } = budget.current[accountNumber];
        const perMonth = roundMoney(sum / numberOfMonths, 2);
        const amounts = Array(numberOfMonths).fill(perMonth);
        const totalAmounts = amounts.reduce((total, i) => total + i);
        const diff = roundMoney(sum - totalAmounts, 2);
        if (diff !== 0) {
          amounts[amounts.length - 1] = roundMoney(perMonth + diff, 2);
        }
        return { account: accountNumber, sum: budget.current[accountNumber].sum, monthly_amounts: amounts };
      });
    } else {
      data = Object.keys(budget.current).map((accountNumber) => {
        return {
          account: accountNumber,
          sum: roundMoney(budget.current[accountNumber].sum, 2),
          monthly_amounts: budget.current[accountNumber].months.map((val) => roundMoney(val, 2)),
        };
      });
    }
    companyAPI.budgets
      .update(companyId, { budget_data: data, financial_year: financialYear.id, nominal_amount: nominalAmount })
      .then(() => {
        toast.success(t("msg:saved"));
        setEditMode(false);
        setRefresh((r) => r + 1);
      })
      .catch((error) => {
        if (error.data.__all__) {
          toast.error(error.data.__all__);
        }
      })
      .finally(() => {
        setSaving(false);
      });
  };

  const clearCurrentBudget = () => {
    const newCurrent = {};
    setEditMode(true);
    setBudget((_budget) => {
      Object.keys(_budget.current).forEach((accountNumber) => {
        newCurrent[accountNumber] = { sum: 0, months: Array(_budget.current[accountNumber].length).fill(0) };
      });
      return { previous: _budget.previous, current: newCurrent };
    });
  };

  const context = {
    companyId,
    budget,
    setBudget,
    editMode,
    setEditMode,
    financialYear,
    showAllAccounts,
    accountTerm,
  };

  const onBudgetModify = async (percentValue) => {
    const numberOfMonths =
      differenceInMonths(parseDate(financialYear.date_end), parseDate(financialYear.date_start)) + 1;
    setEditMode(true);
    if (copyBudgetOption === LAST_BUDGET) {
      const newCurrent = {};
      Object.keys(serverBudget.current.previous).forEach((accountNumber) => {
        const serverValue = serverBudget.current.previous[accountNumber].sum || 0;
        const sum = serverValue + serverValue * (percentValue / 100);
        const months = serverBudget.current.previous[accountNumber].months
          .map((monthValue) => {
            return monthValue + monthValue * (percentValue / 100);
          })
          .slice(-numberOfMonths); // there might be more/fewer months than in previous financial year
        newCurrent[accountNumber] = { sum, months };
      });
      setBudget((_budget) => ({ previous: _budget.previous, current: newCurrent }));
    } else if (copyBudgetOption === LAST_RESULT) {
      const newCurrent = {};
      const previousResults = await getPreviousFinancialYearResults(companyId, financialYear, financialYears);
      Object.keys(previousResults).forEach((accountNumber) => {
        let months = previousResults[accountNumber]
          .map((monthValue) => {
            return monthValue + monthValue * (percentValue / 100);
          })
          .slice(-numberOfMonths);
        const sum = months.reduce((total, value) => total + value, 0);
        const diffMonths = numberOfMonths - months.length;
        if (diffMonths > 0) {
          // if previously was fewer months than in current
          months = [...months, ...Array.from({ length: diffMonths }).fill(0)];
        }
        newCurrent[accountNumber] = { sum, months };
      });
      setBudget((_budget) => ({ previous: _budget.previous, current: newCurrent }));
    }
  };

  const revertBudget = () => {
    setEditMode(false);
    setNominalAmount(initialBudgetNominal);
    setBudget(serverBudget.current);
    setCopyBudgetOption("");
  };

  const filterAccounts = ({ term }) => {
    setAccountTerm(term);
  };

  return (
    <>
      <UnsavedWarn isUnsaved={editMode} />
      <Card>
        <Card.Body>
          <BudgetViewSwitcher view={view} disabled={editMode} />
          <div className="table-filters-group">
            <section className="table-filters-left">
              <FinancialYearFilter
                isDisabled={editMode}
                defaultValue={financialYear.id}
                options={financialYears}
                onFilter={setFinancialYear}
                label={t("common:financialYear")}
              />
              <BudgetModificator
                onBudgetModify={onBudgetModify}
                disabled={editMode}
                copyBudgetOption={copyBudgetOption}
                setCopyBudgetOption={setCopyBudgetOption}
              />
              <NominalAmountInput
                nominalAmount={nominalAmount}
                setNominalAmount={setNominalAmount}
                setEditMode={setEditMode}
              />
            </section>
            <section className="table-filters-right">
              <fieldset disabled={editMode}>
                {view === "yearly" ? (
                  <PL12ReportPDFButton financialYear={financialYear} companyId={companyId} />
                ) : (
                  <PLStandardReportPDFButton financialYear={financialYear} companyId={companyId} />
                )}
              </fieldset>
            </section>
          </div>
        </Card.Body>
      </Card>
      <Card className="reports profit-loss">
        <Card.Body className="pt-2">
          <div className="mb-2">
            <SearchFilter onFilter={filterAccounts} />
            <Button
              variant="outline-primary"
              size="sm"
              className="ml-1"
              active={!showAllAccounts}
              onClick={() => setShowAllAccounts(false)}
            >
              {t("usedAccounts")}
            </Button>
            <Button
              variant="outline-primary"
              className="ml-1"
              size="sm"
              active={showAllAccounts}
              onClick={() => setShowAllAccounts(true)}
            >
              {t("common:actions.showAll")}
            </Button>
            <Button variant="outline-secondary" className="float-right" size="sm" onClick={clearCurrentBudget}>
              {t("common:actions.clearAll")}
            </Button>
          </div>
          <Outlet context={context} />
        </Card.Body>
        {editMode && (
          <Card.Body className="editMode">
            <Button variant="link" size="lg" onClick={revertBudget}>
              {t("common:actions.cancel")}
            </Button>
            <Button variant="primary" size="lg" onClick={saveBudget} disabled={saving}>
              <i className="fas fa-save mr-1" /> {t("common:actions.save")}
            </Button>
          </Card.Body>
        )}
      </Card>
    </>
  );
}

function BudgetLayoutWrapper() {
  const { companyId } = useParams();
  const { t } = useTranslation("company");
  const { info } = useContext(CompanySettingStateContext);
  const path = useLocation().pathname;
  const view = path.match("year") ? "yearly" : "monthly";
  const dataSource = useCallback(
    (cancelToken) => selectAPI.companyFinancialYears(companyId, {}, { cancelToken }),
    [companyId]
  );
  const [{ data: financialYears, loading }] = useAsync(dataSource, []);
  if (loading) {
    return <RocketLoader />;
  }
  if (!financialYears.length) {
    return <Alert variant="warning">{t("minOneFYearWarn")}</Alert>;
  }
  if (!info.budget_enabled) {
    return <Alert variant="warning">{t("moduleNotEnabledWarn")}</Alert>;
  }
  return (
    <BudgetLayout
      view={view}
      companyId={companyId}
      financialYears={financialYears}
      initialBudgetNominal={info.budget_nominal}
    />
  );
}

export default BudgetLayoutWrapper;
