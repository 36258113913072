import React, { useCallback, useState } from "react";
import { Button, Card, Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import * as companyAPI from "api2/companies";
import { confirmExecute } from "components/modals/ConfirmModal";
import useAsync from "hooks/useAsync";
import { SelectSimpleFilter } from "components/filters";

function SyncButton({ isSyncing, title, onClick }) {
  const { t } = useTranslation("company");

  return (
    <Button type="button" variant="outline-warning" onClick={onClick} disabled={isSyncing}>
      {isSyncing ? (
        <span>
          <i className="fas fa-spinner fa-pulse mr-1" /> {t("common:actions.processing")}...
        </span>
      ) : (
        <span>{t(title)}</span>
      )}
    </Button>
  );
}

function GoogleSyncWidget({ companyId, normalLink, annualLink }) {
  const { t } = useTranslation("company");

  const dataSource = useCallback(
    (cancelToken) =>
      companyAPI.financialYears.list(companyId, {}, { cancelToken }).then((response) =>
        response.data.map((fyear) => ({
          value: fyear.id,
          label: `${fyear.date_start} - ${fyear.date_end}`,
        }))
      ),
    [companyId]
  );
  const [{ data: financialYears }] = useAsync(dataSource, []);

  const [data, setData] = useState({
    link1: normalLink,
    link2: annualLink,
    link1Loading: false,
    link2Loading: false,
    fyear1: null,
    fyear2: null,
  });

  const normalSync = async () => {
    const answer = await confirmExecute("");
    if (answer) {
      setData({ ...data, link1Loading: true });
      await companyAPI.annualReports
        .googleSync(companyId)
        .then((response) => {
          toast.success(t("msg:generated"));
          window.open(response.data.link, "_blank");
          setData({ ...data, link1: response.data.link, link1Loading: false });
        })
        .catch((error) => {
          if (error.data.__all__) {
            toast.error(error.data.__all__);
          }
          setData({ ...data, link1Loading: false });
        });
    }
  };

  const annual2Sync = async () => {
    if (!data.fyear1) {
      toast.error(t("company:errors.firstFYearMustSelect"));
      return;
    }
    if (data.fyear1 && data.fyear2 && data.fyear1 === data.fyear2) {
      toast.error(t("company:errors.differentFYearRequired"));
      return;
    }
    const answer = await confirmExecute(t("company:confirm.generateAnnualReport"));
    if (answer) {
      setData({ ...data, link2Loading: true });
      const payload = [data.fyear1];
      if (data.fyear2) {
        payload.push(data.fyear2);
      }
      await companyAPI.annualReports
        .googleAnnualSync(companyId, { financial_years: payload })
        .then((response) => {
          toast.success(t("msg:generated"));
          window.open(response.data.link, "_blank");
          setData({ ...data, link2: response.data.link, link2Loading: false });
        })
        .catch((error) => {
          if (error.data.__all__) {
            toast.error(error.data.__all__);
          }
          setData({ ...data, link2Loading: false });
        });
    }
  };

  return (
    <Row>
      <Col xl={6}>
        <Card className="card-border">
          <Card.Header className="border-success">
            <Card.Title>{t("normalLink")}</Card.Title>
          </Card.Header>
          <Card.Body className="pt-0">
            <div className="form-group">
              {data.link1 ? (
                <a href={data.link1} target="_blank" rel="noopener noreferrer">
                  Open Sheet
                </a>
              ) : (
                <div>Not synced yet</div>
              )}
            </div>
            <SyncButton title="normalSync" onClick={normalSync} isSyncing={data.link1Loading} />
          </Card.Body>
        </Card>
      </Col>
      <Col xl={6}>
        <Card className="card-border">
          <Card.Header className="border-success">
            <Card.Title>{t("annualReport2")}</Card.Title>
          </Card.Header>
          <Card.Body className="pt-0">
            <div className="form-group">
              {data.link2 ? (
                <a href={data.link2} target="_blank" rel="noopener noreferrer">
                  Open Sheet
                </a>
              ) : (
                <div>Not synced yet</div>
              )}
            </div>
            <Row>
              <Col>
                <SelectSimpleFilter
                  options={financialYears}
                  name="fyear1"
                  label={`${t("common:financialYear")} 1`}
                  onFilter={(filter) => {
                    setData((data2) => ({ ...data2, fyear1: filter.fyear1 }));
                  }}
                  defaultValue={null}
                  isClearable={false}
                />
              </Col>
              <Col>
                <SelectSimpleFilter
                  options={financialYears}
                  name="fyear2"
                  label={`${t("common:financialYear")} 2`}
                  onFilter={(filter) => {
                    setData((data2) => ({ ...data2, fyear2: filter.fyear2 }));
                  }}
                  defaultValue={null}
                  isClearable={false}
                />
              </Col>
            </Row>
            <SyncButton title="annualReportSync" onClick={annual2Sync} isSyncing={data.link2Loading} />
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
}

export default GoogleSyncWidget;
