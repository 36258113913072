import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "react-bootstrap";
import { toast } from "react-toastify";
import { isEmpty } from "lodash";

import * as reportAPI from "api2/reports";
import useTable from "components/tables/btable/useTable";
import { SelectionContext } from "state/providers/SelectionProvider";
import { formatDuration } from "utils/date";

function ApproveAction({ companyId, tableId = "timeReports" }) {
  const { t } = useTranslation("common");
  const { dataActions } = useTable();
  const { selection, clearSelection } = useContext(SelectionContext);

  const count = isEmpty(selection) ? 0 : Object.keys(selection[tableId]).length;
  const timeSpentSeconds = !isEmpty(selection)
    ? Object.values(selection[tableId]).reduce((total, item) => total + item.seconds, 0)
    : 0;

  const approve = () => {
    reportAPI.timeReport.approveMany(companyId, Object.keys(selection[tableId])).then((response) => {
      clearSelection();
      dataActions.reload();
      toast.success(t("msg:approved"), { autoClose: 2000 });
    });
  };

  return (
    <Button variant="outline-draft" size="lg" className="mt-3" disabled={count <= 0} onClick={approve}>
      {t("common:actions:approveSelected")}: {count} {timeSpentSeconds > 0 && `(${formatDuration(timeSpentSeconds)})`}
    </Button>
  );
}

export default ApproveAction;
