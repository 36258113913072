import React from "react";
import { useTranslation } from "react-i18next";
import { Link, useResolvedPath } from "react-router-dom";
import useTable from "components/tables/btable/useTable";
import { CheckboxFilter, MonthDateFilter, SearchFilter, SelectSimpleFilter } from "components/filters";
import * as options from "api/options";

function CompanyArchiveFilters({ companyId }) {
  const archiveCategories = options.consultArchiveCategories.asList();
  const { filters, setFilters } = useTable();
  const url = useResolvedPath("").pathname;
  const { t } = useTranslation("common");
  return (
    <>
      <section className="table-filters-left">
        <SearchFilter onFilter={setFilters} defaultValue={filters.term} />
        <SelectSimpleFilter
          name="category"
          options={archiveCategories}
          isClearable
          defaultValue={filters.category}
          onFilter={setFilters}
          placeholder={`${t("common:actions.select")} ${t("common:category")}`}
        />
        <MonthDateFilter
          name="month"
          placeholder={`${t("common:actions.select")} ${t("common:dates.month")}`}
          defaultValue={filters.month}
          isClearable
          onChange={(d) => setFilters({ ...filters, month: d })}
        />
        <CheckboxFilter
          label={t("common:archived")}
          name="is_archived"
          defaultChecked={!!filters.is_archived}
          defaultValue={!!filters.is_archived}
          onFilter={(selected) => {
            setFilters({ ...filters, ...selected });
          }}
        />
      </section>
      <section className="table-filters-right">
        <Link to={`${url}/form`} className="btn btn-first">
          <i className="fas fa-plus" /> {t("common:actions.create")}
        </Link>
      </section>
    </>
  );
}

export default CompanyArchiveFilters;
