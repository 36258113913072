import React from "react";
import { Alert, Col, Row } from "react-bootstrap";
import { Form, Formik } from "formik";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import { toast } from "react-toastify";

import { ConfirmButton, RemoveButton, SubmitButton } from "components/ui/buttons";
import { AllError, FormGroup } from "components/formik";
import * as authAPI from "api2/auth";
import * as userAPI from "api2/users";
import * as options from "api/options";
import { useAuthState } from "hooks/useAuth";
import UserConnectedCompanies from "./UserConnectedCompanies";
import "./user-form.scss";

function UserRolesSelect({ userRoles, requestUserRole }) {
  const { t } = useTranslation("common");
  let filteredRoles;
  switch (requestUserRole) {
    case "consult":
      filteredRoles = userRoles;
      break;
    case "admin":
      filteredRoles = userRoles;
      break;
    default:
      filteredRoles = [];
  }
  return <FormGroup.SimpleSelect options={filteredRoles} name="agency_role" label={t("common:role")} />;
}

function UserForm({ user, agencyId, successCallback, deleteCallback }) {
  const { t } = useTranslation("common");
  const { user: requestUser } = useAuthState();
  const userRoles = options.userRoles.asList();
  let requestUserRole;
  if (requestUser.is_superuser) {
    requestUserRole = "admin";
  } else {
    requestUserRole = requestUser.agencies.find((agency) => agency.agency === parseInt(agencyId, 10)).role;
  }

  const formikProps = {
    initialValues: {
      ...user,
      agency_role: options.userRoles.getOption(user.agency_role),
    },
    validationSchema: yup.object().shape({
      first_name: yup.string().required(),
      last_name: yup.string().required(),
      personal_number: yup
        .string()
        .nullable()
        .trim()
        .required()
        .test("isNum", t("onlyDigitsAllowed"), (val) => !Number.isNaN(+val))
        .test("len", t("requireDigits", { number: 12 }), (val) => val && val.toString().length === 12),
      email: yup.string().email(t("common:errors.invalid.email")).required(),
    }),
    onSubmit: async (values, { setErrors, resetForm }) => {
      await userAPI.agencies
        .userSave(agencyId, {
          id: user.id,
          is_active: user.is_active,
          email: values.email.toLowerCase(),
          first_name: values.first_name,
          last_name: values.last_name,
          personal_number: values.personal_number,
          agency_role: values.agency_role.value,
        })
        .then((response) => {
          toast.success(t("msg:saved"), { autoClose: 2000 });
          if (!user.id) {
            resetForm();
          }
          if (successCallback) {
            successCallback({
              ...response.data,
              name: `${response.data.first_name} ${response.data.last_name}`,
            });
          }
        })
        .catch((error) => {
          toast.error(t("msg:fixErrors"));
          setErrors(error.data);
        });
    },
  };

  const onRemoveFromAgency = async () => {
    return userAPI.agencies
      .disconnect(user.id, agencyId)
      .then(() => {
        toast.success(t("msg:updated"));
        if (deleteCallback) {
          deleteCallback();
        }
      })
      .catch((error) => {
        if (error.data.__all__) {
          toast.error(error.data.__all__, { autoClose: 10000 });
        }
      });
  };

  const startResetPassword = async () => {
    return authAPI
      .resetPasswordStartByConsult(user.id, user.email)
      .then((response) => {
        toast.success(response.data.message);
      })
      .catch(() => {
        toast.error(t("msg:canNotExecuteAction"));
      });
  };

  return (
    <Formik {...formikProps}>
      {({ values, isSubmitting, errors }) => {
        return (
          <Form noValidate>
            <Row>
              <Col lg={9}>
                <Row>
                  <Col lg={4}>
                    <FormGroup.Input
                      label={t("common:contact.personalNumber")}
                      name="personal_number"
                      required
                      placeholder="YYYYMMDDXXXX"
                    />
                  </Col>
                  <Col lg={4}>
                    <FormGroup.Input label={t("common:contact.emailLong")} name="email" required />
                  </Col>
                </Row>
                <Row>
                  <Col lg={4}>
                    <FormGroup.Input label={t("common:contact.firstName")} name="first_name" required />
                  </Col>
                  <Col lg={4}>
                    <FormGroup.Input label={t("common:contact.lastName")} name="last_name" required />
                  </Col>
                  <Col lg={4}>
                    <UserRolesSelect userRoles={userRoles} requestUserRole={requestUserRole} />
                  </Col>
                </Row>
              </Col>
              {user.id && (
                <Col lg={3} className="position-relative">
                  <UserConnectedCompanies user={user} />
                </Col>
              )}
            </Row>
            {values.agency_role.value === "consult" && <Alert variant="warning">{t("others:warnUserConsult")}</Alert>}
            <AllError errors={errors} />
            {!user.is_active && <Alert variant="warning">{t("others:warnUserInactive")}</Alert>}
            <hr />
            <SubmitButton isSubmitting={isSubmitting} />
            {user.id && user.is_active && (
              <>
                <RemoveButton
                  variant="danger"
                  label={t("others:actions.disconnectAgency")}
                  confirmMessage={t("others:confirm.disconnectAgency", {
                    agencyName: "",
                  })}
                  disabled={isSubmitting}
                  className="float-right"
                  onClick={onRemoveFromAgency}
                />

                <ConfirmButton
                  variant="second"
                  confirmMessage={t("others:confirm.userResetPwd", {
                    email: user.email,
                  })}
                  label={t("others:userSendResetPwd")}
                  className="ml-2"
                  icon="fas fa-key"
                  onClick={startResetPassword}
                />
              </>
            )}
          </Form>
        );
      }}
    </Formik>
  );
}

export default UserForm;
