// Function to convert a boolean value to a string ("1" or "0")
export const getBooleanValueAsString = (value) => {
  if (value === null) return "";
  return value ? "1" : "0";
};

// Function to convert a string ("1" or "0") back to a boolean value or null
export const convertToBooleanOrNull = (value) => {
  if (value === "1") return true;
  if (value === "0") return false;
  return null;
};
