import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { createRoot } from "react-dom/client";
import { Button, Modal } from "react-bootstrap";
import { CheckboxFilter } from "components/filters";
import { DraggableModalDialog } from "components/modals/DraggableModalDialog";

function ConfirmShareCustomerModal({ description, giveAnswer, close }) {
  const { t } = useTranslation("common");
  const [notify, setNotify] = useState(false);
  const toggleCheck = () => {
    setNotify(!notify);
  };
  return (
    <Modal
      animation={false}
      scrollable
      show
      onHide={() => giveAnswer(null)}
      dialogClassName="confirmation-modal"
      dialogAs={DraggableModalDialog}
    >
      <Modal.Header closeButton>
        <Modal.Title className="m-0">{t("actions.areYouSure")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {description}
        <div className="mt-2 ml-2">
          <CheckboxFilter onFilter={toggleCheck} label={t("common:actions.notifyCustomer")} defaultChecked={notify} />
        </div>
      </Modal.Body>
      <Modal.Footer className="space">
        <Button type="button" variant="secondary" onClick={() => giveAnswer(false, false)}>
          {t("actions.cancel")}
        </Button>
        <Button type="button" variant="danger" onClick={() => giveAnswer(true, notify)}>
          <span>
            <i className="fas fa-exclamation mr-1" /> {t("actions.yesExecute")}
          </span>
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

const confirmShareCustomer = (description) =>
  new Promise((res) => {
    const root = createRoot(document.createElement("div"));
    const giveAnswer = (answer, notifyCustomer) => {
      root.unmount();
      res({ answer, notifyCustomer });
    };

    root.render(<ConfirmShareCustomerModal giveAnswer={giveAnswer} description={description} />);
  });
export default confirmShareCustomer;
