import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "react-bootstrap";
import cx from "classnames";
import { toast } from "react-toastify";
import * as taxAPI from "api/taxes";
import * as companyAPI from "api2/companies";
import { addMonths, differenceInDays, format } from "date-fns";
import BTable from "components/tables/btable";
import { truncateText } from "utils/text";
import { manageAnnualReportOptions } from "api/options";
import { formatDate, formatMonth, formatQuarter, parseDate } from "utils/date";
import { SelectSimpleFilter } from "components/filters/SelectFilters";
import useTable from "components/tables/btable/useTable";
import useModal from "hooks/useModal";
import Filters from "./ManagementOverviewFilters";
import "./ManagementOverviewTable.scss";

import VatReportedPreviewModal from "./VatReportedPreviewModal";
import SocSecReportedPreviewModal from "./SocSecReportedPreviewModal";

async function fetchEvents(companyId) {
  const isAuthorized = await taxAPI.skatteverket
    .checkAuth()
    .then((authResponse) => {
      return authResponse.data.is_authorized;
    })
    .catch(() => {
      toast.error("Skatteverket connection problem. Please try again");
      return undefined;
    });
  if (isAuthorized === true) {
    if (companyId) {
      await taxAPI.skatteverket.fetchCompanyEvents(companyId).catch((error) => {
        if (error.data.__all__) {
          toast.error(error.data.__all__);
        }
      });
    } else {
      await taxAPI.skatteverket.fetchAllEvents().catch((error) => {
        if (error.data.__all__) {
          toast.error(error.data.__all__);
        }
      });
    }
    return true;
  }
  window.location = await taxAPI.skatteverket.doAuth().then((response) => {
    return response.data.redirect_url;
  });
  return false;
}

function FetchAllButton() {
  const { t } = useTranslation("company");
  const [fetching, setFetching] = useState(false);
  const {
    dataActions: { reload },
  } = useTable();

  const fetch = async () => {
    setFetching(true);
    const fetched = await fetchEvents();
    setFetching(false);
    if (fetched) {
      reload();
    }
  };

  return (
    <div className="text-center">
      <Button type="button" variant="primary" size="md" disabled={fetching} onClick={fetch}>
        {t("common:actions.fetchAll")}
      </Button>
    </div>
  );
}

function FetchButton({ row }) {
  const { t } = useTranslation("company");
  const [fetching, setFetching] = useState(false);
  const {
    dataActions: { reload },
  } = useTable();

  const fetch = async () => {
    setFetching(true);
    const fetched = await fetchEvents(row.id);
    setFetching(false);
    if (fetched) {
      reload();
    }
  };

  return (
    <div>
      <Button variant="link" disabled={fetching} onClick={fetch}>
        <span className="mr-1">{t("common:actions.fetch")}</span>
        <i className="fe-refresh-cw" />
      </Button>
      {row.last_customer_events_fetch_date && (
        <small className="d-block text-muted">
          {t("reports:dashboard.fetched", { date: row.last_customer_events_fetch_date })}
        </small>
      )}
    </div>
  );
}

function DoubleHeader({ title1, title2, className }) {
  return (
    <div>
      <div>{title1}</div>
      <div>{title2}</div>
    </div>
  );
}

function AnnualReportField({ companyId, monthsPassed, initialStatus }) {
  const options = manageAnnualReportOptions.asList();
  const [status, setStatus] = useState(initialStatus);

  const onChange = (data) => {
    setStatus(data.status);
    companyAPI.annualReports.statusUpdate(companyId, data.status);
  };
  const bgClass = useMemo(() => {
    if (status === "registered") {
      return "green";
    }
    if (monthsPassed >= 6) {
      return "red";
    }
    if (monthsPassed >= 5) {
      return ["not_started", "ongoing"].includes(status) ? "red" : "yellow";
    }
    if (monthsPassed >= 3) {
      return ["not_started", "ongoing"].includes(status) ? "yellow" : "";
    }
    return "";
  }, [status, monthsPassed]);

  return (
    <div className={cx(["annual-report-field select", bgClass])}>
      <SelectSimpleFilter name="status" defaultValue={status} options={options} onFilter={onChange} />
    </div>
  );
}

function ReconciliationField({ reportDate }) {
  const bgClass = useMemo(() => {
    if (!reportDate) {
      return "";
    }
    const reconDate = parseDate(reportDate);
    let dueDate = new Date(new Date().getFullYear(), new Date().getMonth(), 15);
    if (differenceInDays(dueDate, reconDate) === 15) {
      dueDate = addMonths(dueDate, 1);
    }
    const diffDays = differenceInDays(dueDate, new Date());
    if (diffDays <= 0) {
      return "red";
    }
    if (diffDays <= 5) {
      return "yellow";
    }
    return "green";
  }, [reportDate]);

  return (
    <div className={cx([bgClass, "colored", "clickable"])}>
      {reportDate ? (
        <div>
          <span className="mr-1">{formatDate(reportDate)}</span>
        </div>
      ) : (
        <div>-</div>
      )}
    </div>
  );
}

function VatReportedField({
  companyId,
  reportDate,
  dueDate,
  vatPeriod,
  vatPeriods,
  isReported,
  isPreviousReported,
  periodStart,
  periodEnd,
}) {
  const previewModal = useModal();
  const [bgClass, displayDate] = useMemo(() => {
    if (vatPeriod === "none") {
      return ["gray", ""];
    }

    const declDueDate = parseDate(dueDate);
    const declDate = reportDate ? parseDate(reportDate) : null;
    const diffDays = differenceInDays(declDueDate, new Date());
    if (vatPeriod.includes("yearly")) {
      if (!isPreviousReported) {
        return ["text-muted", declDueDate ? formatDate(declDueDate) : "-"];
      }
      if (isReported) {
        const yearStart = format(parseDate(periodStart), "yyyy");
        const yearEnd = format(parseDate(periodEnd), "yyyy");
        return ["green", yearStart === yearEnd ? yearEnd : `${yearStart}/${yearEnd}`];
      }
      if (diffDays >= 10) {
        return ["text-muted", formatDate(declDueDate)];
      }
      if (diffDays > 0) {
        return ["text-muted yellow", formatDate(declDueDate)];
      }
      return ["text-muted red", formatDate(declDueDate)];
    }
    // monthly & quarterly
    const formatFunc = vatPeriod.includes("monthly") ? formatMonth : formatQuarter;
    if (!isPreviousReported) {
      return ["red", declDate ? formatFunc(declDate) : null];
    }
    if (diffDays >= 10 || isReported) {
      return ["green", declDate ? formatFunc(declDate) : null];
    }
    if (diffDays > 0) {
      return ["yellow", declDate ? formatFunc(declDate) : null];
    }
    return ["red", declDate ? formatFunc(declDate) : null];
  }, [isReported, isPreviousReported, dueDate, reportDate, vatPeriod, periodStart, periodEnd]);

  return (
    <>
      <div className={cx([bgClass, "colored", "clickable"])}>
        {displayDate ? (
          <div>
            <span className="mr-1">{displayDate}</span>
            <i className="fe-refresh-cw" />
          </div>
        ) : (
          <div>-</div>
        )}
        <i className="fe-search" onClick={previewModal.open} />
      </div>
      {previewModal.show && (
        <VatReportedPreviewModal
          companyId={companyId}
          vatPeriod={vatPeriod}
          vatPeriods={vatPeriods}
          handleClose={previewModal.close}
        />
      )}
    </>
  );
}

function SocSecReportedField({ companyId, registeredAga, reportDate, dueDate, isReported, isPreviousReported }) {
  const previewModal = useModal();
  const [bgClass, displayDate] = useMemo(() => {
    if (!registeredAga || !dueDate) {
      return ["gray", ""];
    }

    const declDate = reportDate ? parseDate(reportDate) : null;
    const declDueDate = parseDate(dueDate);
    const diffDays = differenceInDays(declDueDate, new Date());
    if (!isPreviousReported) {
      return ["red", declDate];
    }
    if (diffDays >= 10 || isReported) {
      return ["green", declDate];
    }
    if (diffDays > 0) {
      return ["yellow", declDate];
    }
    return ["red", declDate];
  }, [registeredAga, isReported, isPreviousReported, dueDate, reportDate]);

  return (
    <>
      <div className={cx([bgClass, "colored", "clickable"])}>
        {displayDate ? (
          <div>
            <span className="mr-1">{formatMonth(displayDate)}</span>
            <i className="fe-refresh-cw" />
          </div>
        ) : (
          <div>-</div>
        )}
        <i className="fe-search" onClick={previewModal.open} />
      </div>
      {previewModal.show && <SocSecReportedPreviewModal companyId={companyId} handleClose={previewModal.close} />}
    </>
  );
}

function Ink2ReportField({ reportDate, dueDate, isReported }) {
  const [bgClass, displayDate] = useMemo(() => {
    const declDate = reportDate ? parseDate(reportDate) : null;
    const declDueDate = parseDate(dueDate);
    const diffDays = differenceInDays(declDueDate, new Date());
    if (isReported) {
      return ["green", declDate];
    }
    if (diffDays > 30) {
      return ["text-muted", dueDate];
    }
    if (diffDays >= 10) {
      return ["yellow text-muted", dueDate];
    }

    return ["red text-muted", dueDate];
  }, [isReported, dueDate, reportDate]);
  return (
    <div className={cx([bgClass, "colored"])}>
      {displayDate ? (
        <div>
          <span className="mr-1">{formatDate(displayDate)}</span>
          <i className="fe-refresh-cw" />
        </div>
      ) : (
        <div>-</div>
      )}
    </div>
  );
}

function ManagementOverviewTable({ selectCompany }) {
  const { t } = useTranslation("company");

  const headers = useMemo(() => {
    return [
      {
        field: "custom1",
        label: <DoubleHeader title1={t("common:companyName")} title2={t("common:orgNo")} />,
        className: "text-left p-0 w-300",
        canSort: false,
        render: (row) => (
          <div>
            <div className="colored">
              <Button variant="link" onClick={() => selectCompany(row.id)} title={row.name}>
                {truncateText(row.name, 30)}
              </Button>
            </div>
            <div className="colored">{row.org_no}</div>
          </div>
        ),
      },
      {
        field: "custom2",
        className: "p-0",
        label: <DoubleHeader title1={t("common:consult")} title2={t("md.monthOfClosure")} />,
        canSort: false,
        render: (row) => (
          <div>
            <div className="colored">{truncateText(row.consult_name, 20)}</div>
            <div className="colored">{row.accounting_month ? formatMonth(row.accounting_month) : "-"}</div>
          </div>
        ),
      },
      {
        field: "custom3",
        className: "p-0",
        label: <DoubleHeader title1={t("md.socSecReported")} title2={t("md.vatReported")} />,
        canSort: false,
        width: 180,
        render: (row) => (
          <div>
            <SocSecReportedField
              companyId={row.id}
              registeredAga={row.registered_aga}
              reportDate={row.soc_sec.report_date}
              dueDate={row.soc_sec.due_date}
              isReported={row.soc_sec.is_reported}
              isPreviousReported={row.soc_sec.are_previous_reported}
            />
            <VatReportedField
              companyId={row.id}
              dueDate={row.vat_report.due_date}
              reportDate={row.vat_report.report_date}
              vatPeriod={row.vat_report_period}
              vatPeriods={row.vat_periods}
              isReported={row.vat_report.is_reported}
              isPreviousReported={row.vat_report.are_previous_reported}
              periodStart={row.vat_report.period_start}
              periodEnd={row.vat_report.period_end}
            />
          </div>
        ),
      },
      {
        field: "custom4",
        className: "p-0",
        label: <DoubleHeader title1={t("md.reconciliation")} title2={t("md.salaries")} />,
        canSort: false,
        width: 180,
        render: (row) => (
          <div>
            <ReconciliationField reportDate={row.last_reconciliation_date} />
            <div className="colored">{row.salaries || "-"}</div>
          </div>
        ),
      },
      {
        field: "custom5",
        className: "p-0",
        label: <DoubleHeader title1={t("md.annualReport")} title2="INK2" />,
        width: 220,
        canSort: false,
        render: (row) => (
          <div>
            <AnnualReportField
              companyId={row.id}
              monthsPassed={row.annual_report ? row.annual_report.months_passed : 0}
              initialStatus={row.annual_report ? row.annual_report.status : "not_started"}
            />
            <Ink2ReportField
              isReported={row.ink2.is_reported}
              reportDate={row.ink2.report_date}
              dueDate={row.ink2.due_date}
            />
          </div>
        ),
      },
      {
        field: "custom6",
        className: "p-0",
        label: <DoubleHeader title1={t("md.keyFinRatios")} title2={t("md.profitability")} />,
        canSort: false,
        width: 180,
        render: (row) => (
          <div>
            <div className="colored">{row.key_financial_ratios || "-"}</div>
            <div className="colored">{row.profitability_ahr || "-"}</div>
          </div>
        ),
      },
      {
        field: "custom7",
        label: <FetchAllButton />,
        className: "pb-0 pt-0 text-center",
        canSort: false,
        width: 120,
        render: (row) => <FetchButton row={row} />,
      },
    ];
  }, [selectCompany, t]);

  return (
    <BTable
      headers={headers}
      tableClassName="sticky-header manage-overview"
      tableResponsive={false}
      selectable={false}
      FiltersComponent={<Filters />}
    />
  );
}

export default ManagementOverviewTable;
