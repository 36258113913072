import React from "react";
import { Button } from "react-bootstrap";

import useTable from "components/tables/btable/useTable";
import {
  MultiAgencyFilter,
  MultiCompanyConsultFilter,
  MultiOfficeFilter,
  MultiSelectSimpleFilter,
  SearchFilter,
} from "components/filters";
import { updateAccountingCompaniesFilters } from "components/tables/btable/provider";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { RegisterCompanyModal } from "../../modals";
import useModal from "../../../hooks/useModal";
import { codesForAgency } from "../../perms/PermCodes";
import { useAuthState } from "../../../hooks/useAuth";
import * as options from "../../../api/options";
import { useCompanyDispatch } from "../../../hooks/useCompany";

function OfficeSupportCompanyFilters({ forStaff, showOfficesFilter }) {
  const { filters, setFilters } = useTable();
  const { t } = useTranslation("company");
  const registerModal = useModal();
  const { user } = useAuthState();
  const navigate = useNavigate();
  const { selectCompany: selectCompanyAction } = useCompanyDispatch();

  const onRegisterCompany = async (company, ttConfig = false) => {
    registerModal.close();
    if (company) {
      await selectCompanyAction(company.id, true);
      navigate("/office-support-companies/client-details");
    }
  };
  const setCompaniesSharedFilters = (selected) => {
    updateAccountingCompaniesFilters(selected);
  };
  const canCompanyRegister =
    user.agencies.some((agency) => agency.perm_codes.includes(codesForAgency.companyRegister)) || user.is_superuser;

  return (
    <>
      <section className="table-filters-left">
        <SearchFilter
          onFilter={(selected) => {
            setFilters(selected);
            setCompaniesSharedFilters(selected);
          }}
          defaultValue={filters.term}
          width={300}
          autoFocus
        />
        <MultiAgencyFilter
          name="agencies"
          onFilter={(selected) => {
            setFilters({ ...selected, offices: [] });
            setCompaniesSharedFilters({ ...selected, offices: [] });
          }}
          placeholder={t("common:agencies")}
          defaultValue={filters.agencies}
        />
        {showOfficesFilter && (
          <MultiOfficeFilter
            name="offices"
            onFilter={(selected) => {
              setFilters(selected);
              setCompaniesSharedFilters(selected);
            }}
            placeholder={t("common:office")}
            defaultValue={filters.offices}
            queryParams={{ belongsMyAgencies: true, agencies: filters.agencies }}
          />
        )}
        <MultiCompanyConsultFilter
          name="consults"
          isClearable
          onFilter={(selected) => {
            setFilters(selected);
            setCompaniesSharedFilters(selected);
          }}
          defaultValue={filters.consults}
          placeholder={t("common:customerManager")}
        />
        <MultiSelectSimpleFilter
          isClearable
          placeholder={t("common:statuses.status")}
          name="statuses"
          onFilter={setFilters}
          defaultValue={filters.statuses}
          options={options.companyStatuses.asList()}
        />
        <Button
          variant="outline-secondary"
          onClick={() => {
            setFilters({ favourites: filters.favourites ? null : true });
            setCompaniesSharedFilters({ favourites: filters.favourites ? null : true });
          }}
          active={filters.favourites}
        >
          {t("favouritesOnly")}
        </Button>
      </section>
      {canCompanyRegister && (
        <section className="table-filters-right">
          <Button variant="first" onClick={registerModal.open} style={{ maxHeight: "40px" }}>
            <i className="fas fa-plus" />
            <span> {t("navigation:registerCompany")}</span>
          </Button>
        </section>
      )}
      {registerModal.show && <RegisterCompanyModal handleClose={onRegisterCompany} />}
    </>
  );
}

export default OfficeSupportCompanyFilters;
