import { Button } from "react-bootstrap";
import React from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

function BackButton({ ...props }) {
  const { t } = useTranslation("common");
  const navigate = useNavigate();

  const goBack = () => {
    navigate(-1);
  };

  return (
    <Button variant="outline-secondary" onClick={goBack} {...props}>
      <i className="fas fa-arrow-left" /> {t("actions.goBack")}
    </Button>
  );
}

export default BackButton;
