import React, { useCallback, useEffect, useState } from "react";
import _ from "lodash";
import { Button, ButtonGroup, Table } from "react-bootstrap";
import { TableGroup } from "components/formik";
import { confirmExecute } from "components/modals/ConfirmModal";
import { formatDate, parseDate } from "utils/date";
import { useFormikContext } from "formik";
import * as agencyAPI from "api2/agencies";
import { useTranslation } from "react-i18next";

function YearsButton({ selectedYear, onChange }) {
  const todayYear = new Date().getFullYear();
  const years = [todayYear - 2, todayYear - 1, todayYear, todayYear + 1];

  return (
    <ButtonGroup size="sm" className="mt-2 mb-2 ml-1 d-block">
      {years.map((year) => (
        <Button
          key={year}
          type="button"
          variant="outline-secondary"
          active={year === selectedYear}
          onClick={() => onChange(year)}
        >
          {year}
        </Button>
      ))}
    </ButtonGroup>
  );
}

function AgencyExceptionTemplateForm({ agencyId, arrayHelpers }) {
  const { t } = useTranslation("others");
  const {
    values: { id: tplId, exceptions },
    setFieldValue,
  } = useFormikContext();
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());

  const fetchExceptions = useCallback(
    (year) => {
      agencyAPI.scheduleTemplates.exceptions.list(agencyId, tplId, { exception_date_year: year }).then((response) => {
        setFieldValue(
          "exceptions",
          response.data.map((e) => ({
            ...e,
            exception_date: parseDate(e.exception_date),
          })) || []
        );
      });
    },
    [agencyId, tplId, setFieldValue]
  );

  useEffect(() => {
    if (tplId) {
      fetchExceptions(selectedYear);
    }
  }, [tplId, fetchExceptions, selectedYear]);

  const removeException = async (exception, index) => {
    if (exception.id) {
      // confirm
      const answer = await confirmExecute(
        t("others:office.removeException", { exceptionDate: formatDate(exception.exception_date) })
      );
      if (answer) {
        // and API request to remove
        agencyAPI.scheduleTemplates.exceptions.remove(agencyId, tplId, exception.id).catch(() => {});
        arrayHelpers.remove(index);
      }
    } else {
      arrayHelpers.remove(index);
    }
  };

  const onChangeYear = async (year) => {
    // AND API to reload
    setSelectedYear(year);
  };

  const addEmpty = () => {
    const initialDate = new Date(selectedYear, new Date().getMonth(), new Date().getDate());
    arrayHelpers.unshift({ id: null, key: _.uniqueId("e"), exception_date: initialDate, description: "", hours: 0 });
  };
  return (
    <div className="schedule-exceptions mt-2">
      {tplId && <YearsButton selectedYear={selectedYear} onChange={onChangeYear} />}
      <Button type="button" variant="outline-primary" className="ml-1" size="sm" onClick={addEmpty}>
        <i className="fas fa-plus" /> {t("others:addException")}
      </Button>
      <Table size="sm">
        <thead>
          <tr>
            <th style={{ width: 150 }}>{t("others:exceptionDate")}</th>
            <th>{t("common:description")}</th>
            <th style={{ width: 150 }}>{t("others:workHours")}</th>
            <th style={{ width: 100 }} />
          </tr>
        </thead>
        <tbody>
          {exceptions.map((exception, index) => {
            return (
              <React.Fragment key={exception.id || exception.key}>
                <tr>
                  <TableGroup.DatePicker name={`exceptions[${index}].exception_date`} />
                  <TableGroup.Input name={`exceptions[${index}].description`} />
                  <TableGroup.NumberInput name={`exceptions[${index}].hours`} />
                  <td className="text-center">
                    <Button type="button" variant="danger" onClick={() => removeException(exception, index)}>
                      <i className="fas fa-times" />
                    </Button>
                  </td>
                </tr>
              </React.Fragment>
            );
          })}
          {!exceptions.length && (
            <tr>
              <td colSpan={4} className="pl-4">
                {t("common:noResultsFound")}
              </td>
            </tr>
          )}
        </tbody>
      </Table>
    </div>
  );
}

export default AgencyExceptionTemplateForm;
