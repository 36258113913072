import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import { toast } from "react-toastify";
import { useResolvedPath } from "react-router-dom";

import {
  HistoryButton,
  PreviewButton,
  TooltipActionButton,
  TooltipActionLink,
  VerificationBookingButton,
  VerificationCommentsButton,
  VerificationDocumentsButton,
} from "components/ui/buttons";
import FileUrlModalOld from "components/modals/FileUrlModal/FileUrlModalOld";
import { CIBookPaymentModal } from "components/modals";
import { SendInvoiceModal } from "components/modals/invoices";

import { confirmExecuteWithAction, confirmRemove } from "components/modals/ConfirmModal";
import useTable from "components/tables/btable/useTable";
import * as ciAPI from "api/customer-invoices";
import useModal from "hooks/useModal";
import { codesForCustomerInvoices } from "components/perms/PermCodes";
import { PermCodeRequired } from "components/perms";
import { history } from "api2/customer-invoices";
import { SelectionContext } from "../../../state/providers/SelectionProvider";

function CustomerInvoiceActions({ row, companyId }) {
  const { t } = useTranslation("ci");
  const url = useResolvedPath("").pathname;
  const bookPayModal = useModal();
  const sendModal = useModal();
  const xmlModal = useModal();
  const {
    tableId,
    dataActions: { reload },
  } = useTable();
  const { removeSelection } = useContext(SelectionContext);
  const show = {
    bookBtn: _.includes(["unpaid", "expired"], row.sub_status),
    bookingBtn: row.status === 1,
    editBtn: row.sub_status === "draft",
    creditBtn:
      _.includes(["unpaid", "expired"], row.sub_status) &&
      row.amount_sek > 0 &&
      !row.credit_invoices.length &&
      row.balance === row.amount_sek,
    deleteBtn: row.status === 0 && !row.is_fortnox_voided,
    reminderBtn: row.amount_sek > 0 && _.includes(["unpaid", "expired"], row.sub_status),
    rotRutXml: row.rot_rut_enabled === true && _.includes(["unpaid", "expired"], row.sub_status),
    sendAgainBtn: row.sub_status === "unpaid",
  };

  const onDelete = async () => {
    const answer = await confirmRemove(t("confirm.removeDraft"));
    if (answer) {
      ciAPI
        .remove(companyId, row.id)
        .then(() => {
          toast.success(t("msg:deleted"));
          if (removeSelection) {
            removeSelection(tableId, row);
          }
          reload();
        })
        .catch(() => {
          toast.error(t("msg:canNotExecuteAction"));
        });
    }
  };

  const onBookPayModalOpen = () => {
    bookPayModal.open(row);
  };

  const onBookPayModalClose = (saved) => {
    bookPayModal.close();
    if (saved) {
      if (removeSelection) {
        removeSelection(tableId, row);
      }
      reload();
    }
  };

  const onInvoiceReminderSend = async () => {
    return confirmExecuteWithAction(t("confirm.sendReminder", { invoiceNo: row.invoice_no || row.ocr }), () => {
      return ciAPI
        .sendInvoiceReminder(companyId, row.id)
        .then(() => {
          toast.success(t("msg:sent"));
          reload();
        })
        .catch((error) => {
          if (error.data && error.data.__all__) {
            toast.error(error.data.__all__);
          } else {
            toast.error(t("msg:canNotExecuteAction"));
          }
        });
    });
  };

  const downloadRotRutXML = () => {
    xmlModal.open(`/companies/${companyId}/customer-invoices/${row.id}/invoice_rot_rut_xml/`);
  };

  return (
    <>
      <HistoryButton apiResource={history} apiParams={[companyId, row.id]} />
      <VerificationDocumentsButton verificationId={row.id} companyId={companyId} documentsIds={row.documents} />
      <VerificationCommentsButton companyId={companyId} verification={row} />
      <PreviewButton text={t("common:actions.preview")} link={`${url}/${row.id}/preview`} />
      {show.bookingBtn && <VerificationBookingButton companyId={companyId} verification={row} />}
      <PermCodeRequired code={codesForCustomerInvoices.bookPayment}>
        {show.bookBtn && (
          <TooltipActionButton
            text={t("common:actions.bookPayment")}
            onClick={onBookPayModalOpen}
            icon="fas fa-dollar-sign"
          />
        )}
      </PermCodeRequired>
      <PermCodeRequired code={codesForCustomerInvoices.manage}>
        {show.rotRutXml && (
          <TooltipActionButton
            text={t("actions.downloadRotRutXml")}
            variant="secondary"
            onClick={downloadRotRutXML}
            icon="fas fa-download"
          />
        )}
        {show.editBtn && (
          <TooltipActionLink text={t("common:actions.change")} link={`${url}/${row.id}/change`} icon="fas fa-pen" />
        )}
        {show.reminderBtn && (
          <TooltipActionButton
            text={t("actions.sendInvoiceReminder")}
            variant={row.reminder_sent_date ? "warning" : "secondary"}
            onClick={onInvoiceReminderSend}
            icon="fas fa-bell"
          />
        )}
        {show.sendAgainBtn && (
          <TooltipActionButton text={t("actions.sendAgain")} onClick={sendModal.open} icon="fas fa-paper-plane" />
        )}
        {show.creditBtn && (
          <TooltipActionLink
            text={t("common:actions.creditInvoice")}
            link={`${url}/${row.id}/credit`}
            icon="fas fa-minus-circle"
          />
        )}

        <TooltipActionLink
          text={t("common:actions.duplicate")}
          link={`${url}/${row.id}/duplicate`}
          icon="fas fa-copy"
        />
        {show.deleteBtn && (
          <TooltipActionButton text={t("common:actions.remove")} onClick={onDelete} icon="fas fa-trash" />
        )}
      </PermCodeRequired>
      {bookPayModal.show && (
        <CIBookPaymentModal companyId={companyId} invoiceData={bookPayModal.data} handleClose={onBookPayModalClose} />
      )}
      {xmlModal.show && (
        <FileUrlModalOld fileUrl={xmlModal.data} handleClose={xmlModal.close} downloadFileName="rot.xml" autoDownload />
      )}
      {sendModal.show && <SendInvoiceModal handleClose={sendModal.close} companyId={companyId} invoiceData={row} />}
    </>
  );
}

export default CustomerInvoiceActions;
