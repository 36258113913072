import React, { useState } from "react";
import { Field, FieldArray, Form, Formik } from "formik";
import { Alert, Card, Col, InputGroup, Row } from "react-bootstrap";
import * as options from "api/options";
import * as yup from "yup";
import * as contractAPI from "api/contracts";
import { toast } from "react-toastify";
import { addMonths } from "date-fns";
import { useTranslation } from "react-i18next";

import { CustomerPicker, FormGroup } from "components/formik";
import useModal from "hooks/useModal";
import { CCProjModal, DiscountModal } from "components/modals/invoices";
import { SubmitButton } from "components/ui/buttons";
import * as selectAPI from "api/select";
import { _getDiscount } from "api/customer-invoices";
import { useCompanyState } from "hooks/useCompany";
import {
  isReversedVATAllowed,
  isVatDisabled,
  onCustomerChange,
  onCustomerReset,
  onReversedVatChange,
  RecordsForm,
  RecordsTool,
} from "components/forms/CustomerInvoiceForm/helpers";
import { DeliveryMethodSwitcher } from "components/forms/CustomerInvoiceForm/CustomerInvoiceForm";
import { formatData } from "./helpers";

function ContractInvoiceForm({ company, invoice, onSave, invoiceConnectedProducts = {} }) {
  const {
    costCenters: { asOptions: centerOptions },
    projects: { asOptions: projectOptions },
  } = useCompanyState();
  const ccProjModal = useModal();
  const paymentTerms = options.paymentTermsOptionsWithoutDefault();
  const createTerms = options.contractCreateTerms.asList();
  const priceGroups = options.priceGroups.asList();
  const vatOptions = options.vatOptions.asList();
  const { t } = useTranslation("ci");
  const discountModal = useModal();
  const [originalPrices, setOriginalPrices] = useState({});
  const [connectedProducts, setConnectedProducts] = useState(invoiceConnectedProducts);
  const formikProps = {
    initialValues: {
      ...invoice,
      payment_terms: options.paymentTerms.getOption(invoice.payment_terms) || paymentTerms[1],
      price_group: options.priceGroups.getOption(invoice.price_group) || priceGroups[0],
      create_terms: options.contractCreateTerms.getOption(invoice.create_terms) || createTerms[1],
    },
    validationSchema: yup.object().shape({
      customer: yup.object().nullable().required(),
      contract_date: yup.date().nullable().required(),
      period_start: yup.date().nullable().required(),
      invoice_interval: yup.number().required(),
      period_end: yup
        .date()
        .nullable()
        .when("ongoing", ([ongoing], schema) => {
          return ongoing ? schema.notRequired() : schema.required();
        }),
    }),
    onSubmit: async (values, { setErrors, setFieldError }) => {
      await contractAPI.contracts
        .save(company.id, formatData(values))
        .then((response) => {
          toast.success(t("msg:saved"), { autoClose: 2000 });
          if (onSave) {
            onSave(response.data);
          }
        })
        .catch((error) => {
          toast.error(t("msg:fixErrors"));
          setErrors(error.data);
        });
    },
  };

  function onTemplateSelected(template, values, setValues) {
    if (template) {
      if (values.customer && template.delivery_method !== values.customer.DefaultActionConfig.DeliveryMethod) {
        toast.warning(t("warning.contractDeliveryMethodMismatch"), { autoClose: 10000 });
      }
      setConnectedProducts(
        Object.assign(
          {},
          ...template.records
            .filter((record) => record.RecordType === "Standard")
            .map((r) => ({ [r.ProductPublicId]: r }))
        )
      );
      setValues({
        ...values,
        delivery_method: template.delivery_method,
        template_delivery_method: template.delivery_method,
        invoice_interval: template.invoice_interval,
        ongoing: template.ongoing,
        period_end: template.ongoing ? null : addMonths(values.period_start, template.contract_length),
        payment_terms: options.paymentTerms.getOption(template.payment_terms),
        records: template.records.map((record) => ({
          ...record,
          VAT: isVatDisabled(company, values) ? vatOptions[0] : options.vatOptions.getOption(record.VAT),
          UnitPrice: record.UnitPrice && record.UnitPrice.Value / 100,
          RotRutAmount: record.RotRutAmount && record.RotRutAmount.Value / 100,
          Discount: _getDiscount(record),
        })),
      });
    }
  }

  return (
    <Card>
      <Formik {...formikProps}>
        {({ values, errors, setFieldValue, setValues, isSubmitting }) => {
          const reversedVATAllowed = isReversedVATAllowed(values.customer);
          return (
            <>
              <Form id="ciForm">
                <Card.Body>
                  <Row xl={2} lg={1}>
                    <Col xl={6} lg={12}>
                      <CustomerPicker
                        name="customer"
                        label={t("common:customer")}
                        companyId={company.id}
                        customer={values.customer}
                        onChange={(customer) =>
                          onCustomerChange(
                            company,
                            customer,
                            values,
                            setValues,
                            setFieldValue,
                            t,
                            connectedProducts,
                            {},
                            {},
                            null,
                            true,
                            false
                          )
                        }
                        onReset={() => onCustomerReset(setFieldValue, values, setValues, connectedProducts)}
                        required
                      />
                    </Col>
                  </Row>
                  {!invoice.id && (
                    <Row>
                      <Col xl={4}>
                        <FormGroup.AsyncSelect
                          name="template"
                          isClearable
                          label={t("common:contractTemplate")}
                          onChange={(template) => onTemplateSelected(template, values, setValues)}
                          loadOptions={(params, callback) =>
                            selectAPI.getCompanyContractTemplates({ ...params, companyId: company.id }, callback)
                          }
                          minSearchLength={0}
                        />
                      </Col>
                    </Row>
                  )}
                  <Row>
                    <Col lg={3}>
                      <FormGroup.Input name="your_reference" label={t("common:yourReference")} />
                    </Col>
                    <Col lg={3}>
                      <FormGroup.Input name="our_reference" label={t("common:ourReference")} />
                    </Col>
                  </Row>
                  <Row xl={4} sm={3}>
                    <Col>
                      <FormGroup.DatePicker name="contract_date" label={t("contractDate")} required />
                    </Col>
                    <Col>
                      <FormGroup.Input
                        type="number"
                        label={t("invoiceIntervalMonths")}
                        name="invoice_interval"
                        required
                      />
                    </Col>
                    <Col>
                      <FormGroup.DatePicker name="period_start" label={t("periodStart")} required />
                    </Col>
                    {!values.ongoing && (
                      <Col>
                        <FormGroup.DatePicker name="period_end" label={t("periodEnd")} required />
                      </Col>
                    )}
                  </Row>
                  <Row xl={4} sm={3}>
                    <Col>
                      <FormGroup.SimpleSelect
                        name="payment_terms"
                        label={t("common:paymentTerms")}
                        options={paymentTerms}
                      />
                    </Col>
                    <Col>
                      <FormGroup.SimpleSelect name="create_terms" label={t("createTerms")} options={createTerms} />
                    </Col>
                    <Col>
                      <div className="form-group">
                        <label className="form-label">{t("reversedVat")}</label>
                        <InputGroup className="mb-3">
                          <InputGroup.Prepend>
                            <span className="input-group-text">
                              <Field
                                type="checkbox"
                                name="reversed_vat"
                                disabled={!reversedVATAllowed}
                                onChange={(e) =>
                                  onReversedVatChange(
                                    e.currentTarget.checked,
                                    values.customer,
                                    values,
                                    setValues,
                                    connectedProducts
                                  )
                                }
                              />
                            </span>
                          </InputGroup.Prepend>
                          <Field
                            type="text"
                            name="reversed_vat_receiver_no"
                            placeholder={t("common:money.vatNo")}
                            disabled={values.reversed_vat === false}
                            className="form-control"
                          />
                        </InputGroup>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <label>{t("common:options")}</label>
                      <FormGroup.Checkbox wrapperClass="mb-1" label={t("common:statuses.active")} name="is_active" />
                      <FormGroup.Checkbox wrapperClass="mb-3" label={t("common:statuses.ongoing")} name="ongoing" />
                    </Col>
                  </Row>
                  <FieldArray
                    name="records"
                    render={(arrayHelper) => (
                      <>
                        <RecordsForm values={values} currency="SEK" arrayHelper={arrayHelper} t={t} />
                        <RecordsTool
                          companyId={company.id}
                          arrayHelper={arrayHelper}
                          currency="SEK"
                          vatDisabled={isVatDisabled(company, values)}
                          rotRutEnabled={false}
                          discountModal={discountModal}
                          rotRutModal={null}
                          ccProjModal={ccProjModal}
                          centerOptions={centerOptions}
                          projectOptions={projectOptions}
                          prices={[]}
                          priceGroup={values.price_group}
                          connectedProducts={connectedProducts}
                          setConnectedProducts={setConnectedProducts}
                          originalPrices={originalPrices}
                          setOriginalPrices={setOriginalPrices}
                          forOffer={false}
                          t={t}
                        />
                      </>
                    )}
                  />
                  <Row>
                    <Col sm={12}>
                      <div className="form-group">
                        <label className="form-label">{t("sendInvoiceBy")}</label>
                        <DeliveryMethodSwitcher
                          t={t}
                          deliveryMethod={values.delivery_method}
                          changeDeliveryMethod={(newMethod) => setFieldValue("delivery_method", newMethod)}
                        />
                      </div>
                    </Col>
                  </Row>
                  {errors && errors.__all__ && <Alert variant="danger">{errors.__all__}</Alert>}
                </Card.Body>
                <Card.Footer>
                  <SubmitButton className="ml-2" isSubmitting={isSubmitting} />
                </Card.Footer>
              </Form>
              {discountModal.show && (
                <DiscountModal
                  show
                  companyId={company.id}
                  currency="SEK"
                  records={values.records}
                  onSave={(records) => {
                    setFieldValue("records", records);
                    discountModal.close();
                  }}
                  onCancel={discountModal.close}
                />
              )}
              {ccProjModal.show && (
                <CCProjModal
                  companyId={company.id}
                  records={values.records}
                  onCancel={ccProjModal.close}
                  onSave={(records) => {
                    setFieldValue("records", records, false);
                    ccProjModal.close();
                  }}
                />
              )}
            </>
          );
        }}
      </Formik>
    </Card>
  );
}

export default ContractInvoiceForm;
