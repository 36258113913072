import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Form, Formik, useFormikContext } from "formik";
import { Button, Card, Col, Row } from "react-bootstrap";
import { ConsultPicker } from "components/formik";
import { SubmitButton } from "components/ui/buttons";
import { toast } from "react-toastify";
import * as supportApi from "api2/office-support";
import * as yup from "yup";
import { useCompanyState } from "hooks/useCompany";

function OfficeSupportRolesEssentialForm({ companyId, changeEditedForm, initialData }) {
  const { t } = useTranslation("company");
  const {
    company: { agency_id },
  } = useCompanyState();
  const [showMore, setShowMore] = useState(true);
  const formikProps = {
    initialValues: {
      ...initialData,
      consult: initialData.consult && {
        value: initialData.consult,
        label: initialData.consult_name,
      },
      payroll_consultant: initialData.payroll_consultant && {
        value: initialData.payroll_consultant,
        label: initialData.payroll_consultant_name,
      },
      coordinator: initialData.coordinator && {
        value: initialData.coordinator,
        label: initialData.coordinator_name,
      },
      accounting_consultant: initialData.accounting_consultant && {
        value: initialData.accounting_consultant,
        label: initialData.accounting_consultant_name,
      },
      invoicing_manager: initialData.invoicing_manager && {
        value: initialData.invoicing_manager,
        label: initialData.invoicing_manager_name,
      },
      accounting_assistant: initialData.accounting_assistant && {
        value: initialData.accounting_assistant,
        label: initialData.accounting_assistant_name,
      },
    },
    validationSchema: yup.object().shape({
      consult: yup.object().nullable().required(),
      accounting_consultant: yup.object().nullable().required(),
    }),
    onSubmit: async (values, { setErrors, resetForm }) => {
      await supportApi.roles.essential
        .update(companyId, {
          ...values,
          payroll_consultant: values.payroll_consultant?.value,
          coordinator: values.coordinator?.value,
          consult: values.consult?.value,
          accounting_consultant: values.accounting_consultant?.value,
          invoicing_manager: values.invoicing_manager?.value,
          accounting_assistant: values.accounting_assistant?.value,
        })
        .then((response) => {
          resetForm({ values });
          toast.success(t("msg:saved"), { autoClose: 2000 });
        })
        .catch((error) => {
          toast.error(t("msg:fixErrors"));
          if (error.data.__all__) {
            setErrors({ __all__: error.data.__all__ });
          } else {
            setErrors({ ...error.data });
          }
        });
    },
  };

  function FormUpdateChecker() {
    const { dirty } = useFormikContext();
    React.useEffect(() => {
      changeEditedForm({ id: "roles-essential-form", dirty });
    }, [dirty]);
    return null;
  }

  return (
    <Formik {...formikProps}>
      {({ values, touched, isSubmitting, setFieldValue, errors, resetForm, dirty }) => {
        return (
          <Form>
            <FormUpdateChecker />
            <Card>
              <Card.Header onClick={() => setShowMore((prevValue) => !prevValue)}>
                <Card.Title style={{ color: "#ADADAD", fontWeight: 400, fontSize: "12px" }}>
                  {t("header.businessEssential")}
                  <i
                    style={{ textAlign: "right", float: "right" }}
                    className={showMore ? "fas fa-chevron-up fa-1x" : "fas fa-chevron-down fa-1x"}
                  />
                </Card.Title>
              </Card.Header>
              {showMore && (
                <Card.Body className="pt-0">
                  <Row>
                    <Col>
                      <ConsultPicker agencyId={agency_id} label={t("common:customerManager")} required />
                    </Col>
                    <Col>
                      <ConsultPicker agencyId={agency_id} label={t("title.coordinator")} name="coordinator" />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <ConsultPicker
                        agencyId={agency_id}
                        label={t("title.accounting_consultant")}
                        name="accounting_consultant"
                        required
                      />
                    </Col>
                    <Col>
                      <ConsultPicker
                        agencyId={agency_id}
                        label={t("title.payrollConsultant")}
                        name="payroll_consultant"
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <ConsultPicker
                        agencyId={agency_id}
                        name="accounting_assistant"
                        label={t("title.accounting_assistance")}
                      />
                    </Col>
                    <Col>
                      <ConsultPicker
                        agencyId={agency_id}
                        label={t("title.invoicing_manager")}
                        name="invoicing_manager"
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col className="text-right">
                      {dirty && (
                        <Button variant="link" size="lg" onClick={() => resetForm()} className="cancel-btn">
                          {t("common:actions.cancel")}
                        </Button>
                      )}
                      <SubmitButton isSubmitting={isSubmitting} disabled={!dirty} />
                    </Col>
                  </Row>
                </Card.Body>
              )}
            </Card>
          </Form>
        );
      }}
    </Formik>
  );
}

export default OfficeSupportRolesEssentialForm;
